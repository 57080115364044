import { BaseHttpService } from 'app/services';
import {
  ValidateEmv,
  ValidateEmvResponse,
} from 'modules/products/interfaces/qrCode';

export class QrCodeHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  validateEmv = async (
    validateQrCode: ValidateEmv,
  ): Promise<ValidateEmvResponse> => {
    const res = await this.post(
      `${this.baseUrl}/originator/qrcode/validate-pix-emv`,
      validateQrCode,
    );

    return res.data as ValidateEmvResponse;
  };
}
