import { DashboardLayout } from '../../../app/components';
import { routingPath } from '../../../app/routes';
import { OperationsMain } from '../pages';

const OperationRoutes = () => {
  return {
    element: <DashboardLayout />,
    children: [
      {
        path: routingPath.operations.main,
        element: <OperationsMain />,
      },
    ],
  };
};

export default OperationRoutes;
