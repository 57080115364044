import { useEffect, useCallback, useState } from 'react';
import { ProductSideSheet } from 'modules/products/components/ProductSideSheet';
import { PersonContent, RegisterContent } from './components';
import { useQualificationsService } from 'modules/products/services/hooks';
import { TProducts } from 'modules/products/context';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {} from 'modules/products/services/hooks/useApplicationsService';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { TBorrowerQualificationRequestType } from 'modules/products/services/hooks/intefaces';
import { TCustomSelectOption } from 'app/components/CustomTableSelect/CustomTableSelect';
import { TBorrowerQualificationResponseParsed } from 'modules/products/pages/interfaces';
import { Spinner } from 'app/components';
import { SpinnerLoadingArea } from './styles';
import { useAppContext } from 'app/context/AppContextProvider';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';

export interface IPersonDetailsSideSheetProps {
  handleClose: () => void;
  handleEditCondition: (
    qualification: TBorrowerQualificationResponseParsed | undefined,
  ) => void;
  isOpen: boolean;
  qualification?: TBorrowerQualificationResponseParsed | undefined;
  productInfo?: TProducts | undefined;
  statusList: TCustomSelectOption[];
  isStatusOptionsLoading: string | boolean;
  forceUpdate?: string | Date;
  handleUpdateQualificationStatus: (
    qualification: TBorrowerQualificationResponseParsed | undefined,
    identifier: string,
  ) => void;
}

const PersonDetailsSideSheet = ({
  handleClose,
  handleUpdateQualificationStatus,
  handleEditCondition,
  isOpen,
  qualification,
  productInfo,
  statusList,
  isStatusOptionsLoading,
  forceUpdate,
}: IPersonDetailsSideSheetProps) => {
  const { getBorrowerQualification } = useQualificationsService();
  const { getPerson } = usePersonService();
  const { getBusiness } = useCustomerService();
  const { is_issuer_app } = useAppContext();
  const [borrowerPersonData, setBorrowerPersonData] =
    useState<TRegisterPersonDataRequest>();
  const [borrowerBusinessData, setBorrowerBusinessData] =
    useState<TRegisterBusinessDataRequest>();
  const [borrowerQualificationData, setBorrowerQualificationData] = useState<
    TBorrowerQualificationRequestType[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    handleClose();
  };

  const fetchBorrowerQualificationDetails = useCallback(async () => {
    setIsLoading(true);
    const originator =
      is_issuer_app && qualification?.originator
        ? qualification.originator
        : undefined;
    try {
      if (productInfo) {
        await handleGetBorrower(
          productInfo,
          qualification?.borrower.id || '',
          originator,
        );
      }

      const borrowerQualificationRes = await handleGetBorrowerQualification(
        productInfo?.id || '',
        qualification?.borrower.id || '',
        qualification?.id || '',
        originator,
      );

      if (!borrowerQualificationRes) {
        handleClose();
      }
      setBorrowerQualificationData(borrowerQualificationRes?.content);
    } finally {
      setIsLoading(false);
    }
  }, [productInfo, qualification]);

  const handleGetBorrower = useCallback(
    async (product: TProducts, borrowerId: string, originator?: string) => {
      try {
        if (product.borrower_type === 'PERSON') {
          const personData = await getPerson(
            borrowerId || '',
            false,
            originator,
          );
          if (!personData) {
            handleClose();
          }
          setBorrowerPersonData(
            personData || ({} as TRegisterPersonDataRequest),
          );
        }
        if (product.borrower_type === 'BUSINESS') {
          const personData = await getBusiness(
            borrowerId || '',
            false,
            originator,
          );
          if (!personData) {
            handleClose();
          }
          setBorrowerBusinessData(
            personData || ({} as TRegisterBusinessDataRequest),
          );
        }
      } catch (error) {
        handleClose();
      }
    },
    [productInfo],
  );

  const handleGetBorrowerQualification = useCallback(
    async (
      productId: string,
      borrowerId: string,
      qualificationId = '',
      originator?: string,
    ) => {
      try {
        const borrowerQualificationRes = await getBorrowerQualification(
          productId,
          borrowerId,
          qualificationId,
          EFunctions.BORROWER,
          originator,
        );
        return borrowerQualificationRes;
      } catch (error) {
        handleClose();
      }
    },
    [],
  );

  useEffect(() => {
    if (qualification) {
      fetchBorrowerQualificationDetails();
    }
  }, [qualification, forceUpdate]);

  return (
    <ProductSideSheet
      open={isOpen}
      handleClose={onClose}
      title={qualification?.borrower.name || ''}
    >
      {isLoading ? (
        <SpinnerLoadingArea>
          <Spinner />
        </SpinnerLoadingArea>
      ) : (
        <>
          <PersonContent
            borrowerQualificationData={borrowerQualificationData?.[0]}
            selectedQualification={qualification}
            product={productInfo}
            statusOptions={statusList}
            handleUpdateQualificationStatus={handleUpdateQualificationStatus}
            isStatusOptionsLoading={isStatusOptionsLoading}
            handleEditCondition={handleEditCondition}
          />
          <RegisterContent
            borrowerQualificationData={borrowerQualificationData?.[0]}
            borrowerBusiness={borrowerBusinessData}
            borrowerPerson={borrowerPersonData}
            product={productInfo}
            originator={qualification?.originator}
          />
        </>
      )}
    </ProductSideSheet>
  );
};

export default PersonDetailsSideSheet;
