import styled from '@emotion/styled';

export const OperationsMainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TabsArea = styled.div`
  margin-top: 24px;
`;

export const ContentHeader = styled.div`
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding: 30px 48px 0px;
  border-top: 1px solid #DBD6FE;
  border-left: 1px solid #DBD6FE;
  border-right: 1px solid #DBD6FE;
  border-radius: 16px 16px 0px 0px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const HeaderButtons = styled.section`
  display: flex;
  button {
    width: auto;
    margin-right: 16px;
  }
`;

export const PendingCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${({ theme }) => theme.palette?.brand.primary.base};
  font-size: 12px;
  line-height: 8px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 999999;
`;
