const QRCodeCalendarIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49935 0.621094V2.28776H12.4993V0.621094H14.166V2.28776H17.4993C17.9596 2.28776 18.3327 2.66086 18.3327 3.12109V16.4544C18.3327 16.9147 17.9596 17.2878 17.4993 17.2878H2.49935C2.03912 17.2878 1.66602 16.9147 1.66602 16.4544V3.12109C1.66602 2.66086 2.03912 2.28776 2.49935 2.28776H5.83268V0.621094H7.49935ZM16.666 8.95443H3.33268V15.6211H16.666V8.95443ZM9.16602 10.6211V13.9544H4.99935V10.6211H9.16602ZM5.83268 3.95443H3.33268V7.28776H16.666V3.95443H14.166V5.62109H12.4993V3.95443H7.49935V5.62109H5.83268V3.95443Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeCalendarIcon;
