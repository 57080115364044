import { TProducts } from 'modules/products/context';
import { BaseHttpService } from '../../../../app/services';
import { ICustomVariablesRequest } from '../hooks/intefaces';
import { TProductQualificationRequest } from 'modules/products/context/ProductsProvider/products.interfaces';

export class ProductsHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getProducts = async (isIssuer?: boolean) => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(`${this.baseUrl}/${entity}/products?size=100`);
    return res;
  };

  getProduct = async (productId: string, isIssuer?: boolean) => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${this.baseUrl}/${entity}/products/${productId}`,
    );
    return res;
  };

  getProductQualifications = async (productId: string) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=EMPLOYER`,
    );
    return res;
  };

  getProductVariablesCsv = async (productId: string) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/variables/csv/?type=BORROWER&subtype=PERSON`,
    );
    return res;
  };

  getCustomVariables = async (productId: string) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/custom-variables`,
    );
    return res;
  };

  getAllCustomVariables = async () => {
    const products = await this.getProducts();
    const customVariables = await Promise.all(
      (products.data.content as TProducts[]).map<
        Promise<ICustomVariablesRequest>
      >(async (product) => {
        const customVariablesReturn = await this.getCustomVariables(product.id);
        return customVariablesReturn.data as ICustomVariablesRequest;
      }),
    );
    return customVariables;
  };

  postConditionsRequests = async (
    productId: string,
    qualificationRequest: TProductQualificationRequest,
  ) => {
    const res = await this.post(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?is_qualification=false`,
      qualificationRequest,
    );

    return res;
  };
}
