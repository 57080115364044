import { useState } from 'react';
import { ButtonPrimary, CustomCircularIndicator } from '..';
import { QRCodeInput, QRCodeInputGroup } from './styles';
import { CustomInput } from 'app/components';
import { SearchWhiteIcon } from 'app/components/Icons';
import useQRCodeService from 'modules/products/services/hooks/useQRCodeService';
import { ValidateEmvResponse } from 'modules/products/interfaces/qrCode';
import QRCodeErrors from './QRCodeErrors';

type QRCodeQueryProps = {
  qrcodeEmv: ValidateEmvResponse | null;
  onValidateQRCode: (qrcodeEmvResponse: ValidateEmvResponse) => void;
};
const QRCodeQuery = ({ qrcodeEmv, onValidateQRCode }: QRCodeQueryProps) => {
  const { validateEmv, validateEmvLoading } = useQRCodeService();
  const [error, setError] = useState<string | undefined>();
  const [qrCodeValue, updateQRCodeValue] = useState('');

  const onValidateQRCodeEmv = () => {
    if (qrcodeEmv?.emv === qrCodeValue) {
      setError('QR Code já consultado');
    } else {
      validateEmv({ emv: qrCodeValue }).then((res) => {
        if (res && 'emv' in res) {
          onValidateQRCode(res);
          updateQRCodeValue('');
        } else {
          setError(res?.message ?? 'Erro ao consultar QR Code');
        }
      });
    }
  };

  return (
    <>
      <QRCodeInputGroup>
        <QRCodeInput>
          <CustomInput
            name="qrCodeValue"
            id="qrCodeValue"
            style={{ height: '45px' }}
            value={qrCodeValue}
            handleInputChange={(e) => {
              updateQRCodeValue(e.target.value);
              if (error) {
                setError(undefined);
              }
            }}
            placeholder="Digite aqui"
            labelValue="Inserir Pix copia e cola"
          />
          <ButtonPrimary
            style={{
              width: '170px',
              marginTop: '1.8rem',
            }}
            iconPosition="right"
            btnIcon={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  paddingTop: validateEmvLoading ? '2px' : '0px',
                }}
              >
                {validateEmvLoading ? (
                  <CustomCircularIndicator
                    size={22}
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : (
                  <SearchWhiteIcon color="white" />
                )}
              </div>
            }
            onClick={onValidateQRCodeEmv}
            disabled={
              !qrCodeValue || qrCodeValue.length === 0 || validateEmvLoading
            }
          >
            Consultar
          </ButtonPrimary>
        </QRCodeInput>
        <QRCodeErrors error={error ?? ''} />
      </QRCodeInputGroup>
    </>
  );
};

export default QRCodeQuery;
