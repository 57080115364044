import styled from '@emotion/styled';

export const CreditSimulatorWrapper = styled.div`
  position: relative;
`;

export const CreditSimulatorContentWrapper = styled.div`
  width: 100%;
`;

export const CreditSimulatorInputPreview = styled.div`
  display: flex;
  overflow-y: visible;
  width: 100%;
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  margin-right: 48px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 28px;
      margin-right: 28px;
  }
`;

export const CreditSimulatorContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 120px;
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  width: 90vw;
  height: auto;
  max-width: 1301px;
  /* max-height: 850px; */
  border: 2px solid #E6E5EA;
  border-radius: 20px;
  display: flex;
  overflow-y: visible;
`;

export const Arrow = styled.div`
  position: fixed;
  height: 20px;
  width: 20px;
  border: 2px solid #E6E5EA;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  bottom: 75px;
  right: 110.8px;
  transform: rotate(45deg);
  border-left: 0px;
  border-bottom: 0px;
`;

export const InputArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  border-left: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-top: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-bottom: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-top-left-radius: ${({ mode }) => (mode === 'MODAL' ? '0px' : '20px')};
  border-bottom-left-radius: ${({ mode }) =>
    mode === 'MODAL' ? '0px' : '20px'};

  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
  width: 70%;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #EDEDED;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette?.brand.primary.base};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette?.brand.primary.base};
  }
`;

export const InputAreaHeader = styled.div`
  width: 100%;
  padding: 38px 48px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      padding: 30px 48px;
  }
`;

export const InputContent = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  width: 100%;
  padding: ${({ mode }) => (mode === 'MODAL' ? '48px' : '0px')};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InputButtonArea = styled.div`
  background-color: #F7F7F7;
  width: 100%;
  padding: 30px 48px;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const PreviewArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  border-right: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-top: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-bottom: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-top-right-radius: ${({ mode }) => (mode === 'MODAL' ? '0px' : '20px')};
  border-bottom-right-radius: ${({ mode }) =>
    mode === 'MODAL' ? '0px' : '20px'};

  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding: ${({ mode }) => (mode === 'MODAL' ? '48px' : '24px 40px')};
  width: ${({ mode }) => (mode === 'MODAL' ? '30%' : '40%')};
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      padding: 28px;
  }
`;

export const TabArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 24px;
  margin: ${({ mode }) => (mode === 'MODAL' ? '0px 0px' : '40px 0px')};
`;

export const InputsWrapper = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`  
  margin-top: ${({ mode }) => (mode === 'MODAL' ? '48px' : '0px')};
  width: 100% ;
  padding: ${({ mode }) => (mode === 'MODAL' ? '0px' : '24px 40px')};
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 28px;
  }
`;

export const InputsGrid = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  margin-top: 32px;
  display: grid;
  grid-template-columns: ${({ mode }) =>
    mode === 'MODAL' ? '1fr 1fr 1fr' : '1fr 1fr'};
  grid-gap: 24px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      grid-gap: 16px;
  }
`;

export const PreviewItems = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  margin-top: ${({ mode }) => (mode === 'MODAL' ? '48px' : '32px')};
  width: 100%;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 20px;
  }
`;

export const PreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  position: relative;
`;

export const PreviewButtonsArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  width: 100%;
  margin-top: ${({ mode }) => (mode === 'MODAL' ? '48px' : '32px')};
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 20px;
  }
`;

export const ButtonsArea = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .btn-preview {
    display: flex;
    justify-content: space-around;
    gap: 8px;
  }
`;

export const ValidationQuestionMark = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 10px;
  background-color: ${({ theme }) => theme.palette?.brand.error[900]};
  font-size: 9px;
  line-height: 0px;
  border: 1px solid ${({ theme }) => theme.palette?.brand.primary.base};
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  cursor: pointer;
`;

export const ValidationDetails = styled.div`
  background-color: #424242;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
  color: white;
  position: absolute;
  top: 35px;
  max-width: 300px;
  right: 20%;
  z-index: 99991;

  &.review {
    width: 450px;
    left: -50% !important;

    &:before {
      left: 40%;
    }
  }

  &:before {
    content: " ";
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid  #424242;
    position: absolute;
    top: -10px;
    right: 40%;
  }
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    content: " ";
    z-index: 9999;
    background-color: transparent;
  }

  ul {
    li {
      white-space: nowrap;
    }
  }
`;
