import { useCallback, useContext, useState } from 'react';
import { useSnackbar } from '../../../app/hooks/useSnackbar';
import { MimeTypeToExtension } from '../helpers';
import { PersonFileHttpService } from '../services';
import {
  DOCUMENT_TYPE,
  PersonDocumentTypeCreation,
} from 'app/helpers/documentTypes';
import { DocumentsContext } from '../context';

export interface IFile {
  id?: string;
  file_name?: string;
  uploaded?: boolean;
  file: File | null;
  error?: boolean;
  type: DOCUMENT_TYPE;
  created_at?: string;
  invalidSize?: boolean;
  file_extension?: string;
}

const useUploadPersonFiles = () => {
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [uploadedFilesLoading, setUploadedFilesLoading] = useState(false);
  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const [downloadRequestLoading, setDownloadRequestLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { onOpenModal, onCloseModal } = useContext(DocumentsContext);

  const updateFile = useCallback((id: string, data: Partial<IFile>) => {
    setUploadedFiles((state) =>
      state.map((file) => (file.id === id ? { ...file, ...data } : file)),
    );
  }, []);

  const removeFile = (id: string) => {
    setUploadedFiles((state) => state.filter((item) => item.id !== id));
  };

  const addFile = useCallback((data: IFile) => {
    setUploadedFiles((state) => state.concat(data));
  }, []);

  const processUpload = useCallback(
    async (uploadedFile: IFile, personId: string) => {
      setUploadedFilesLoading(true);
      const data = new FormData();
      if (uploadedFile.file) {
        data.append('file', uploadedFile.file);
        data.append('type', uploadedFile.type);
      }

      const personFilesHttpService = new PersonFileHttpService();
      personFilesHttpService.setHeader({
        'Content-Type': `multipart/form-data`,
      });

      try {
        const result = await personFilesHttpService.uploadFilesToPerson(
          data,
          personId,
        );

        const updatedFile = {
          ...uploadedFile,
          id: result.data.id,
          uploaded: true,
          created_at: result.data.created_at,
        };

        addFile(updatedFile);
        setUploadedFilesLoading(false);
      } catch (error) {
        showSnackbar('Ocorreu um erro ao fazer o upload do arquivo');
        setUploadedFilesLoading(false);
      }
    },
    [updateFile],
  );

  const handleFileExtension = (file: File) => {
    const nameSplitted = file.name.split('.');
    return nameSplitted[nameSplitted?.length - 1 || 0];
  };

  const handleUploadFiles = useCallback(
    (files: File[], personId: string) => {
      const file = files[0];
      if (Number((file.size / (1024 * 1024)).toFixed(2)) > 20) {
        showSnackbar(
          `Arquivo inválido. ${file.name} possui tamanho superior a 20MB`,
        );
        return;
      }

      const excludeTypes = uploadedFiles.map(
        (uploadedFile) => uploadedFile.type,
      );
      onOpenModal(file.name, PersonDocumentTypeCreation, excludeTypes).then(
        (type) => {
          if (type !== false) {
            processUpload(
              {
                file,
                type,
                file_name: file.name,
                uploaded: false,
                error: false,
                file_extension: handleFileExtension(file) || 'file',
              },
              personId,
            ).then(() => onCloseModal());
          }
        },
      );
    },
    [uploadedFiles],
  );

  const fetchPersonFile = async (
    personId: string,
    originatorId?: string,
  ): Promise<IFile[]> => {
    try {
      const personFilesHttpService = new PersonFileHttpService();
      if (originatorId) {
        personFilesHttpService.setOriginatorId(originatorId);
      }
      const result = await personFilesHttpService.getFilesByPerson(personId);
      setUploadedFiles(result.data);
      return result.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleDeletePersonFile = useCallback(
    async (fileId: string, personId: string) => {
      setDeleteRequestLoading(true);
      try {
        const personFilesHttpService = new PersonFileHttpService();
        await personFilesHttpService.deleteFilesByPerson(personId, fileId);
        removeFile(fileId);
        setDeleteRequestLoading(false);
      } catch (error) {
        setDeleteRequestLoading(false);
        showSnackbar('Ocorreu um erro ao tentar remover o arquivo');
      }
    },
    [],
  );

  const downloadPersonFile = useCallback(
    async (personId: string, fileId: string, file: IFile) => {
      setDownloadRequestLoading(true);
      try {
        const personFilesHttpService = new PersonFileHttpService();
        personFilesHttpService.setRequestConfig({
          responseType: 'arraybuffer',
        });
        const result = await personFilesHttpService.downloadPersonFile(
          personId,
          fileId,
        );
        const fileData = result.data;
        const fileContentType = result.headers?.['content-type'];
        const url = URL.createObjectURL(new Blob([fileData]));
        const link = document.createElement('a');
        const hasExtension = Object.values(MimeTypeToExtension).findIndex(
          (ext) => file?.file_name?.includes(`.${ext}`),
        );
        link.href = url;
        if (hasExtension === -1) {
          link.setAttribute(
            'download',
            `${file?.file_name || 'document'}${
              MimeTypeToExtension[fileContentType || 'txt']
                ? `.${MimeTypeToExtension[fileContentType || 'txt']}`
                : ''
            }`,
          );
        } else {
          link.setAttribute('download', file.file_name!);
        }
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      } catch (error) {
        showSnackbar('Ocorreu um erro ao tentar fazer o download do arquivo');
      }
    },
    [],
  );

  return {
    handleUploadFiles,
    processUpload,
    fetchPersonFile,
    handleDeletePersonFile,
    downloadPersonFile,
    uploadedFiles,
    uploadedFilesLoading,
    deleteRequestLoading,
    downloadRequestLoading,
  };
};

export default useUploadPersonFiles;
