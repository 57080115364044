import { useTheme } from '@mui/material';
import {
  ButtonPrimary,
  CustomTableSelect,
  Heading3,
  TextM,
} from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { formatCurrency } from 'app/utils/normalizer';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import { TApplicationsParsed } from '../../../ApplicationContent/ApplicationContent';
import {
  EApplicationStatus,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
};

export interface IConditionContent {
  applicationData: Partial<TApplicationsParsed> | undefined;
  isStatusOptionsLoading: boolean;
  handleUpdateQualificationStatus: (
    applicationId: string,
    identifier: string,
  ) => void;
}

const ConditionContent = ({
  applicationData,
  isStatusOptionsLoading,
  handleUpdateQualificationStatus,
}: IConditionContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isProfileAuthorized } = usePermify();
  const { showSnackbarCopyLink } = useSnackbar();
  const { setSelectedOriginator } = useOriginatorContext();
  const [applicationState, setApplicationState] = useState<
    Partial<TApplicationsParsed> | undefined
  >(applicationData);

  const handleNavigate = () => {
    setSelectedOriginator(applicationData?.originator?.id as string);
    setTimeout(() =>
      navigate(`/products/application/${applicationData?.id}/details`),
    );
  };

  const handleClipboard = useCallback(() => {
    navigator.clipboard.writeText(
      `${document.location.origin}/#/products/application/${applicationData?.id}/details`,
    );
    showSnackbarCopyLink();
  }, []);

  const handleShowApplicationStatus = (
    application: Partial<TApplicationsParsed> | undefined,
  ) => {
    if (
      [
        EApplicationStatus.PENDING_QUALIFICATION,
        EApplicationStatus.PENDING_CESSION_QUALIFICATION,
      ].includes(application?.status as EApplicationStatus)
    ) {
      return (
        application?.qualificationData?.status ||
        EApplicationStatus.PENDING_RANDOM
      );
    } else {
      return application?.status;
    }
  };

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Condições</Heading3>

        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleNavigate}
          >
            <TextM
              weight="Semibold"
              style={{
                color: theme.palette.brand.primary.base,
                marginRight: '8px',
              }}
            >
              Ver página
            </TextM>
            <ArrowIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
        </div>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Status</TextM>
            </span>
            <span>
              <CustomTableSelect
                selectItems={
                  applicationState?.statusList || [{ label: '', value: '' }]
                }
                isLoading={isStatusOptionsLoading}
                disabled={
                  !isProfileAuthorized(
                    EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                  )
                }
                handleChange={(identifier) => {
                  handleUpdateQualificationStatus(
                    applicationData?.id as string,
                    identifier,
                  );

                  if (
                    (applicationData?.status ===
                      EApplicationStatus.PENDING_QUALIFICATION &&
                      !applicationData.qualificationData?.id) ||
                    applicationData?.status ===
                      EApplicationStatus.PENDING_SIGNATURE
                  ) {
                    const updatedStatus = {
                      ...applicationState,
                      status: identifier,
                    };

                    setApplicationState(
                      updatedStatus as Partial<TApplicationsParsed> | undefined,
                    );
                    return;
                  }
                  const updatedStatus:
                    | Partial<TApplicationsParsed>
                    | undefined = {
                    ...applicationState,
                    qualificationData: {
                      ...(applicationState?.qualificationData as TQualificationRequestApplication),
                      status: identifier,
                    },
                  };
                  setApplicationState(updatedStatus);
                }}
                value={handleShowApplicationStatus(applicationState) || ''}
              />
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Valor solicitado</TextM>
            </span>
            <span>
              <TextM>
                {formatCurrency(applicationState?.requested_amount)}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Valor da parcela</TextM>
            </span>
            <span>
              <TextM>
                {applicationState?.loan_details?.payment_amount
                  ? formatCurrency(
                      applicationState?.loan_details?.payment_amount,
                    )
                  : '-'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Valor Financiado</TextM>
            </span>
            <span>
              <TextM>
                {formatCurrency(
                  applicationState?.loan_details?.financed_amount,
                )}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Prazo</TextM>
            </span>
            <span>
              <TextM>
                {applicationState?.num_payments}{' '}
                {applicationState?.num_payments === 1 ? 'mês' : 'meses'}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Criado em</TextM>
            </span>
            <span>
              <TextM>
                {formatDateFromBackend(
                  applicationState?.created_at || '',
                ).toLocaleDateString()}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Vencimento da primeira parcela</TextM>
            </span>
            <span>
              <TextM>
                {formatDateFromBackend(
                  applicationState?.first_payment_date || '',
                ).toLocaleDateString()}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default ConditionContent;
