const CardsIcon = ({ color, ...props }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75 5.75012H9.25V9.50012H4.75V5.75012ZM3 11.2501V4.00012H11V11.2501H3ZM20 14.0001H14V15.6668H20V14.0001ZM20 17.3335H14V19.0001H20V17.3335ZM19.25 5.75012H14.75V9.50012H19.25V5.75012ZM13 4.00012V11.2501H21V4.00012H13ZM4.75 14.5001H9.25V18.2501H4.75V14.5001ZM3 20.0001V12.7501H11V20.0001H3Z"
      fill={color ?? '#A1A5B7'}
    />
  </svg>
);
export default CardsIcon;
