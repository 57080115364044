import { useTheme } from '@mui/material';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomTableSelect,
  ButtonPrimary,
  Spinner,
  ButtonDanger,
} from '../../../../../../app/components';
import {
  TextS,
  TextXs,
} from '../../../../../../app/components/typography/body';
import { TextL } from '../../../../../../app/components/typography/body/TextL';
import { formatCNPJ } from '../../../../../../app/helpers';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from '../../../../../../app/helpers/dateHelpers';
import {
  qualificationsActionType,
  TProducts,
  TQualification,
  useQualificationsContext,
} from '../../../../context';
import { EQualificationStatus } from '../../../../context/QualificationsProvider/qualifications.interfaces';
import { useQualificationsService } from '../../../../services/hooks';
import { BusinessDetailsSideSheet } from '../BusinessDetailsSideSheet';
import { EmptyContent } from '../EmptyContent';
import { ButtonContainer, Container, TableArea, TableWrapper } from './styles';
import { Table, TextM } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import { AddIcon, DetailsIcon } from 'app/components/Icons';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { PendenciesModal } from 'modules/products/components/PendenciesModal';
import { routingPath } from 'app/routes';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { useAppContext } from 'app/context/AppContextProvider';

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Empregador</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Criado em</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer style={{ textAlign: 'center' }}>
        <TextL weight="bold">Situação de preenchimento</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer style={{ maxWidth: '200px' }}>
        <TextL weight="bold">Status</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

const QualificationContent = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { totalElements, content: qualifications, selected, statusOptions },
    dispatch,
  } = useQualificationsContext();
  const {
    getProductQualifications,
    getQualificationStatusOptions,
    updateQualificationStatus,
    getQualificationStatusOptionsLoading,
    getProductQualificationsLoading,
    updateQualificationStatusLoading,
  } = useQualificationsService();
  const { is_issuer_app } = useAppContext();
  const [pendenciesModalOpen, updatePendenciesModalOpen] = useState<
    TQualification | undefined
  >(undefined);
  const productId = params?.id as string;
  const [productData, updateProductData] = useState<TProducts>();
  const [finishRequest, setFinishRequest] = useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty] = useState(totalElements);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const { isProfileAuthorized } = usePermify();

  useEffect(() => {
    getQualificationStatusOptions(productId);
    getProductQualificationsIn(currentPage, linesPerPage);
  }, [linesPerPage, currentPage]);

  const getProductQualificationsIn = async (
    pg: number,
    linesPerPageNumber: number,
  ) => {
    const product = await getProductQualifications(
      productId,
      'EMPLOYER',
      pg,
      linesPerPageNumber,
    );
    if (product) updateProductData(product);
    setFinishRequest(true);
  };

  const handleOpenQualificationDetail = (qualification: TQualification) => {
    dispatch({
      type: qualificationsActionType.SELECT_QUALIFICATION,
      payload: qualification,
    });
  };

  const handleCloseQualificationDetail = () => {
    dispatch({
      type: qualificationsActionType.SELECT_QUALIFICATION,
      payload: undefined,
    });
  };

  const handleUpdateQualificationStatus = (
    qualification: TQualification,
    identifier: string,
  ) => {
    const originator = is_issuer_app ? qualification.originator : undefined;
    updateQualificationStatus(
      productId,
      qualification.id,
      identifier,
      'EMPLOYER',
      originator,
    ).then(() => {
      if (selected) {
        dispatch({
          type: qualificationsActionType.SELECT_QUALIFICATION,
          payload: {
            ...selected,
            status: identifier,
          },
        });
      }
    });
  };

  const statusList = useMemo(
    () =>
      [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ].concat(
        statusOptions.map((status) => ({
          label: status.description,
          value: status.identifier,
        })),
        {
          label: 'Reprovado',
          value: EQualificationStatus.DENIED as string,
        },
      ),
    [statusOptions],
  );

  const isLoading =
    getProductQualificationsLoading || getQualificationStatusOptionsLoading;

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          marginBottom: '30px',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonContainer>
          {isProfileAuthorized(EAccountPermissions.CREATE_PERSON_BUSINESS) && (
            <ButtonPrimary
              style={{ backgroundColor: 'transparent', width: '230px' }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <AddIcon color={theme.palette.brand.primary.base as string} />
              }
              onClick={() =>
                navigate(`/${routingPath.qualification.new}`, {
                  state: {
                    product: productData,
                    borrowerType: EFunctions.EMPLOYER,
                  },
                })
              }
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Criar Linha de Crédito
              </TextM>
            </ButtonPrimary>
          )}
        </ButtonContainer>
      </div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
          }}
        >
          <Spinner />
        </div>
      ) : qualifications?.length <= 0 && finishRequest ? (
        <EmptyContent titleText="Nenhum empregador com linha de crédito encontrado" />
      ) : (
        statusOptions &&
        qualifications.length > 0 && (
          <TableArea>
            <TableWrapper>
              <Table
                showPagination
                overflowVisible
                handlePageChange={(selectedPage) => {
                  setCurrentPage(selectedPage - 1);
                }}
                handleLinesPerPage={(selectedLinesPerPage) => {
                  setCurrentPage(0);
                  setLinesPerPage(
                    selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                  );
                }}
                totalElementsQty={totalItemsQty}
                currentPage={currentPage + 1}
                linesPerPage={linesPerPage}
                tableHeadCell={tableHeader}
                tableBodyContent={qualifications.map((qualification) => {
                  const missingItems =
                    qualification &&
                    (qualification.missingDocuments.length > 0 ||
                      qualification.missingVariables.length > 0);
                  return {
                    id: qualification.id,
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {qualification.employer.legal_name}
                            </TextS>
                            <TextXs>
                              {qualification.employer.taxpayer_id &&
                                formatCNPJ(qualification.employer.taxpayer_id)}
                            </TextXs>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {`${formatDateFromBackend(
                                qualification?.created_at || '',
                              ).toLocaleDateString(
                                'pt-br',
                              )} às ${formatStringHourFromBackend(
                                qualification?.created_at || '',
                              )}`}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            {!missingItems ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <CheckCircleOutline
                                  style={{ marginRight: '5px' }}
                                  color="success"
                                />
                                <TextM
                                  style={{
                                    color: '#2e7d32',
                                  }}
                                >
                                  Sem pendências
                                </TextM>
                              </div>
                            ) : (
                              <ButtonDanger
                                typeVariant="ghost"
                                style={{
                                  fontWeight: 'normal',
                                  justifyContent: 'flex-start',
                                  padding: '0px',
                                }}
                                iconPosition="left"
                                onClick={() =>
                                  qualification &&
                                  updatePendenciesModalOpen(qualification)
                                }
                                btnIcon={
                                  <ErrorOutline
                                    color="error"
                                    style={{ marginRight: '5px' }}
                                  />
                                }
                              >
                                Visualizar pendências
                              </ButtonDanger>
                            )}
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        width: '215px',
                        content: (
                          <TableBodyCellContainer style={{ maxWidth: '300px' }}>
                            <CustomTableSelect
                              disabled={
                                !isProfileAuthorized(
                                  EAccountPermissions.UPDATE_QUALIFICATION,
                                )
                              }
                              selectItems={statusList}
                              isLoading={
                                updateQualificationStatusLoading ===
                                qualification.id
                              }
                              handleChange={(identifier) =>
                                handleUpdateQualificationStatus(
                                  qualification,
                                  identifier,
                                )
                              }
                              value={qualification.status}
                            />
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginLeft: '0px',
                            }}
                          >
                            <ButtonPrimary
                              typeVariant="outline"
                              style={{ width: 'fit-content' }}
                              onClick={() =>
                                handleOpenQualificationDetail(qualification)
                              }
                            >
                              <DetailsIcon
                                color={
                                  theme.palette.brand.primary.base as string
                                }
                              />
                            </ButtonPrimary>
                          </TableBodyCellContainer>
                        ),
                      },
                    ],
                  };
                })}
              />
            </TableWrapper>
          </TableArea>
        )
      )}
      <BusinessDetailsSideSheet
        handleClose={handleCloseQualificationDetail}
        handleUpdateQualificationStatus={handleUpdateQualificationStatus}
        qualification={selected as TQualification}
        statusList={statusList}
        isStatusLoading={updateQualificationStatusLoading === selected?.id}
        isOpen={!!selected}
      />
      <PendenciesModal
        isOpen={pendenciesModalOpen !== undefined}
        qualification={pendenciesModalOpen}
        handleClose={() => updatePendenciesModalOpen(undefined)}
      />
    </Container>
  );
};

export default QualificationContent;
