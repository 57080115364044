import styled from '@emotion/styled';

export const StepContent = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 32px;
  margin-top: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 15px;
  padding: 24px;
`;

export const QRCodeButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;
