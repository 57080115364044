import {
  AccountPermissionProperties,
  EAccountPermissions,
} from 'modules/auth/context/permify/permify.interfaces';

export type UserAccess = {
  id: string;
  status: 'ACTIVE' | 'PENDING' | 'INACTIVE';
  full_name: string;
  taxpayer_id: string;
  phone: string;
  email: string;
  created_at?: string;
  created_by?: string;
  deactivated_at?: string;
  permissions: EAccountPermissions[];
  permission_properties?: AccountPermissionProperties | null;
};

export const UserValidPermissions = [
  EAccountPermissions.DISBURSEMENT_ADMIN,
  EAccountPermissions.USER_ADMIN,
  EAccountPermissions.UPDATE_QUALIFICATION,
  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
  EAccountPermissions.CREATE_APPLICATION,
  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
  EAccountPermissions.CREATE_PERSON_BUSINESS,
  EAccountPermissions.UPDATE_PERSON_BUSINESS,
  EAccountPermissions.READ_KYC,
  EAccountPermissions.CREATE_KYC,
  EAccountPermissions.READ_SCR,
  EAccountPermissions.CREATE_SCR,
  EAccountPermissions.CNAB,
  EAccountPermissions.WITHDRAW,
  EAccountPermissions.CANCEL_APPLICATION,
  EAccountPermissions.APPROVE_APPLICATION,
  // EAccountPermissions.PORTAL_ADMIN,
];

export const UserActionPermissions = [
  EAccountPermissions.USER_ADMIN,
  EAccountPermissions.UPDATE_QUALIFICATION,
  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
  EAccountPermissions.CREATE_APPLICATION,
  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
  EAccountPermissions.CREATE_PERSON_BUSINESS,
  EAccountPermissions.UPDATE_PERSON_BUSINESS,
  EAccountPermissions.CREATE_KYC,
  EAccountPermissions.CREATE_SCR,
  EAccountPermissions.CNAB,
  EAccountPermissions.WITHDRAW,
  EAccountPermissions.CANCEL_APPLICATION,
  EAccountPermissions.APPROVE_APPLICATION,
  EAccountPermissions.PORTAL_ADMIN,
];

export const UserPermissionsDescription = {
  [EAccountPermissions.GUEST]: {
    title: 'Service Desk',
    isDisabled: false,
    description:
      'Permissão de acesso ao Service Desk, sem poder de ações dentro da plataforma.',
  },
  [EAccountPermissions.CREATE_PERSON_BUSINESS]: {
    title: 'Criar cadastro de pessoas, empresas e linha de crédito',
    isDisabled: false,
    description:
      'Controla a permissão sobre o cadastro inicial de uma pessoa, empresa ou linha de crédito na plataforma.',
  },
  [EAccountPermissions.UPDATE_QUALIFICATION]: {
    title: 'Aprovar e alterar status de linhas de crédito',
    isDisabled: false,
    description:
      'Controla a permissão sobre alteração de status ou aprovação de linhas de crédito de empresas e pessoas para um produto.',
  },
  [EAccountPermissions.UPDATE_PERSON_BUSINESS]: {
    title: 'Editar cadastro de pessoas e empresas',
    isDisabled: false,
    description:
      'Controla a permissão sobre o cadastro inicial de uma pessoa ou empresa na plataforma.',
  },
  [EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS]: {
    title: 'Definir condições',
    isDisabled: false,
    description:
      'Controla a permissão sobre a definição de condições para uma linha de crédito.',
  },
  [EAccountPermissions.CREATE_APPLICATION]: {
    title: 'Criar solicitação',
    isDisabled: false,
    description: 'Controla a permissão sobre a criação de novas solicitações.',
  },
  [EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION]: {
    title: 'Alterar status de solicitações',
    isDisabled: false,
    description:
      'Controla a permissão sobre a alteração de status das solicitações.',
  },
  [EAccountPermissions.CANCEL_APPLICATION]: {
    title: 'Cancelar solicitações',
    isDisabled: false,
    description: 'Controla a permissão sobre o cancelamento das solicitações.',
  },
  [EAccountPermissions.APPROVE_APPLICATION]: {
    title: 'Aprovar solicitações',
    isDisabled: false,
    description: 'Controla a permissão sobre a aprovação das solicitações.',
  },
  [EAccountPermissions.READ_KYC]: {
    title: 'Visualizar informações de KYC',
    isDisabled: false,
    description:
      'Controla a permissão sobre a visualização das informações de KYC de uma pessoa ou empresa.',
  },
  [EAccountPermissions.CREATE_KYC]: {
    title: 'Criar ou atualizar informações de KYC',
    isDisabled: false,
    description:
      'Controla a permissão sobre a criação ou atualização das informações de KYC de uma pessoa ou empresa.',
  },
  [EAccountPermissions.READ_SCR]: {
    title: 'Visualizar informações de SCR',
    isDisabled: false,
    description:
      'Controla a permissão sobre a visualização das informações de SCR de uma pessoa ou empresa.',
  },
  [EAccountPermissions.CREATE_SCR]: {
    title: 'Criar ou atualizar informações de SCR',
    isDisabled: false,
    description:
      'Controla a permissão sobre a criação ou atualização das informações de SCR de uma pessoa ou empresa.',
  },
  [EAccountPermissions.CNAB]: {
    title: 'Gerar relatório CNAB',
    isDisabled: false,
    description: 'Controla a permissão sobre a geração de relatórios CNAB.',
  },
  [EAccountPermissions.WITHDRAW]: {
    title: 'Solicitar saque',
    isDisabled: false,
    description: 'Controla a permissão sobre a solicitação de saque.',
  },
  [EAccountPermissions.USER_ADMIN]: {
    title: 'Admin. de usuários',
    isDisabled: false,
    description:
      'Pode criar novos usuários (incluindo outros administradores de \
      usuário), editar dados dos usuários existentes, e solicitar a \
      criação de uma nova senha para um usuário.',
  },
  [EAccountPermissions.DISBURSEMENT_ADMIN]: {
    title: 'Visualização de saldo e conciliação',
    isDisabled: false,
    description: '',
  },
  // [EAccountPermissions.PORTAL_ADMIN]: {
  //   title: 'Admin. de portais',
  //   isDisabled: false,
  //   description:
  //     'Pode criar Portais para Empregadores e realizar a gestão dos \
  //   usuários destes Portais.',
  // },
};
