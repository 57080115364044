const QRCodeAmountIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99952 13.3335C11.8404 13.3335 13.3329 11.8411 13.3329 10.0002C13.3329 8.15921 11.8404 6.66683 9.99952 6.66683C8.15855 6.66683 6.66616 8.15921 6.66616 10.0002C6.66616 11.8411 8.15855 13.3335 9.99952 13.3335ZM17.5033 3.33594H2.50326C2.04302 3.33594 1.66992 3.70903 1.66992 4.16927V15.8359C1.66992 16.2962 2.04302 16.6692 2.50326 16.6692H17.5033C17.9635 16.6692 18.3366 16.2962 18.3366 15.8359V4.16927C18.3366 3.70903 17.9635 3.33594 17.5033 3.33594ZM3.33659 13.0387V6.96159C4.27473 6.681 5.01449 5.94093 5.29465 5.0026H14.7044C14.9853 5.94344 15.7282 6.68495 16.6699 6.96383V13.0365C15.7266 13.3158 14.9827 14.0594 14.7029 15.0026H5.2961C5.01705 14.062 4.27636 13.3198 3.33659 13.0387Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeAmountIcon;
