import { InfoOutlined, Sync } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import { useState, useMemo } from 'react';
import { CustomSelectArrowIcon } from '../Icons';
import { TextS } from '../typography';
import {
  CustomSelectContainer,
  CustomSelectContent,
  CustomSelectDropdownItem,
  MenuContent,
  MenuOutside,
  MenuWrapper,
  SpinDiv,
} from './styles';

export type TCustomSelectOption = {
  value: string;
  label: string;
};

export interface ICustomSelect {
  value: string;
  selectItems: TCustomSelectOption[];
  isLoading?: boolean;
  disabled?: boolean;
  info?: string;
  handleChange?: (value: string) => void;
}

const getSelectItemColor = (itemValue: string, dflt = '#4B4662') => {
  switch (itemValue) {
    case 'QUALIFIED':
    case 'APPROVED':
    case 'ISSUED':
      return {
        text: '#246B30',
      };
    case 'DENIED':
    case 'SIGNATURE_ERROR':
    case 'CANCELED':
      return {
        text: '#9F0B0B',
      };
    default:
      return {
        text: dflt,
      };
  }
};

const CustomTableSelect = ({
  selectItems,
  value,
  isLoading,
  disabled,
  info,
  handleChange,
}: ICustomSelect) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (item: string) => {
    if (!isLoading) {
      setIsOpen(false);
      handleChange?.(item);
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (!isLoading) {
      e.currentTarget.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
      setIsOpen((state) => !state);
    }
  };

  const selectedItem = useMemo(() => {
    const selectedItemIndex = selectItems.findIndex(
      (item) => item.value === value,
    );
    if (selectedItemIndex >= 0) return selectItems[selectedItemIndex];
    return null;
  }, [value]);

  return (
    <>
      <CustomSelectContainer onClick={handleOpen} value={selectedItem?.value}>
        <CustomSelectContent disabled={disabled}>
          <TextS
            style={{
              color: getSelectItemColor(selectedItem?.value || '', '#8D4908')
                .text,
            }}
            weight="Bold"
          >
            {selectedItem?.label}
          </TextS>
          {typeof info === 'string' && info.length > 0 && (
            <Tooltip
              title={
                <TextS style={{ color: theme.palette.common.white }}>
                  {info}
                </TextS>
              }
            >
              <InfoOutlined
                fontSize="small"
                style={{
                  marginLeft: '0.3rem',
                  color: getSelectItemColor(selectedItem?.value || '').text,
                }}
              />
            </Tooltip>
          )}
          {isLoading && (
            <SpinDiv>
              <Sync />
            </SpinDiv>
          )}
          {!disabled && (
            <CustomSelectArrowIcon
              color={theme.palette.brand.secondary.base as string}
            />
          )}
        </CustomSelectContent>
      </CustomSelectContainer>
      <MenuWrapper className="mwrapper">
        {isOpen && (
          <>
            <MenuOutside onClick={() => setIsOpen(false)} />
            <MenuContent>
              {selectItems.map((item) => (
                <CustomSelectDropdownItem
                  onClick={() => handleClick(item.value)}
                  key={item.value}
                  value={item.value}
                >
                  <TextS
                    style={{ color: getSelectItemColor(item.value).text }}
                    weight="Bold"
                  >
                    {item.label}
                  </TextS>
                </CustomSelectDropdownItem>
              ))}
            </MenuContent>
          </>
        )}
      </MenuWrapper>
    </>
  );
};

export default CustomTableSelect;
