import { ButtonPrimary, StepContent } from 'app/components';
import { useState, useContext } from 'react';
import {
  ListBox,
  ListBoxItem,
  ListBoxItemTitle,
} from '../../ApplicationRequest/components';
import { StepContentButtonsArea } from './styles';
import { QualificationRequestStepperContext } from '../QualificationRequest';
import { formatCNPJ } from 'app/helpers';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import { OriginatorType } from 'modules/common/OriginatorProvider/types';

const OriginatorStep = () => {
  const { originators } = useOriginatorContext();
  const [searchText, updateSearchText] = useState('');
  const { originator, updateOriginator, onForward } = useContext(
    QualificationRequestStepperContext,
  );
  const [selectedOriginator, setSelectedOriginator] = useState<string | null>(
    originator,
  );

  return (
    <StepContent
      title="Originador"
      description="Selecione o originador para a linha de crédito"
    >
      <ListBox
        filterItems={[{ name: 'Nome', parameterName: 'legal_name' }]}
        items={originators?.filter(
          (originatorItem) =>
            !searchText ||
            originatorItem.legal_name
              .toLowerCase()
              .includes(searchText.toLowerCase()),
        )}
        onSearch={(field, value) => updateSearchText(value)}
      >
        {(i) => {
          const item = i as OriginatorType;
          return (
            <ListBoxItem
              className={selectedOriginator === item.id ? 'selected' : ''}
              onClick={() => setSelectedOriginator(item.id)}
              key={item.legal_name}
            >
              <ListBoxItemTitle>{item.legal_name}</ListBoxItemTitle>
              <span>{formatCNPJ(item.taxpayer_id)}</span>
            </ListBoxItem>
          );
        }}
      </ListBox>
      <StepContentButtonsArea>
        <ButtonPrimary
          disabled={!selectedOriginator}
          onClick={() => {
            if (selectedOriginator) {
              updateOriginator(selectedOriginator);
              setTimeout(() => onForward());
            }
          }}
        >
          Avançar
        </ButtonPrimary>
      </StepContentButtonsArea>
    </StepContent>
  );
};

export default OriginatorStep;
