import { useContext, useCallback, useState, useRef } from 'react';
import { Divider, useTheme } from '@mui/material';
import { ConfirmModal } from '../../../../../../app/components';
import { BusinessEditSideSheet } from '../../../../components/BusinessEditSideSheet';
import { NaturalPersonViewPageContext } from '../../NaturalPersonViewPage';
import { formatCNPJ, formatPhone } from '../../../../../../app/helpers';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from '../../../../context';
import { TextL, TextS } from 'app/components';
import { AttentionCircularRedIcon } from 'app/components/Icons/AttentionCircularIcon';
import { useCustomerService } from 'modules/customer/services';
import {
  InfoArea,
  ListBoxItem,
} from 'modules/customer/pages/NaturalPersonHandler/steps/styles';
import { ListBox } from 'modules/products/pages/ApplicationRequest/components';

const BorrowerInfoDivider = () => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        backgroundColor: '#E6E5EA',
        height: '21px',
        width: '2px',
        margin: '0px 24px',
      }}
    />
  );
};

export interface INaturalPersonEditEmployerSection {
  personData: TRegisterPersonDataRequest;
  handleClose: () => void;
  isOpen: boolean;
}

const NaturalPersonEditEmployerSection = ({
  personData,
  handleClose,
  isOpen,
}: INaturalPersonEditEmployerSection) => {
  const theme = useTheme();
  const [isListLoading, setIsListLoading] = useState(false);
  const { getBusinessList, creatBusinessLoading } = useCustomerService();
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const { updatePerson, isLoading } = useContext(NaturalPersonViewPageContext);
  const [selectedEmployer, setSelectedEmployer] =
    useState<TRegisterBusinessDataRequest>();
  const [employerList, setEmployerList] = useState<
    TRegisterBusinessDataRequest[]
  >([]);
  const currentPage = useRef<number>(0);
  const searchParameter = useRef<string>('');
  const searchValue = useRef<string>('');
  const { employer } = personData;

  const onConfirmAction = useCallback(() => {
    toggleConfirmModal(false);
    updatePerson({
      ...personData,
      employer: undefined,
    }).then(() => {
      onClose();
    });
  }, [personData]);

  const onSave = () => {
    updatePerson({
      ...personData,
      employer: {
        id: selectedEmployer?.id,
      },
    }).then(() => {
      onClose();
    });
  };

  const onClose = () => {
    handleClose();
  };

  const handleGetBusinessList = async (
    page: number,
    searchParameterName?: string,
    searchParameterValue?: string,
  ) => {
    setIsListLoading(true);
    const businessListRes = await getBusinessList(
      page,
      searchParameterName,
      searchParameterValue,
      undefined,
      true,
    );
    setEmployerList((state) =>
      state?.concat(
        businessListRes?.content.filter((item) => {
          const index = state?.findIndex((el) => el.id === item.id);
          return index < 0;
        }) || [],
      ),
    );
    currentPage.current = page;
    setIsListLoading(false);
  };

  const onSearch = (field: string, value: string) => {
    searchParameter.current = field;
    searchValue.current = value;
    currentPage.current = 0;
    setEmployerList([]);

    if (field === '') {
      handleGetBusinessList(0);
      return;
    }
    handleGetBusinessList(0, field, value);
  };

  return (
    <BusinessEditSideSheet
      open={isOpen}
      handleClose={onClose}
      handleSave={onSave}
      isLoading={isListLoading || creatBusinessLoading}
      isBigger={true}
      isRemoveDisabled={
        typeof employer?.id !== 'string' || employer?.id.length === 0
      }
      handleRemove={() => toggleConfirmModal(true)}
      title="Empregador"
    >
      <ListBox
        fullHeight={true}
        noGutters={true}
        filterItems={[
          { name: 'Nome', parameterName: 'legal_name' },
          { name: 'CNPJ', parameterName: 'taxpayer_id' },
        ]}
        items={employerList || []}
        onSearch={onSearch}
        isLoading={isLoading || creatBusinessLoading}
        handleRequest={() => {
          handleGetBusinessList(
            currentPage.current + 1,
            searchParameter.current ? searchParameter.current : undefined,
            searchValue.current ? searchValue.current : undefined,
          );
        }}
      >
        {(i) => {
          const item = i as TRegisterBusinessDataRequest;
          return (
            <ListBoxItem
              className={item.id === selectedEmployer?.id ? 'selected' : ''}
              onClick={() => setSelectedEmployer(item)}
              key={item.id || ''}
            >
              <TextL
                style={{
                  color: theme.palette.brand.secondary.base,
                }}
                weight="semibold"
              >
                {item.legal_name}
              </TextL>
              <InfoArea>
                <TextS>{formatCNPJ(item.taxpayer_id)}</TextS>
                <BorrowerInfoDivider />
                <TextS>
                  {formatPhone(item.phone.area_code + item.phone.number || '')}
                </TextS>
                <BorrowerInfoDivider />
                <TextS>{item.email_address}</TextS>
              </InfoArea>
            </ListBoxItem>
          );
        }}
      </ListBox>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={isConfirmModalOpen}
        icon={<AttentionCircularRedIcon />}
        title="O empregador será desvinculado dessa pessoa."
        btnConfirmText="Confirmar"
        color="danger"
        handleConfirm={onConfirmAction}
        handleClose={() => toggleConfirmModal(false)}
      >
        Você pode incluí-lo novamente depois.
      </ConfirmModal>
    </BusinessEditSideSheet>
  );
};

export default NaturalPersonEditEmployerSection;
