/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseHttpService } from '../../../../app/services';
import { IConditionUpdate } from '../hooks/intefaces';

export type TgetProductQualifications = 'EMPLOYER' | 'BORROWER' | 'APPLICATION';

export class QualificationsHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getQualificationStatusOptions = async (productId: string) => {
    const res = await this.get(
      `${this.baseUrl}/issuer/products/${productId}/statuses-definitions`,
    );
    return res;
  };

  getProductQualifications = async (
    productId: string,
    role: TgetProductQualifications = 'EMPLOYER',
    page = 0,
    linesPerPage = 10,
    isIssuer?: boolean,
  ) => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${
        this.baseUrl
      }/${entity}/products/${productId}/qualification-requests?role=${role}&page=${page}&size=${
        linesPerPage || 10
      }`,
    );
    return res;
  };

  updateApplicationQualificationStatus = async (
    productId: string,
    qualificationId: string,
    status: { status: string },
  ) => {
    const res = await this.put(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests/${qualificationId}/qualify`,
      status,
    );
    return res;
  };

  updateQualificationStatus = async (
    productId: string,
    borrowerId: string | undefined,
    qualificationId: string,
    status: { status: string },
  ) => {
    return await this.put(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests/${borrowerId}/qualify?qualificationId=${qualificationId}`,
      status,
    );
  };

  updateLineOfCreditStatus = async (
    productId: string,
    qualificationId: string,
    status: { status: string },
  ) => {
    return await this.put(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests/${qualificationId}/qualify`,
      status,
    );
  };

  updateLimitsStatus = async (
    productId: string,
    limitId: string,
    status: { status: string },
  ) => {
    console.log('STATUS', status);
    const res = await this.put(
      `${this.baseUrl}/originator/products/${productId}/limits/${limitId}/status`,
      status,
    );
    return res;
  };

  getBorrowerQualifications = async (
    productId: string,
    page = 0,
    itemsPerPage: number,
    isIssuer?: boolean,
  ) => {
    const entity = isIssuer ? 'issuer' : 'originator';
    const res = await this.get(
      `${this.baseUrl}/${entity}/products/${productId}/limits?role=BORROWER&page=${page}&size=${itemsPerPage}`,
    );

    return res;
  };

  getBorrowerQualification = async (
    productId: string,
    borrowerId: string,
    page = 0,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=BORROWER&borrower_id=${borrowerId}&page=${page}&size=10`,
    );

    return res;
  };

  getBorrowerEnabledLimits = async (
    productId: string,
    borrowerId: string,
    limitId: string,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/limits/${limitId}?borrowerId=${borrowerId}`,
    );

    return res;
  };

  getBorrowerLimits = async (
    productId: string,
    borrowerId: string,
    page = 0,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/limits?role=BORROWER&borrowerId=${borrowerId}&page=${page}&size=10`,
    );

    return res;
  };

  getEmployerQualification = async (
    productId: string,
    employerId: string,
    page = 0,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=EMPLOYER&employer_id=${employerId}&page=${page}&size=10`,
    );

    return res;
  };

  getEmployerLimits = async (
    productId: string,
    employerId: string,
    page = 0,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/limits?role=EMPLOYER&employer_id=${employerId}&page=${page}&size=10`,
    );

    return res;
  };

  getBorrowerQualificationApplication = async (
    productId: string,
    borrowerId: string,
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?role=APPLICATION&borrower_id=${borrowerId}`,
    );

    return res;
  };

  getQualificationByApplication = async (
    productId: string,
    applicationId: string,
    role = 'APPLICATION',
  ) => {
    const res = await this.get(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests?application_id=${applicationId}&role=${role}`,
    );

    return res;
  };

  updateQualificationRequestCondition = async (
    productId: string,
    borrowerId: string,
    data: Partial<IConditionUpdate>,
  ) => {
    const res = await this.put(
      `${this.baseUrl}/originator/products/${productId}/qualification-requests/${borrowerId}/conditions`,
      data,
    );

    return res;
  };

  updateLimitsCondition = async (
    productId: string,
    qualificationId: string,
    data: Partial<IConditionUpdate>,
  ) => {
    const res = await this.put(
      `${this.baseUrl}/originator/products/${productId}/limit/${qualificationId}`,
      data,
    );

    return res;
  };
}
