const QRCodeTaxpayerIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5006 14.7878H2.50065V4.78776H17.5006V14.7878ZM1.66732 3.12109C1.20708 3.12109 0.833984 3.49419 0.833984 3.95443V15.6211C0.833984 16.0813 1.20708 16.4544 1.66732 16.4544H18.334C18.7942 16.4544 19.1673 16.0813 19.1673 15.6211V3.95443C19.1673 3.49419 18.7942 3.12109 18.334 3.12109H1.66732ZM6.66732 7.28776C7.12755 7.28776 7.50065 7.66086 7.50065 8.12109C7.50065 8.58134 7.12755 8.95443 6.66732 8.95443C6.20708 8.95443 5.83398 8.58134 5.83398 8.12109C5.83398 7.66086 6.20708 7.28776 6.66732 7.28776ZM6.66732 10.6211C8.04803 10.6211 9.16732 9.50184 9.16732 8.12109C9.16732 6.74039 8.04803 5.62109 6.66732 5.62109C5.28661 5.62109 4.16732 6.74039 4.16732 8.12109C4.16732 9.50184 5.28661 10.6211 6.66732 10.6211ZM4.60643 13.9753C5.13515 13.4467 5.86318 13.1211 6.66882 13.1211C7.47447 13.1211 8.20249 13.4467 8.73123 13.9753L9.90973 12.7968C9.08123 11.9683 7.93424 11.4544 6.66882 11.4544C5.4034 11.4544 4.25643 11.9683 3.42792 12.7968L4.60643 13.9753ZM15.834 7.28776H11.6673V8.95443H15.834V7.28776ZM15.834 10.6211H11.6673V12.2878H15.834V10.6211Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeTaxpayerIcon;
