import { TextM } from '..';
import {
  ErrorList,
  ErrorListArea,
  ErrorListAreaIcon,
  ErrorListItemsList,
} from '../ErrorList';
import { ErrorListCircularIcon } from 'app/components/Icons';

type QRCodeErrorsProps = {
  error: string;
};
const QRCodeErrors = ({ error }: QRCodeErrorsProps) => {
  return (
    <>
      {error.length > 0 && (
        <ErrorListArea>
          <ErrorListAreaIcon>
            <ErrorListCircularIcon />
          </ErrorListAreaIcon>
          <ErrorList>
            <TextM weight="Bold" style={{ color: '#220B64' }}>
              Este QR Code é inválido
            </TextM>
            <ErrorListItemsList>{error}</ErrorListItemsList>
          </ErrorList>
        </ErrorListArea>
      )}
    </>
  );
};

export default QRCodeErrors;
