import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DashboardContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette?.brand.gray[50]};
  padding: 50px 40px;
  min-height: 100vh;
    
  @media screen and (max-width: 1920px) {
    zoom: 0.9;
  }

  @media screen and (max-width: 1800px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1706px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1537px) {
    zoom: 0.75;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;
export interface IContent {
  activeOverlay: boolean;
}

export const Wrapper = styled.div<IContent>`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  ${({ activeOverlay }) =>
    activeOverlay &&
    css`
    filter: blur(8px) brightness(0.8) drop-shadow(16px 16px 16px rgba(53, 53, 53, 0.32));
    pointer-events: none;
    padding: 0;
  `}
`;

export const Content = styled.div<{ isCollapsed: boolean }>`
  width: ${({ isCollapsed }) => (isCollapsed ? '95%' : '86%')};
  transition: width 0.7s;
  height: 100%;
`;

export const SideMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
    
  @media screen and (max-width: 1920px) {
    zoom: 0.9;
  }

  @media screen and (max-width: 1800px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1706px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1537px) {
    zoom: 0.75;
  }
`;

export const ImageArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
`;

export const ButtonArea = styled.div`
  margin-top: 40px;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SideMenuDrawerItemContent = styled.div`
  width: 100%;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 4px 0px;

  p {
    color: ${({ theme }) => theme.palette?.brand.secondary.base};

    &:hover {
        color: ${({ theme }) => theme.palette?.brand.primary.base}
    }
  }
`;

export interface ISideMenuDrawerItemContentTextProps {
  isSelected?: boolean;
}

export const SideMenuDrawerItemContentText = styled.div<ISideMenuDrawerItemContentTextProps>`
  padding: 0px 0px 2px;

  &:last-child {
    padding: 0px;
  }
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      p {
        color: ${theme.palette?.brand.primary.base};
      }
  `}
`;
