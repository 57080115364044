import { useEffect, useState } from 'react';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import {
  BoxSection,
  ButtonPrimary,
  Heading3,
  NoItemsRegistered,
} from '../../../../../../app/components';
import { useTheme } from '@mui/material';
import { useProductsService } from '../../../../services/hooks';
import { useProductsContext } from '../../../../context';
import { Container, FilterArea, SearchInputContainer } from './styles';
import { Spinner } from 'app/components';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import ProductTable from '../ProductTable/ProductTable';
import SearchWhiteIcon from 'app/components/Icons/SearchWhiteIcon';
import { SearchFilterInput } from 'app/components/SearchFilter';
import { CardsIcon, ListIcon } from 'app/components/Icons';
import { ProductCardList } from '../ProductCardList';
import { VIEW_TYPE_KEY } from 'modules/products/constants/storage';

enum ViewType {
  CARDS = 'CARDS',
  LIST = 'LIST',
}

const MainPageContent = () => {
  const theme = useTheme();
  const [viewType, setViewType] = useState<ViewType>();
  const [searchText, updateSearchText] = useState('');
  const { getProducts, getProductsLoading } = useProductsService();
  const {
    state: { content },
  } = useProductsContext();
  const { setSelectedOriginator } = useOriginatorContext();

  const reloadViewTypeFromStorage = () => {
    const viewTypeStorage = localStorage.getItem(VIEW_TYPE_KEY);
    if (viewTypeStorage) {
      setViewType(viewTypeStorage as ViewType);
    } else {
      setViewType(ViewType.CARDS);
    }
  };

  useEffect(() => {
    reloadViewTypeFromStorage();
    setSelectedOriginator(null);
    getProducts();
  }, []);

  useEffect(() => {
    if (viewType) localStorage.setItem(VIEW_TYPE_KEY, viewType);
  }, [viewType]);

  return (
    <Container>
      <Heading3 fontWeight="Sb">Produtos</Heading3>
      <BoxSection style={{ marginTop: '32px' }} boxVariant="rounded">
        <FilterArea>
          <SearchInputContainer>
            <SearchWhiteIcon />
            <SearchFilterInput
              handleClearInput={() => updateSearchText('')}
              handleInputChange={(evt) =>
                updateSearchText(evt.currentTarget.value)
              }
              searchItemName="produtos"
              value={searchText}
            />
          </SearchInputContainer>
          <ButtonPrimary
            style={{
              width: '48px',
              height: '48px',
              maxHeight: 'unset',
              marginLeft: '12px',
              boxShadow: '0px 2px 2px 0px #0000001A',
              padding: 'unset',
              borderColor:
                viewType === ViewType.CARDS
                  ? (theme.palette.brand.primary.base as string)
                  : undefined,
            }}
            typeVariant="outline"
            onClick={() => setViewType(ViewType.CARDS)}
          >
            <CardsIcon
              color={
                viewType === ViewType.CARDS
                  ? (theme.palette.brand.primary.base as string)
                  : undefined
              }
            />
          </ButtonPrimary>
          <ButtonPrimary
            style={{
              width: '48px',
              height: '48px',
              maxHeight: 'unset',
              marginLeft: '12px',
              boxShadow: '0px 2px 2px 0px #0000001A',
              padding: 'unset',
              borderColor:
                viewType === ViewType.LIST
                  ? (theme.palette.brand.primary.base as string)
                  : undefined,
            }}
            typeVariant="outline"
            onClick={() => setViewType(ViewType.LIST)}
          >
            <ListIcon
              color={
                viewType === ViewType.LIST
                  ? (theme.palette.brand.primary.base as string)
                  : undefined
              }
            />
          </ButtonPrimary>
        </FilterArea>
        {getProductsLoading ? (
          <Spinner />
        ) : content.length > 0 ? (
          <>
            {viewType === ViewType.LIST && (
              <ProductTable searchText={searchText} />
            )}
            {viewType === ViewType.CARDS && (
              <ProductCardList searchText={searchText} />
            )}
          </>
        ) : (
          <NoItemsRegistered
            buttonText="Adicionar produto"
            icon={
              <AccountBalanceOutlined
                sx={{ color: theme.palette.brand.gray[100], fontSize: '160px' }}
              />
            }
            title="Nenhum produto cadastrado"
          />
        )}
      </BoxSection>
    </Container>
  );
};

export default MainPageContent;
