const WarningIcon = ({ color, ...props }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8656 3.0002L22.3916 19.5002C22.4794 19.6522 22.5256 19.8247 22.5256 20.0002C22.5256 20.1757 22.4794 20.3482 22.3916 20.5002C22.3038 20.6522 22.1776 20.7784 22.0256 20.8662C21.8736 20.954 21.7011 21.0002 21.5256 21.0002H2.47361C2.29807 21.0002 2.12563 20.954 1.97362 20.8662C1.8216 20.7784 1.69537 20.6522 1.6076 20.5002C1.51984 20.3482 1.47363 20.1757 1.47363 20.0002C1.47363 19.8247 1.51984 19.6522 1.60761 19.5002L11.1336 3.0002C11.2214 2.84819 11.3476 2.72197 11.4996 2.63421C11.6516 2.54645 11.8241 2.50024 11.9996 2.50024C12.1751 2.50024 12.3476 2.54645 12.4996 2.63421C12.6516 2.72197 12.7778 2.84819 12.8656 3.0002ZM4.20561 19.0002H19.7936L11.9996 5.5002L4.20561 19.0002ZM10.9996 16.0002H12.9996V18.0002H10.9996V16.0002ZM10.9996 9.0002H12.9996V14.0002H10.9996V9.0002Z"
      fill={color ?? '#9390A3'}
    />
  </svg>
);
export default WarningIcon;
