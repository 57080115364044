export const IOFTYPES = [
  { value: 'PERSON', label: 'PF' },
  { value: 'BUSINESS', label: 'PJ' },
  { value: 'BUSINESS_SIMPLE', label: 'PJ Simples' },
  { value: 'ZERO', label: 'Zerado' },
];

export const IOFTYPES_TRANSLATED_EN_PT: { [key: string]: string } = {
  PERSON: 'PF',
  BUSINESS: 'PJ',
  BUSINESS_SIMPLE: 'PJ Simples',
  ZERO: 'Zerado',
};

export const AMORTIZATION_TYPES = [
  { value: 'PRICE', label: 'Price' },
  { value: 'AMERICAN', label: 'Americana' },
];

export const AMORTIZATION_TYPES_TRANSLATED_EN_PT: { [key: string]: string } = {
  PRICE: 'Price',
  AMERICAN: 'Americana',
};

export const FINANCED_IOF = [
  { value: 'true', label: 'Sim' },
  { value: 'false', label: 'Não' },
];

export const FINANCED_IOF_TRANSLATED_EN_PT: { [key: string]: string } = {
  true: 'Sim',
  false: 'Não',
};

export const INTEREST_COMPOUNDING_TYPES = [
  { value: 'COMPOUND', label: 'Composto' },
  { value: 'SIMPLE', label: 'Simples' },
];

export const INTEREST_COMPOUNDING_TYPES_TRANSLATED_EN_PT: {
  [key: string]: string;
} = {
  COMPOUND: 'Composto',
  SIMPLE: 'Simples',
};

export const SCHEDULE_TYPES_TRANSLATED_EN_PT: { [key: string]: string } = {
  DAILY: 'Diário',
  WEEKLY: 'Semanal',
  BIWEEKLY: 'Quinzenal',
  MONTHLY: 'Mensal',
};

export const SCHEDULE_TYPES = [
  { value: 'DAILY', label: 'Diário' },
  { value: 'WEEKLY', label: 'Semanal' },
  { value: 'BIWEEKLY', label: 'Quinzenal' },
  { value: 'MONTHLY', label: 'Mensal' },
];
export const TABS_CONTENT = [
  'Solicitado',
  'Total devido',
  'Da parcela',
  'Financiado',
];

export const ADDITIONAL_FEE = [
  { value: 'false', label: 'Não' },
  { value: 'true', label: 'Sim' },
];
