import { useTheme } from '@mui/material';
import { Spinner } from 'app/components';
import { useAuthContext } from 'modules/auth/context';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from 'react-router-dom';
import { AuthRoutes } from '../../modules/auth';
import { CustomerRoutes } from '../../modules/customer';
import { ProductRoutes } from '../../modules/products';
import { DisbursementRoutes } from '../../modules/disbursement';
import { useThemeContext } from '../theme';
import { routingPath } from './config';
import { ManagementRoutes } from 'modules/management';
import CessionRoutes from 'modules/cession/routes/CessionRoutes';
import { OperationRoutes } from 'modules/operations';

export const AppRoutes = () => {
  const location = useLocation();
  const { loading } = useThemeContext();
  const { isLogged, authLoading } = useAuthContext();
  const theme = useTheme();

  if (loading || authLoading) {
    return (
      <Routes location={location} key={location.pathname}>
        <Route
          path="*"
          element={
            <div
              style={{
                height: '100%',
                minHeight: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '30px',
                flexDirection: 'column',
              }}
            >
              <h1 style={{ color: theme.palette.brand.secondary.base }}>
                Carregando suas configurações...
              </h1>
              <Spinner />
            </div>
          }
        />
      </Routes>
    );
  }

  return useRoutes([
    AuthRoutes(),
    CustomerRoutes(),
    ProductRoutes(),
    OperationRoutes(),
    DisbursementRoutes(),
    ManagementRoutes(),
    CessionRoutes(),
    {
      path: '*',
      element: (
        <Navigate
          to={isLogged ? routingPath.products.main : routingPath.auth.login}
        />
      ),
    },
  ]);
};

export default AppRoutes;
