import { TypeProductEmission } from '../context/ProductsProvider/products.interfaces';
import { ESignatureCollectMethod } from '../services/hooks/useApplicationsService';

export const SignatureCollectObject = {
  [ESignatureCollectMethod.NONE]: 'Manual',
  [ESignatureCollectMethod.SMS]: 'SMS',
  [ESignatureCollectMethod.LINK]: 'Link',
  [ESignatureCollectMethod.EMAIL]: 'E-mail',
  [ESignatureCollectMethod.PHYSICAL]: 'Físico',
  [ESignatureCollectMethod.WHATSAPP]: 'WhatsApp',
};

export const ProductType = {
  [TypeProductEmission.BILLET_PAYMENT]: 'Boleto',
  [TypeProductEmission.COMMERCIAL_PAPER]: 'Nota Comercial',
  [TypeProductEmission.CCB]: 'CCB',
  [TypeProductEmission.QRCODE_PIX]: 'Pix Copia e Cola',
};
