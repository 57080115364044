const QRCodePersonIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33398 18.334C3.33398 16.5659 4.03636 14.8702 5.28661 13.6199C6.53685 12.3697 8.23254 11.6673 10.0007 11.6673C11.7688 11.6673 13.4645 12.3697 14.7147 13.6199C15.9649 14.8702 16.6673 16.5659 16.6673 18.334H15.0007C15.0007 17.0079 14.4739 15.7361 13.5362 14.7984C12.5985 13.8608 11.3267 13.334 10.0007 13.334C8.67457 13.334 7.4028 13.8608 6.46512 14.7984C5.52744 15.7361 5.00065 17.0079 5.00065 18.334H3.33398ZM10.0007 10.834C7.23815 10.834 5.00065 8.59648 5.00065 5.83398C5.00065 3.07148 7.23815 0.833984 10.0007 0.833984C12.7632 0.833984 15.0007 3.07148 15.0007 5.83398C15.0007 8.59648 12.7632 10.834 10.0007 10.834ZM10.0007 9.16732C11.8423 9.16732 13.334 7.67565 13.334 5.83398C13.334 3.99232 11.8423 2.50065 10.0007 2.50065C8.15898 2.50065 6.66732 3.99232 6.66732 5.83398C6.66732 7.67565 8.15898 9.16732 10.0007 9.16732Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodePersonIcon;
