import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { openApiErrorModal } from 'app/components/ModalApiError/apiErrorEventManager';
import { TOKEN_COOKIE } from 'modules/auth/constants/cookies';
import { ORIGINATOR_COOKIE } from 'modules/common/OriginatorProvider/constants';

const getCurrentAccessToken = () => {
  try {
    return Cookies.get(TOKEN_COOKIE);
  } catch (e) {
    return undefined;
  }
};

const getCurrentOriginatorContext = () => {
  try {
    return Cookies.get(ORIGINATOR_COOKIE);
  } catch (e) {
    return undefined;
  }
};

const configureAxiosInterceptors = () => {
  axios.interceptors.request.use(
    async (config) => {
      if (
        !config.url?.startsWith('https://s3.') &&
        !config.url?.includes('static.keepo.io')
      ) {
        const token = getCurrentAccessToken();
        if (config.headers && token) {
          config.headers['Authorization'] = token;
        }
        const originator = getCurrentOriginatorContext();
        if (
          config.headers &&
          originator &&
          config.url?.includes('/originator')
        ) {
          config.headers['originator'] = originator;
        }

        if (
          config.headers &&
          typeof config.headers['forceOriginator'] === 'string'
        ) {
          config.headers['originator'] = config.headers['forceOriginator'];
          delete config.headers['forceOriginator'];
        }
      }
      return config;
    },
    (e) => {
      Promise.reject(e);
    },
  );
};

const configureAxiosInterceptorsResponse = () => {
  axios.interceptors.response.use(
    async (config) => {
      return config;
    },
    (error: AxiosError<{ message: string | undefined }>) => {
      if (
        !error.config?.headers?.handled &&
        error?.response?.status &&
        error?.response?.status >= 400 &&
        error?.response?.status !== 401 &&
        error?.response?.status !== 404
      ) {
        openApiErrorModal({
          request_body: JSON.stringify(error.config?.data),
          errorMessage: error?.response?.data?.message,
          endpoint: error?.response?.request?.responseURL,
          method: error?.response?.config?.method,
          response_body: JSON.stringify(error?.response?.data),
          response_status: error?.response?.status?.toString(),
        });
      }
      if (error?.response?.status === 401) {
        Auth.signOut().then(() => {
          location.href = '/';
        });
      }
      return Promise.reject(error);
    },
  );
};

export class BaseHttpService {
  requestConfig: AxiosRequestConfig = {};

  constructor(public token?: string) {
    configureAxiosInterceptors();
    configureAxiosInterceptorsResponse();
  }

  setOriginatorId = (originatorId: string) => {
    this.requestConfig.headers = {
      ...this.requestConfig.headers,
      forceOriginator: originatorId,
    };
  };

  setRequestConfig = (params: AxiosRequestConfig) => {
    this.requestConfig = {
      ...params,
    };
  };

  setHeader = (headers: AxiosRequestConfig['headers']) => {
    this.requestConfig.headers = {
      ...this.requestConfig.headers,
      ...headers,
    };
  };

  get = async (url: string) => {
    const res = await axios.get(url, this.requestConfig);
    return res;
  };

  getWithParams = async (
    url: string,
    params: AxiosRequestConfig['headers'],
  ) => {
    const res = await axios.get(url, {
      headers: this.requestConfig.headers,
      params,
    });

    return res;
  };

  post = async (url: string, data: unknown) => {
    const res = await axios.post(url, data, this.requestConfig);
    return res;
  };

  upload = async (url: string, file: FormData) => {
    const res = await axios.post(url, file, {
      ...this.requestConfig,
      headers: {
        ...this.requestConfig.headers,
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  };

  postWithParams = async (
    url: string,
    params: AxiosRequestConfig['headers'],
    data: unknown,
  ) => {
    const res = await axios.post(url, data, {
      headers: this.requestConfig.headers,
      params,
    });
    return res;
  };

  put = async (url: string, data: unknown) => {
    const res = await axios.put(url, data, this.requestConfig);
    return res;
  };

  putWithParams = async (
    url: string,
    data: unknown,
    params: AxiosRequestConfig['headers'],
  ) => {
    const res = await axios.put(url, data, {
      headers: this.requestConfig.headers,
      params,
    });
    return res;
  };

  patch = async (url: string, data: unknown) => {
    const res = await axios.patch(url, data, {
      headers: this.requestConfig.headers,
    });
    return res;
  };

  patchWithParams = async (
    url: string,
    data: unknown,
    params: AxiosRequestConfig['headers'],
  ) => {
    const res = await axios.patch(url, data, {
      headers: this.requestConfig.headers,
      params,
    });
    return res;
  };

  delete = async (url: string) => {
    const res = await axios.delete(url);
    return res;
  };

  deleteWithParms = async (
    url: string,
    params: AxiosRequestConfig['headers'],
  ) => {
    const res = await axios.delete(url, {
      headers: this.requestConfig.headers,
      params,
    });

    return res;
  };
}
