import React from 'react';
import { ThemeProvider as EmotionProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import { AppContextProvider } from './AppContextProvider';
import { DrawerProvider } from './DrawerProvider';
import { HeaderProvider } from './HeaderProvider';
import { useThemeContext } from '../theme';
import { BreadcrumbsProvider } from './BreadcrumbsProvider';
import { ProductsProvider } from '../../modules/products';
import { CustomerProvider } from '../../modules/customer';
import { QualificationsProvider } from '../../modules/products/context';
import {
  DocumentsProvider,
  PersonProvider,
} from '../../modules/customer/context';
import { ProviderCompose } from '../components';
import { AuthProvider, PermifyProvider } from 'modules/auth/context';
import { SimulatorProvider } from 'modules/simulation/context';
import { QualificationChangeProvider } from 'modules/products/context/QualificationChangeProvider';
import { InstallmentsCartProvider } from 'modules/charges/contexts/InstallmentsCartContext';
import { OriginatorProvider } from 'modules/common/OriginatorProvider';

export interface IAppProviderProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: IAppProviderProps) => {
  const { state } = useThemeContext();

  return (
    <EmotionProvider theme={state.theme.light}>
      <ProviderCompose
        contexts={[
          <ThemeProvider theme={state.theme.light} key="theme-context" />,
          <AppContextProvider key="app-context" />,
          <AuthProvider key="auth-context" />,
          <OriginatorProvider key="originator-context" />,
          <PermifyProvider key="permify-context" />,
          <BreadcrumbsProvider key="breadcrumb-context" />,
          <DrawerProvider key="drawer-context" />,
          <ProductsProvider key="products-context" />,
          <QualificationsProvider key="qualifications-context" />,
          <DocumentsProvider key="documents-context" />,
          <CustomerProvider key="customer-context" />,
          <PersonProvider key="person-context" />,
          <HeaderProvider key="header-context" />,
          <SimulatorProvider key="simulator-context" />,
          <QualificationChangeProvider key="qualifications-context" />,
          <InstallmentsCartProvider key="installments-cart-context" />,
        ]}
      >
        {children}
      </ProviderCompose>
    </EmotionProvider>
  );
};

export default AppProvider;
