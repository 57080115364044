export enum EProfile {
  FUNDING = 'FUNDING',
  ORIGINATOR = 'ORIGINATOR',
  EMPLOYER = 'EMPLOYER',
}

export enum EAccountPermissions {
  ACCESS = 'access',
  USER_ADMIN = 'user_admin',
  PORTAL_ADMIN = 'portal_admin',
  DISBURSEMENT_ADMIN = 'disbursement_admin',
  UPDATE_QUALIFICATION = 'update_qualification',
  CREATE_QUALIFICATION = 'create_qualification',
  UPDATE_APPLICATION_QUALIFICATION = 'update_application_qualification',
  CREATE_APPLICATION = 'create_application',
  CANCEL_APPLICATION = 'cancel_application',
  APPROVE_APPLICATION = 'approve_application',
  UPDATE_APPLICATION_QUALIFICATION_CONDITIONS = 'update_application_qualification_conditions',
  CREATE_PERSON_BUSINESS = 'create_person_business',
  UPDATE_PERSON_BUSINESS = 'update_person_business',
  READ_KYC = 'read_kyc',
  CREATE_KYC = 'create_kyc',
  READ_SCR = 'read_scr',
  CREATE_SCR = 'create_scr',
  CNAB = 'cnab',
  WITHDRAW = 'withdraw',
  GUEST = 'guest',
}

export type AccountPermissionProperties = {
  [EAccountPermissions.APPROVE_APPLICATION]: {
    amount: number;
  };
};

export enum EPermission {
  // Menu
  MENU_RECORDS = 'MENU_RECORDS',

  // Simulation
  SIMULATION_OPEN = 'SIMULATION_OPEN',

  // Natural Person
  NATURAL_PERSON_LIST = 'NATURAL_PERSON_LIST',
  NATURAL_PERSON_CREATE = 'NATURAL_PERSON_CREATE',
  NATURAL_PERSON_VIEW = 'NATURAL_PERSON_VIEW',
  NATURAL_PERSON_EDIT = 'NATURAL_PERSON_EDIT',

  // Legal Person
  LEGAL_PERSON_LIST = 'LEGAL_PERSON_LIST',
  LEGAL_PERSON_CREATE = 'LEGAL_PERSON_CREATE',
  LEGAL_PERSON_VIEW = 'LEGAL_PERSON_VIEW',
  LEGAL_PERSON_EDIT = 'LEGAL_PERSON_EDIT',

  // Products
  PRODUCTS_LIST = 'PRODUCTS_LIST',

  // Tab Applications
  PRODUCTS_APPLICATIONS = 'PRODUCTS_APPLICATIONS',
  PRODUCTS_APPLICATIONS_VIEW = 'PRODUCTS_APPLICATIONS_VIEW',
  PRODUCTS_APPLICATIONS_CREATE = 'PRODUCTS_APPLICATIONS_CREATE',
  PRODUCTS_APPLICATIONS_SIDESHEET = 'PRODUCTS_APPLICATIONS_SIDESHEET',
  PRODUCTS_APPLICATIONS_CHANGE_STATUS = 'PRODUCTS_APPLICATIONS_CHANGE_STATUS',

  // Tab Qualifications
  PRODUCTS_QUALIFICATIONS = 'PRODUCTS_QUALIFICATIONS',
  PRODUCTS_QUALIFICATIONS_SIDESHEET = 'PRODUCTS_QUALIFICATIONS_SIDESHEET',
  PRODUCTS_QUALIFICATIONS_CHANGE_STATUS = 'PRODUCTS_QUALIFICATIONS_CHANGE_STATUS',

  // Tab Borrowers
  PRODUCTS_PERSON_QUALIFICATIONS = 'PRODUCTS_PERSON_QUALIFICATIONS',
  PRODUCTS_PERSON_QUALIFICATIONS_SIDESHEET = 'PRODUCTS_PERSON_QUALIFICATIONS_SIDESHEET',
  PRODUCTS_PERSON_QUALIFICATIONS_CHANGE_STATUS = 'PRODUCTS_PERSON_QUALIFICATIONS_CHANGE_STATUS',
  PRODUCTS_PERSON_QUALIFICATIONS_DEFINE_CONDITIONS = 'PRODUCTS_PERSON_QUALIFICATIONS_DEFINE_CONDITIONS',
}

export const ProfilePermissions = {
  [EProfile.EMPLOYER]: [
    EPermission.PRODUCTS_LIST,
    EPermission.PRODUCTS_APPLICATIONS,
    EPermission.PRODUCTS_APPLICATIONS_VIEW,
    EPermission.PRODUCTS_APPLICATIONS_SIDESHEET,
    EPermission.PRODUCTS_APPLICATIONS_CHANGE_STATUS,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS_SIDESHEET,
    EPermission.MENU_RECORDS,
    EPermission.LEGAL_PERSON_LIST,
    EPermission.LEGAL_PERSON_VIEW,
    EPermission.NATURAL_PERSON_LIST,
    EPermission.NATURAL_PERSON_VIEW,
  ],
  [EProfile.FUNDING]: [
    EPermission.PRODUCTS_LIST,
    EPermission.PRODUCTS_APPLICATIONS,
    EPermission.PRODUCTS_APPLICATIONS_VIEW,
    EPermission.PRODUCTS_APPLICATIONS_SIDESHEET,
    EPermission.PRODUCTS_APPLICATIONS_CHANGE_STATUS,
    EPermission.PRODUCTS_QUALIFICATIONS,
    EPermission.PRODUCTS_QUALIFICATIONS_SIDESHEET,
    EPermission.PRODUCTS_QUALIFICATIONS_CHANGE_STATUS,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS_SIDESHEET,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS_DEFINE_CONDITIONS,
    EPermission.MENU_RECORDS,
    EPermission.LEGAL_PERSON_LIST,
    EPermission.LEGAL_PERSON_VIEW,
    EPermission.NATURAL_PERSON_LIST,
    EPermission.NATURAL_PERSON_VIEW,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS_CHANGE_STATUS,
  ],
  [EProfile.ORIGINATOR]: [
    EPermission.PRODUCTS_LIST,
    EPermission.PRODUCTS_APPLICATIONS,
    EPermission.PRODUCTS_APPLICATIONS_VIEW,
    EPermission.PRODUCTS_APPLICATIONS_CREATE,
    EPermission.PRODUCTS_APPLICATIONS_SIDESHEET,
    EPermission.PRODUCTS_APPLICATIONS_CHANGE_STATUS,
    EPermission.PRODUCTS_QUALIFICATIONS,
    EPermission.PRODUCTS_QUALIFICATIONS_SIDESHEET,
    EPermission.PRODUCTS_QUALIFICATIONS_CHANGE_STATUS,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS_SIDESHEET,
    EPermission.MENU_RECORDS,
    EPermission.LEGAL_PERSON_LIST,
    EPermission.LEGAL_PERSON_VIEW,
    EPermission.LEGAL_PERSON_CREATE,
    EPermission.LEGAL_PERSON_EDIT,
    EPermission.NATURAL_PERSON_LIST,
    EPermission.NATURAL_PERSON_VIEW,
    EPermission.NATURAL_PERSON_CREATE,
    EPermission.NATURAL_PERSON_EDIT,
    EPermission.SIMULATION_OPEN,
    EPermission.PRODUCTS_PERSON_QUALIFICATIONS_CHANGE_STATUS,
  ],
};

export interface IPermifyContext {
  isAuthorized(permission: EPermission): boolean;
  isProfileAuthorized(permission: EAccountPermissions): boolean;
}
