import ArrowForward from '@mui/icons-material/ArrowForward';
import { Card } from 'app/components';
import { Heading4 } from '../../../../../../app/components';
import {
  ProductCardHeader,
  ProductCardContent,
  ProductCardStyle,
  ProductCardFooter,
} from './styles';
import { TextM } from '../../../../../../app/components/typography/body';
import { TProducts } from '../../../../context';
import { formatCurrency } from 'app/utils/normalizer';

export interface IProductCardProps {
  product: TProducts;
  handleClick?: (id: string) => void;
}

const ProductCard = ({ product, handleClick }: IProductCardProps) => {
  return (
    <Card sx={ProductCardStyle} onClick={() => handleClick?.(product?.id)}>
      <ProductCardHeader>
        <Heading4 fontWeight="B" style={{ color: '#1E1360' }}>
          {product.name}
        </Heading4>
        <ArrowForward />
      </ProductCardHeader>
      <ProductCardContent>
        <TextM style={{ color: '#6F6B82' }}>{product?.description}</TextM>
      </ProductCardContent>
      <ProductCardFooter>
        {typeof product?.total_amount_applications === 'number' && (
          <TextM style={{ color: '#CDCCD1' }}>
            {`Valor total movimentado: ${formatCurrency(
              product?.total_amount_applications,
            )}`}
          </TextM>
        )}
        {typeof product?.total_applications === 'number' && (
          <TextM style={{ color: '#CDCCD1' }}>
            {`Número de operações: ${product?.total_applications}`}
          </TextM>
        )}
      </ProductCardFooter>
    </Card>
  );
};

export default ProductCard;
