const ErrorListCircularIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#FF0000" />
    <path
      d="M12.0005 10.9391L15.713 7.22656L16.7735 8.28706L13.061 11.9996L16.7735 15.7121L15.713 16.7726L12.0005 13.0601L8.28804 16.7726L7.22754 15.7121L10.94 11.9996L7.22754 8.28706L8.28804 7.22656L12.0005 10.9391Z"
      fill="#F1FAFF"
    />
  </svg>
);

export default ErrorListCircularIcon;
