import { useState } from 'react';
import { CnabHttpService } from './api/cnabHttpService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  TGenerateCnab444Request,
  TGenerateCnab444Response,
} from '../interfaces/cnab444';
import { IList } from 'modules/products/services/hooks/intefaces';

export const useCnabService = () => {
  const { showSnackbar } = useSnackbar();

  const [getCnab444Loading, setGetCnab444Loading] = useState(false);
  const [getCnab444FileLoading, setGetCnab444FileLoading] = useState(false);
  const [generateCnab444FileLoading, setGenerateCnab444FileLoading] =
    useState(false);
  const [getCnab444RequestsListLoading, setGetCnab444RequestsListLoading] =
    useState(false);

  const getCnab444 = async (
    fundingId: string,
    id: string,
    originator?: string,
  ): Promise<TGenerateCnab444Response | undefined> => {
    setGetCnab444Loading(true);
    try {
      const cnabService = new CnabHttpService();
      if (originator) {
        cnabService.setOriginatorId(originator);
      }
      cnabService.setHeader({
        funding: fundingId,
      });
      const res = await cnabService.getCnab444(id);
      return res;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar o CNAB 444', 'error');
    } finally {
      setGetCnab444Loading(false);
    }
  };

  const getCnab444File = async (
    fundingId: string,
    id: string,
    originator?: string,
  ): Promise<string | undefined> => {
    setGetCnab444FileLoading(true);
    try {
      const cnabService = new CnabHttpService();
      if (originator) {
        cnabService.setOriginatorId(originator);
      }
      cnabService.setHeader({
        funding: fundingId,
      });
      const res = await cnabService.getCnab444File(id);
      return res;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar o arquivo CNAB 444', 'error');
    } finally {
      setGetCnab444FileLoading(false);
    }
  };

  const getCnab444RequestsList = async (
    fundingId: string,
    page: number,
    size: number,
    originator?: string,
  ): Promise<IList<TGenerateCnab444Response> | undefined> => {
    setGetCnab444RequestsListLoading(true);
    try {
      const cnabService = new CnabHttpService();
      if (originator) {
        cnabService.setOriginatorId(originator);
      }
      cnabService.setHeader({
        funding: fundingId,
      });
      const res = await cnabService.getCnab444RequestsList(page, size);
      return res;
    } catch {
      showSnackbar(
        'Ocorreu um erro ao buscar a lista de CNABs 444 gerados',
        'error',
      );
    } finally {
      setGetCnab444RequestsListLoading(false);
    }
  };

  const generateCnab444File = async (
    fundingId: string,
    params: TGenerateCnab444Request,
    originator?: string,
  ): Promise<TGenerateCnab444Response | undefined> => {
    setGenerateCnab444FileLoading(true);
    try {
      const cnabService = new CnabHttpService();
      if (originator) {
        cnabService.setOriginatorId(originator);
      }
      cnabService.setHeader({
        funding: fundingId,
      });
      const res = await cnabService.generateCnab444File(params);
      return res;
    } catch {
      showSnackbar('Ocorreu um erro ao gerar o arquivo CNAB 444', 'error');
    } finally {
      setGenerateCnab444FileLoading(false);
    }
  };

  return {
    getCnab444,
    getCnab444Loading,
    getCnab444File,
    getCnab444FileLoading,
    getCnab444RequestsList,
    getCnab444RequestsListLoading,
    generateCnab444File,
    generateCnab444FileLoading,
  };
};
