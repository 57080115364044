import { useProductsContext } from '../../../../context';
import { ProductCard } from '../ProductCard';
import { useNavigate } from 'react-router-dom';
import { ProductCardListContainer } from './styles';

type ProductCardListProps = {
  searchText: string;
};
const ProductCardList = ({ searchText }: ProductCardListProps) => {
  const navigate = useNavigate();
  const {
    state: { content },
  } = useProductsContext();

  const handleProductCardClick = (id: string) => {
    navigate(`/products/detail/${id}/applications?page=0`);
  };

  return (
    <ProductCardListContainer>
      {content
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description?.toLowerCase()?.includes(searchText.toLowerCase())
          );
        })
        .map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            handleClick={handleProductCardClick}
          />
        ))}
    </ProductCardListContainer>
  );
};

export default ProductCardList;
