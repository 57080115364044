import { BaseHttpService } from 'app/services';
import {
  AccountPermissionProperties,
  EAccountPermissions,
} from 'modules/auth/context/permify/permify.interfaces';
import { UserValidPermissions } from 'modules/management/interfaces/usersAccess';

type PostUserAccess = {
  taxpayerId: string;
  email: string;
  fullName: string;
  phone: string;
  permissions: EAccountPermissions[];
  permission_properties: AccountPermissionProperties | null;
  authentication: {
    EMPLOYER: string;
    FUNDING: string;
    ISSUER: string;
    ORIGINATOR: string;
  };
};

type ExistsUserAccess = {
  taxpayerId: string;
  email?: string;
  authentication: {
    EMPLOYER: string;
    FUNDING: string;
    ISSUER: string;
    ORIGINATOR: string;
  };
};

const AccountValidPermissions = [
  EAccountPermissions.ACCESS,
  EAccountPermissions.CREATE_QUALIFICATION,
  EAccountPermissions.GUEST,
  ...UserValidPermissions,
];

export class AccessHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}`;

  postUserAccess = async (userAccess: PostUserAccess) => {
    const res = await this.post(`${this.BASE_URL}/accounts`, userAccess);
    return res;
  };

  existsUserAccess = async (user: ExistsUserAccess) => {
    const res = await this.post(`${this.BASE_URL}/accounts/exists`, user);
    return res;
  };

  activateUserAccess = async (user: { email: string }) => {
    const res = await this.post(`${this.BASE_URL}/accounts/activate`, user);
    return res;
  };

  deactivateUserAccess = async (user: { email: string }) => {
    const res = await this.post(`${this.BASE_URL}/accounts/deactivate`, user);
    return res;
  };

  updateUserAccess = async (
    userId: string,
    permissions: EAccountPermissions[],
    permissionProperties: AccountPermissionProperties | null,
  ) => {
    const newPermissions = ([] as EAccountPermissions[]).concat(permissions);

    if (!newPermissions.includes(EAccountPermissions.ACCESS))
      newPermissions.push(EAccountPermissions.ACCESS);

    const res = await this.post(
      `${this.BASE_URL}/accounts/${userId}/permissions`,
      {
        permissions: newPermissions.filter((permission) =>
          AccountValidPermissions.includes(permission),
        ),
        permission_properties: permissionProperties,
      },
    );
    return res;
  };

  getUserAccessList = async () => {
    const res = await this.get(`${this.BASE_URL}/accounts`);
    return res;
  };

  getUserAccess = async (userId: string) => {
    const res = await this.get(`${this.BASE_URL}/accounts/${userId}`);
    return res;
  };

  resetUserAccess = async (userId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/accounts/${userId}/reset-password`,
      {},
    );
    return res;
  };

  resendUserAccess = async (userId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/accounts/${userId}/resend-activation-link`,
      {},
    );
    return res;
  };
}
