const ListIcon = ({ color, ...props }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 4.25012H3V6.25012H21V4.25012ZM21 8.75012H3V10.7501H21V8.75012ZM21 17.7501H3V19.7501H21V17.7501ZM3 13.2501H21V15.2501H3V13.2501Z"
      fill={color ?? '#A1A5B7'}
    />
  </svg>
);
export default ListIcon;
