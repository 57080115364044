import { ButtonDefault, Heading4, Table, TextM, TextS } from '../';
import { TableDetailsWrapper } from './styles';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { TrashIcon } from 'app/components/Icons';
import { BilletsInfo } from 'modules/products/interfaces/billets';
import { formatCurrency } from 'app/utils/normalizer';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { getBilletNumber } from 'modules/products/helpers/billets';

const tableHeader = [
  '',
  'Nº do documento',
  'Nome do sacado',
  'CPF / CNPJ',
  'Vencimento',
  'Valor',
  'Ação',
];

type BillPaymentDetailsProps = {
  billets: BilletsInfo[];
  total: number;
  onRemoveBillet: (billet: BilletsInfo) => void;
};
const BillPaymentDetails = ({
  billets,
  total,
  onRemoveBillet,
}: BillPaymentDetailsProps) => {
  return (
    <>
      {billets.length > 0 && (
        <>
          <Heading4 style={{ marginTop: '32px' }}>Boletos consultados</Heading4>
          <TableDetailsWrapper>
            <Table
              tableHeadCell={tableHeader.map((header, index) => ({
                id: String(index),
                content: (
                  <TableHeadCellContainer>
                    <TextS
                      weight="Bold"
                      style={{ marginLeft: index >= 2 ? '12px' : '0px' }}
                    >
                      {header}
                    </TextS>
                  </TableHeadCellContainer>
                ),
              }))}
              tableBodyContent={billets.map((billet, inx) => {
                return {
                  id: '1',
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <TextS weight="Bold">{inx + 1}</TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <TextS
                            style={{
                              overflow: 'hidden',
                              display: 'inline-block',
                              whiteSpace: 'nowrap',
                              maxWidth: '150px',
                              marginTop: '5px',
                              textOverflow: 'ellipsis',
                            }}
                            weight="Regular"
                          >
                            {getBilletNumber(billet.request)}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <TextS
                            style={{ width: 'fit-content' }}
                            weight="Regular"
                          >
                            {billet.result.payer}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <TextS
                            style={{ width: 'fit-content' }}
                            weight="Regular"
                          >
                            {billet.result.document_payer}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: (
                        <TableBodyCellContainer
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <TextS
                            style={{ width: 'fit-content' }}
                            weight="Regular"
                          >
                            {billet.result.due_date
                              ? formatDateFromBackend(
                                  billet.result.due_date,
                                ).toLocaleDateString('pt-BR')
                              : '-'}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '6',
                      content: (
                        <TableBodyCellContainer
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <TextS
                            style={{ width: 'fit-content' }}
                            weight="Regular"
                          >
                            {formatCurrency(billet.result.amount)}
                          </TextS>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '7',
                      content: (
                        <TableBodyCellContainer>
                          <ButtonDefault
                            style={{
                              background: 'transparent',
                              border: 'none',
                              padding: '5px',
                            }}
                            onClick={() => onRemoveBillet(billet)}
                          >
                            <TrashIcon />
                          </ButtonDefault>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                };
              })}
              tableFooterCell={[
                {
                  id: '1',
                  content: null,
                },
                {
                  id: '2',
                  content: null,
                },
                {
                  id: '3',
                  content: null,
                },
                {
                  id: '4',
                  content: null,
                },
                {
                  id: '5',
                  content: null,
                },
                {
                  id: '6',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>{`Valor Total:`}</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">{formatCurrency(total)}</TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '7',
                  content: null,
                },
              ]}
            />
          </TableDetailsWrapper>
        </>
      )}
    </>
  );
};

export default BillPaymentDetails;
