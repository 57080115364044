import styled from '@emotion/styled';

export const QRCodeInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  .MuiFormLabel-root + div > div:first-of-type {
    background-color: ${({ theme }) => theme.palette?.brand?.primary?.base};
  }
`;

export const StepContent = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 32px;
  margin-top: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 15px;
  padding: 24px;
`;

export const QRCodeInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QRCodeDetailsContainer = styled.div<{
  simulator?: boolean;
  review?: boolean;
}>`
  border: ${({ simulator }) => (simulator ? 'none' : '1px solid #E6E5EA')};
  border-radius: 15px;
  padding:  ${({ simulator }) => (simulator ? '0px' : '12px 1rem 16px')};
  margin-top: ${({ review }) => (review ? '0px' : '32px')};
  box-shadow: ${({ simulator }) =>
    simulator
      ? 'unset'
      : '0px 0px 4px -6px rgba(0, 0, 0, 0.08), 0px 16px 24px -4px rgba(0, 0, 0, 0.04)'};
`;

export const QRCodeDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 1rem 0 1rem;
`;

export const QRCodeDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const QRCodeDetailsItems = styled.div`
  display: flex;
  > div {
    width: 50%;
  }
`;

export const QRCodeDetailsItem = styled.div`
  display: flex;
  margin-right: auto;
`;

export const QRCodeDetailsItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0;
  padding-right: 1rem;
  padding-left: 0.2rem;
`;

export const QRCodeDetailsItemDescription = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: block;
  }

  span:first-of-type {
    font-size: 12px;
    color: #6F6B82;
    font-weight: 500;
  }

  span:nth-of-type(2) {
    font-size: 16px;
    color: #302D3F;
    font-weight: 600;
    word-break: break-all;
  }
`;
