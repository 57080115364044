import {
  simulatorActionTypes,
  useSimulatorContext,
} from 'modules/simulation/context';
import { useState } from 'react';
import { TSimulatorParametersData } from '../components/CreditSimulator/CreditSimulatorModal/CreditSimulatorContent';
import { SimulatorHttpService } from '../services';
import { formatToCurrencyFloat } from '../utils/normalizer';
import { useSnackbar } from './useSnackbar';
import { formatInterestRate } from 'modules/products/helpers/interestRate';
import { format } from 'date-fns';
import { TacValueType } from 'modules/products/pages/ApplicationRequest/ApplicationRequest';

const useCreditSimulator = () => {
  const [simulationLoading, setSimulationLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { dispatch } = useSimulatorContext();
  const simulate = async (
    simulatorParametersData: TSimulatorParametersData,
    index: string,
    productId?: string,
  ) => {
    const hasAdditionalFee =
      simulatorParametersData.hasAdditionalFee.value === 'true';

    try {
      setSimulationLoading(true);
      const simulatorParametersDataParsed = {
        amount: Number(formatToCurrencyFloat(simulatorParametersData.amount)),
        numPayments: parseInt(simulatorParametersData.numPayments),
        ...(simulatorParametersData.tacType.value === TacValueType.MONETARY
          ? {
              tacAmount: Number(
                formatToCurrencyFloat(simulatorParametersData.tacAmount),
              ),
              tacRate: undefined,
            }
          : {
              tacAmount: undefined,
              tacRate:
                formatInterestRate(simulatorParametersData.tacRate) ??
                undefined,
            }),
        discountRate:
          formatInterestRate(simulatorParametersData.discountRate) ?? 0,
        interestRate:
          formatInterestRate(simulatorParametersData.interestRate) ?? 0,
        financeFee: Number(
          formatToCurrencyFloat(simulatorParametersData.financeFee),
        ),
        firstPaymentDate: format(
          new Date(simulatorParametersData.firstPaymentDate),
          'yyyy-MM-dd',
        ),
        disbursementDate: format(
          new Date(simulatorParametersData.disbursementDate),
          'yyyy-MM-dd',
        ),
        additionalInstallmentFee:
          simulatorParametersData.additionalInstallmentFee && hasAdditionalFee
            ? Number(
                formatToCurrencyFloat(
                  simulatorParametersData.additionalInstallmentFee,
                ),
              )
            : null,
        additionalInstallmentDescription: hasAdditionalFee
          ? simulatorParametersData.additionalInstallmentDescription
          : null,
      };

      dispatch({
        type: simulatorActionTypes.SET_SIMULATOR_DATA,
        payload: {
          ...simulatorParametersData,
          ...simulatorParametersDataParsed,
          amountKey: index,
        },
      });

      const simulatorHttpService = new SimulatorHttpService();

      const res =
        typeof productId === 'string'
          ? await simulatorHttpService.getRequestedAmountByProduct(
              { ...simulatorParametersData, ...simulatorParametersDataParsed },
              index,
              productId,
            )
          : await simulatorHttpService.getRequestedAmount(
              { ...simulatorParametersData, ...simulatorParametersDataParsed },
              index,
            );

      dispatch({
        type: simulatorActionTypes.SET_APPLICATION_PREVIEW,
        payload: res.data,
      });

      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro. Tente novamente.');
      setSimulationLoading(false);
    } finally {
      setSimulationLoading(false);
    }
  };

  return { simulate, simulationLoading };
};

export default useCreditSimulator;
