import React from 'react';
import { Drawer, DrawerProps } from '@mui/material';

export interface ISideSheet extends DrawerProps {
  handleClose: () => void;
}

const SideSheet = ({ open, handleClose, children, ...props }: ISideSheet) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      {...props}
      className="drawer-zoom"
    >
      {children}
    </Drawer>
  );
};

export default SideSheet;
