import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette?.brand.gray[100]};
  padding: 0px 40px;
  
    
  @media screen and (max-width: 1920px) {
    zoom: 0.9;
  }

  @media screen and (max-width: 1800px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1706px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1537px) {
    zoom: 0.75;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      padding: 0px 6px;
      min-height: 100px;
      max-height: 100px;
  }
`;

export const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 6px;
  cursor: pointer;
`;
