const QRCodeBusinessIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5006 15.8333H19.1673V17.5H0.833984V15.8333H2.50065V3.33333C2.50065 3.11232 2.58845 2.90036 2.74473 2.74408C2.90101 2.5878 3.11297 2.5 3.33398 2.5H11.6673C11.8883 2.5 12.1003 2.5878 12.2566 2.74408C12.4129 2.90036 12.5007 3.11232 12.5007 3.33333V15.8333H15.834V9.16667H14.1673V7.5H16.6673C16.8883 7.5 17.1003 7.5878 17.2566 7.74408C17.4129 7.90036 17.5006 8.11232 17.5006 8.33333V15.8333ZM4.16732 4.16667V15.8333H10.834V4.16667H4.16732ZM5.83398 9.16667H9.16732V10.8333H5.83398V9.16667ZM5.83398 5.83333H9.16732V7.5H5.83398V5.83333Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeBusinessIcon;
