import { useTheme } from '@mui/material';
import { ButtonPrimary, Heading3, TextM } from 'app/components';
import { ArrowIcon, HyperlinkIcon } from 'app/components/Icons';
import { formatCNPJ, formatCPF, formatPhone } from 'app/helpers';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import { TProducts } from 'modules/products/context';
import { TBorrowerQualificationRequestType } from 'modules/products/services/hooks/intefaces';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApplicationDetailsHeader,
  ViewBoxContainer,
  ViewBoxContent,
  ViewBoxItem,
} from '../../styles';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

export interface IConditionContent {
  borrowerPerson: TRegisterPersonDataRequest | undefined;
  borrowerBusiness: TRegisterBusinessDataRequest | undefined;
  product?: TProducts;
  originator?: string;
  borrowerQualificationData: TBorrowerQualificationRequestType | undefined;
}

const buttonStyle = {
  padding: '11px 15px',
  width: 'fit-content',
  gap: '6px',
};

const ConditionContent = ({
  borrowerBusiness,
  borrowerPerson,
  product,
  originator,
  borrowerQualificationData,
}: IConditionContent) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbarCopyLink } = useSnackbar();
  const { setSelectedOriginator } = useOriginatorContext();

  const isPerson = useCallback(() => {
    return product?.borrower_type === 'PERSON';
  }, []);

  const handleClipboard = useCallback(() => {
    isPerson()
      ? navigator.clipboard.writeText(
          `${document.location.origin}/#/records/natural/${
            borrowerQualificationData?.borrower.id || ''
          }/details`,
        )
      : navigator.clipboard.writeText(
          `${document.location.origin}/#/records/legal/${
            borrowerQualificationData?.borrower?.id || ''
          }/details`,
        );
    showSnackbarCopyLink();
  }, []);

  const handleNavigate = () => {
    setSelectedOriginator(originator as string);
    setTimeout(() =>
      isPerson()
        ? navigate(
            `/records/natural/${
              borrowerQualificationData?.borrower?.id || ''
            }/details`,
          )
        : navigate(
            `/records/legal/${
              borrowerQualificationData?.borrower.id || ''
            }/details`,
          ),
    );
  };

  return (
    <>
      <ApplicationDetailsHeader>
        <Heading3>Cadastro</Heading3>
        <div style={{ display: 'flex', gap: '6px' }}>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleClipboard}
          >
            <HyperlinkIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
          <ButtonPrimary
            typeVariant="outline"
            style={buttonStyle}
            onClick={handleNavigate}
          >
            <TextM
              weight="Semibold"
              style={{
                color: theme.palette.brand.primary.base,
                marginRight: '8px',
              }}
            >
              Ver página
            </TextM>
            <ArrowIcon color={theme.palette.brand.primary.base as string} />
          </ButtonPrimary>
        </div>
      </ApplicationDetailsHeader>
      <ViewBoxContainer>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Nome completo</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? borrowerPerson?.full_name
                  : borrowerBusiness?.legal_name}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">{isPerson() ? 'CPF' : 'CNPJ'}</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? formatCPF(borrowerPerson?.taxpayer_id || '')
                  : formatCNPJ(borrowerBusiness?.taxpayer_id || '')}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">
                {isPerson() ? 'Data de nascimento' : 'Data de constituição'}
              </TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? formatDateFromBackend(
                      borrowerPerson?.birth_date || '',
                    ).toLocaleDateString('pt-br')
                  : formatDateFromBackend(
                      borrowerBusiness?.foundation_date || '',
                    ).toLocaleDateString('pt-br')}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Email</TextM>
            </span>
            <span>
              <TextM style={{ wordWrap: 'break-word' }}>
                {isPerson()
                  ? borrowerPerson?.email_address
                  : borrowerBusiness?.email_address}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Celular</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? formatPhone(
                      (borrowerPerson?.phone.area_code || '') +
                        (borrowerPerson?.phone.number || ''),
                    )
                  : formatPhone(
                      (borrowerBusiness?.phone.area_code || '') +
                        (borrowerBusiness?.phone.number || ''),
                    )}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
        <ViewBoxContent>
          <ViewBoxItem>
            <span>
              <TextM weight="Semibold">Cadastrado em</TextM>
            </span>
            <span>
              <TextM>
                {isPerson()
                  ? formatDateFromBackend(
                      borrowerPerson?.created_at || '',
                    ).toLocaleDateString('pt-br')
                  : formatDateFromBackend(
                      borrowerBusiness?.created_at || '',
                    ).toLocaleDateString('pt-br')}
              </TextM>
            </span>
          </ViewBoxItem>
        </ViewBoxContent>
      </ViewBoxContainer>
    </>
  );
};

export default ConditionContent;
