import { useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  ValidateEmv,
  ValidateEmvError,
  ValidateEmvResponse,
} from 'modules/products/interfaces/qrCode';
import { QrCodeHttpService } from '../api/qrCodeHttpService';
import { AxiosError } from 'axios';

const useQRCodeService = () => {
  const [validateEmvLoading, setValidateEmvLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const validateEmv = async (
    emv: ValidateEmv,
  ): Promise<ValidateEmvResponse | ValidateEmvError | undefined> => {
    setValidateEmvLoading(true);
    try {
      const validateService = new QrCodeHttpService();
      validateService.setHeader({
        handled: true,
      });
      const res = await validateService.validateEmv(emv);
      return res;
    } catch (error: unknown) {
      if (error && 'response' in (error as AxiosError)) {
        return (error as AxiosError).response?.data as ValidateEmvError;
      }
      showSnackbar('Ocorreu um erro ao buscar os dados do QR Code', 'error');
    } finally {
      setValidateEmvLoading(false);
    }
  };

  return {
    validateEmvLoading,
    validateEmv,
  };
};

export default useQRCodeService;
