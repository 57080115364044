import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 40px;
  position: relative;
`;

export const FilterArea = styled.div`
  display: flex;
  width: 100%;
  margin-right: 11px;
  margin-bottom: 32px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  box-shadow: 0px 4px 10px 0px rgba(15, 33, 43, 0.05);
  border-radius: 10px;
  padding: 0px 15px;
  height: 50px;
  margin-right: 12px;
  position: relative;
  border: 1px solid #E6E5EA;

  & > svg {
    position: absolute;
    left: 16px;
    top: 12px;
  }

  input {
    padding-left: 35px;
    font-size: 16px;
    font-weight: 500;
    height: 48px !important;

    &::placeholder {
      font-size: 16px;
    }
  }
`;
