import {
  createContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import { useBreadcrumbsContext } from '../../../../app/context';
import {
  LegalPersonHandlerContainer,
  LegalPersonHandlerStepContainer,
  LegalPersonHandlerStepperContainer,
} from './styles';
import { Stepper } from 'app/components';
import {
  AboutStep,
  BankStep,
  AddressStep,
  BusinessRelations,
  DocumentsStep,
  ProductStep,
  VariablesStep,
  FundingStep,
} from './steps';
import { IBusinessCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import { TRegisterBusinessDataRequest } from 'modules/customer/context';
import { useCustomer } from 'modules/customer/hooks';
import { EFunctions } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from 'modules/auth/context';
import BillingAddressStep from './steps/BillingAddressStep';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import { useAppContext } from 'app/context/AppContextProvider';
import OriginatorStep from './steps/OriginatorStep';

type ILegalPersonHandlerStepperContext = {
  activeStep: number;
  onForward: () => void;
  onBack: () => void;
  onBackTwoSteps: () => void;
  hasCustomVariables?: boolean;
  updateHasCustomVariables: (hasCustomVariables: boolean) => void;
  createdBusiness: TRegisterBusinessDataRequest | null;
  handleCreatedBusiness: (
    business: TRegisterBusinessDataRequest | null,
  ) => void;
  customVariables: IBusinessCustomVariablesValues[] | null;
  updateCustomVariables: (variables: IBusinessCustomVariablesValues[]) => void;
  selectedRelationPersonList: RelationPerson[] | undefined;
  updatePersonList: (addedPerson?: RelationPerson) => void;
  removePersonFromList: (addedPerson?: RelationPerson) => void;
  handleSelectedRelationPersonListOrder: () => void;
  funding: string | null;
  hasMultiplesFundings: boolean;
  updateFunding: (funding: string) => void;
  utilizeCurrentAddressAsBilling: boolean;
  updateUtilizeCurrentAddressAsBilling: (utilize: boolean) => void;
  originator: string | null;
  updateOriginator: (originator: string | null) => void;
};

export type StepItem = {
  step: number;
  title: string;
  subtitle?: string;
  handleClick?: () => void;
  clickable?: boolean;
  element?: JSX.Element;
};

export const LegalPersonHandlerStepperContext =
  createContext<ILegalPersonHandlerStepperContext>({
    activeStep: 1,
    createdBusiness: null,
    hasCustomVariables: false,
    updateHasCustomVariables: () => {
      throw new Error('Método não implementado');
    },
    customVariables: null,
    selectedRelationPersonList: undefined,
    updateCustomVariables: () => {
      throw new Error('Método não implementado');
    },
    onForward: () => {
      throw new Error('Método não implementado');
    },
    onBack: () => {
      throw new Error('Método não implementado');
    },
    onBackTwoSteps: () => {
      throw new Error('Método não implementado');
    },
    handleCreatedBusiness: () => {
      throw new Error('Método não implementado');
    },
    updatePersonList: () => {
      throw new Error('Método não implementado');
    },
    removePersonFromList: () => {
      throw new Error('Método não implementado');
    },
    handleSelectedRelationPersonListOrder: () => {
      throw new Error('Método não implementado');
    },
    funding: null,
    hasMultiplesFundings: false,
    updateFunding: () => {
      throw new Error('Método não implementado');
    },
    utilizeCurrentAddressAsBilling: false,
    updateUtilizeCurrentAddressAsBilling: () => {
      throw new Error('Método não implementado');
    },
    originator: null,
    updateOriginator: () => {
      throw new Error('Método não implementado');
    },
  });

const LegalPersonHandler = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [activeStep, updateActiveStep] = useState<number>(0);
  const [createdBusiness, setCreatedBusiness] =
    useState<TRegisterBusinessDataRequest | null>(null);
  const [hasCustomVariables, updateHasCustomVariables] = useState(false);
  const { selectedOriginator, setSelectedOriginator } = useOriginatorContext();
  const [customVariables, updateCustomVariables] = useState<
    IBusinessCustomVariablesValues[] | null
  >(null);
  const [selectedRelationPersonList, setSelectedRelationPersonList] =
    useState<RelationPerson[]>();
  const { setProductData, setFunctionType } = useCustomer();
  const { state } = useLocation();
  const [selectedFunding, updateSelectedFunding] = useState<string | null>(
    null,
  );
  const [stepsInfo, updateStepsInfo] = useState<StepItem[]>([]);
  const [
    utilizeCurrentAddressAsBillingState,
    updateUtilizeCurrentAddressAsBillingState,
  ] = useState(true);
  const { is_issuer_app } = useAppContext();
  const { userInfo } = useAuthContext();

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  useEffect(() => {
    setItems(
      [
        { to: '/records/legal', label: 'Cadastros' },
        { to: '/records/legal', label: 'Empresas' },
      ],
      <AccountBalanceOutlined />,
    );

    return () => resetBreadcrumbs();
  }, []);

  useEffect(() => {
    if (state?.applicationRequestFlow) {
      setTimeout(() => {
        setFunctionType({ value: EFunctions.BORROWER, label: 'Tomador' });
        setProductData(state.product);
      });
    }
    return () => {
      history.replaceState({}, document.title);
    };
  }, [state]);

  const onForward = useCallback(() => {
    if (activeStep < steps.length) {
      updateActiveStep(activeStep + 1);
    }
  }, [activeStep]);

  const onBack = useCallback(() => {
    if (activeStep > 0) {
      updateActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  const onBackTwoSteps = useCallback(() => {
    if (activeStep > 1) {
      updateActiveStep(activeStep - 2);
    }
  }, [activeStep]);

  const handleCreatedBusiness = (
    business: TRegisterBusinessDataRequest | null,
  ) => {
    setCreatedBusiness(business);
  };

  const updatePersonList = (addedPerson?: RelationPerson) => {
    const newPersonList = [
      ...(selectedRelationPersonList?.filter(
        (el) => el.id != addedPerson?.id,
      ) || []),
    ];

    if (addedPerson) {
      newPersonList.push(addedPerson);
    }

    setSelectedRelationPersonList(newPersonList);
  };

  const removePersonFromList = (addedPerson?: RelationPerson) => {
    const newPersonList = [
      ...(selectedRelationPersonList?.filter(
        (el) => el.id != addedPerson?.id,
      ) || []),
    ];

    setSelectedRelationPersonList(newPersonList);
  };

  const handleSelectedRelationPersonListOrder = () => {
    setSelectedRelationPersonList(selectedRelationPersonList?.reverse());
  };

  const steps = useMemo(
    () => [
      {
        step: 0,
        title: 'Originador',
        clickable: false,
        element: <OriginatorStep />,
      },
      {
        step: 1,
        title: 'Funding',
        clickable: false,
        element: <FundingStep />,
      },
      {
        step: 2,
        title: 'Produto e Função',
        clickable: false,
        element: <ProductStep />,
      },
      {
        step: 3,
        title: 'Sobre',
        clickable: false,
        element: <AboutStep />,
      },
      {
        step: 4,
        title: 'Endereço',
        clickable: false,
        element: <AddressStep />,
      },
      {
        step: 5,
        title: 'Endereço de Cobrança',
        clickable: false,
        element: <BillingAddressStep />,
      },
      {
        step: 6,
        title: 'Dados Bancários',
        clickable: false,
        element: <BankStep />,
      },
      {
        step: 7,
        title: 'Campos adicionais',
        clickable: false,
        element: <VariablesStep />,
      },
      {
        step: 8,
        title: 'Representantes legais',
        clickable: false,
        element: <BusinessRelations />,
      },
      {
        step: 9,
        title: 'Documentos',
        clickable: false,
        element: <DocumentsStep />,
      },
    ],
    [hasMultiplesFundings],
  );

  useEffect(() => {
    if (!hasMultiplesFundings && userInfo) {
      updateSelectedFunding(userInfo.fundings[0]);
    }
  }, [hasMultiplesFundings]);

  useEffect(() => {
    let updatedSteps = [...steps];
    if (!hasMultiplesFundings) {
      updatedSteps = updatedSteps.filter((step) => step.step !== 1);
    }

    if (utilizeCurrentAddressAsBillingState) {
      updatedSteps = updatedSteps.filter((step) => step.step !== 5);
    }

    if (!is_issuer_app) {
      updatedSteps = updatedSteps.filter((item) => item.title !== 'Originador');
    }

    updateStepsInfo(updatedSteps);
  }, [
    hasMultiplesFundings,
    utilizeCurrentAddressAsBillingState,
    is_issuer_app,
  ]);

  const renderSteps = () => {
    const currentStep = stepsInfo[activeStep];
    return currentStep?.element || null;
  };

  return (
    <LegalPersonHandlerStepperContext.Provider
      value={{
        activeStep,
        createdBusiness,
        hasCustomVariables,
        updateHasCustomVariables,
        customVariables,
        selectedRelationPersonList,
        updateCustomVariables,
        onForward,
        onBack,
        onBackTwoSteps,
        handleCreatedBusiness,
        updatePersonList,
        removePersonFromList,
        handleSelectedRelationPersonListOrder,
        funding: selectedFunding,
        hasMultiplesFundings: !!hasMultiplesFundings,
        updateFunding: updateSelectedFunding,
        utilizeCurrentAddressAsBilling: utilizeCurrentAddressAsBillingState,
        updateUtilizeCurrentAddressAsBilling:
          updateUtilizeCurrentAddressAsBillingState,
        originator: selectedOriginator,
        updateOriginator: setSelectedOriginator,
      }}
    >
      <LegalPersonHandlerContainer>
        <LegalPersonHandlerStepperContainer>
          <Stepper activeStep={activeStep} stepsItems={stepsInfo} />
        </LegalPersonHandlerStepperContainer>
        <LegalPersonHandlerStepContainer>
          {renderSteps()}
        </LegalPersonHandlerStepContainer>
      </LegalPersonHandlerContainer>
    </LegalPersonHandlerStepperContext.Provider>
  );
};

export default LegalPersonHandler;
