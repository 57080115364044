import { useState } from 'react';
import {
  productsActionType,
  useProductsContext,
} from '../../context/ProductsProvider';
import { ProductsHttpService } from '../api';
import { ICustomVariablesRequest, IList } from './intefaces';
import {
  TProductQualificationRequest,
  TProducts,
} from 'modules/products/context/ProductsProvider/products.interfaces';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useAppContext } from 'app/context/AppContextProvider';

const useProductsService = () => {
  const { dispatch } = useProductsContext();
  const { showSnackbar } = useSnackbar();
  const { is_issuer_app } = useAppContext();
  const [getProductsLoading, setGetProductsLoading] = useState(false);
  const [getProductsListLoading, setGetProductsListLoading] = useState(false);
  const [getProductLoading, setGetProductLoading] = useState(false);
  const [getProductQualificationsLoading, setGetProductQualificationsLoading] =
    useState(false);
  const [getProductVariablesCsvLoading, setGetProductVariablesCsvLoading] =
    useState(false);
  const [customVariablesLoading, setCustomVariablesLoading] = useState(false);
  const [
    createQualificationRequestLoading,
    setCreateQualificationRequestLoading,
  ] = useState(false);

  const getProducts = async (skipIssuerRequest?: boolean) => {
    setGetProductsLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.getProducts(
        is_issuer_app && !skipIssuerRequest,
      );
      dispatch({
        type: productsActionType.SET_PRODUCTS,
        payload: result.data,
      });
    } catch (error) {
      throw Error();
    } finally {
      setGetProductsLoading(false);
    }
  };

  const getProductsList = async (
    skipIssuerRequest?: boolean,
  ): Promise<IList<TProducts>> => {
    setGetProductsListLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.getProducts(
        is_issuer_app && !skipIssuerRequest,
      );
      return result.data;
    } catch (error) {
      throw Error();
    } finally {
      setGetProductsListLoading(false);
    }
  };

  const getProduct = async (
    productId: string,
    hideError?: boolean,
    skipIssuerRequest?: boolean,
  ) => {
    setGetProductLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.getProduct(
        productId,
        is_issuer_app && !skipIssuerRequest,
      );
      return result;
    } catch (error) {
      if (!hideError) showSnackbar('Ocorreu um erro ao buscar o produto');
    } finally {
      setGetProductLoading(false);
    }
  };

  const getProductQualifications = async (productId: string) => {
    setGetProductQualificationsLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.getProductQualifications(productId);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setGetProductQualificationsLoading(false);
    }
  };

  const getProductVariablesCsv = async (productId: string) => {
    setGetProductVariablesCsvLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.getProductVariablesCsv(productId);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setGetProductVariablesCsvLoading(false);
    }
  };

  const getCustomVariables = async (
    productId: string,
  ): Promise<ICustomVariablesRequest | undefined> => {
    setCustomVariablesLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.getCustomVariables(productId);
      return result.data;
    } catch (error) {
      console.log(error);
    } finally {
      setCustomVariablesLoading(false);
    }
  };

  const createQualificationRequest = async (
    productId: string,
    qualificationRequest: TProductQualificationRequest,
  ): Promise<TQualification | false> => {
    setCreateQualificationRequestLoading(true);
    try {
      const productsService = new ProductsHttpService();
      const result = await productsService.postConditionsRequests(
        productId,
        qualificationRequest,
      );
      return result.data;
    } catch (error) {
      console.log(error);
    } finally {
      setCreateQualificationRequestLoading(false);
    }
    return false;
  };

  return {
    getProductsLoading,
    getProductsListLoading,
    getProductLoading,
    getProductQualificationsLoading,
    getProductVariablesCsvLoading,
    customVariablesLoading,
    createQualificationRequestLoading,
    getProductsList,
    getProducts,
    getProduct,
    getProductQualifications,
    getProductVariablesCsv,
    getCustomVariables,
    createQualificationRequest,
  };
};

export default useProductsService;
