import styled from '@emotion/styled';

export const AuthContainerWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center ;
  align-items: center;
  padding: 0px 20px;
    
  @media screen and (max-width: 1920px) {
    zoom: 0.9;
  }

  @media screen and (max-width: 1800px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1706px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1537px) {
    zoom: 0.75;
  }
`;
