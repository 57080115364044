import styled from '@emotion/styled';

export const ErrorListArea = styled.div`
  border-left: 8px solid #fcb9b9;
  background: #FFEAEA;
  position: relative;
  box-shadow: 0px 1px 0px rgba(253, 217, 184, 0.48);
  border-radius: 10px;
  width: 100%;
  padding: 14px 0px;
  margin-top: 32px;
  display: flex;
`;

export const ErrorList = styled.div``;

export const ErrorListAreaIcon = styled.span`
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorListItemsList = styled.ul`
  font-size: 14px;
  margin-top: 10px;
  list-style-type: none;
  color: #220B64;
`;

export const ErrorListItemsListItem = styled.li`
  line-height: 18px;
  color: #220B64;
`;
