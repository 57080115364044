import styled from '@emotion/styled';
import { InputLabel } from '@mui/material';

export const CalendarInputContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;

  .MuiFormControl-root {
    width: 100% !important;

    button {
      svg {
        color: ${({ theme, disabled }) =>
          disabled
            ? 'rgba(0, 0, 0, 0.38)'
            : theme.palette?.brand?.primary.base};
      }
    }
  }

  .MuiInputBase-root {
    width: 100% !important;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};

    
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid;
      border-color: ${({ theme }) =>
        theme.palette?.brand?.gray[300]} !important;
    }
    
    fieldset {
      border: 2px solid;
      border-color: ${({ theme }) =>
        theme.palette?.brand?.gray[300]} !important;
      border-radius: 10px;
    }
  }

  .MuiInputBase-root:hover {
    fieldset {
      border-color: ${({ theme }) =>
        theme.palette?.brand?.gray[300]} !important;
    }
  }

  .MuiInputBase-root:focus-within {
    fieldset {
      border-color: ${({ theme }) =>
        theme.palette?.brand?.primary.base} !important;
    }
  }
`;

export const InputLabelContainer = styled(InputLabel)`
  color: ${({ theme }) => theme.palette?.brand?.secondary.base};
  font-weight: 600;
  font-size: 14px;
  font-family: 'Inter';
`;

export const TextFieldArea = styled.div`
  margin-top: 10px;
  position: relative;

  input {
    &::placeholder{
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
      color: #C3C1CA;
    }
  }

  .custom-select-content {
    display: flex;
    justify-content: space-between;
  }
`;

export const LineItem = styled.div<{ disabled?: boolean }>`
  position: absolute;
  /* background-color: ${({ theme }) =>
    theme.palette?.brand?.neutral?.light2}; */
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette?.brand?.neutral?.light2
      : theme.palette?.brand.primary.base};
  height: 40%;
  width: 4px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  left: 2px;
`;

export const InputContent = styled.div`
  height: 55.99px;
  width: 100%;
  color: ${({ theme }) => theme.palette?.brand?.primary.base};
  border: 2px solid;
  border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .input-area {
    width: 90%;
  }

  .input-icon {
    margin: 0px 6px;
    margin-right: 20px;
  }


  &:focus{
    border: 2px solid;
    border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
  }
    
`;
