import { useTheme } from '@mui/material';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { formatToCurrencyFloat } from 'app/utils/normalizer';
import { usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import {
  CustomerHttpService,
  PersonHttpService,
} from 'modules/customer/services';
import {
  TBorrowerQualificationResponseParsed,
  TConditionItems,
  TQualificationStatusOption,
} from 'modules/products/pages/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomTableSelect,
  ButtonPrimary,
  Spinner,
  ButtonDanger,
  ConfirmModal,
} from '../../../../../../app/components';
import {
  TextS,
  TextXs,
} from '../../../../../../app/components/typography/body';
import { TextL } from '../../../../../../app/components/typography/body/TextL';
import {
  formatCNPJ,
  formatCPF,
  roleTypesTranslationParse,
} from '../../../../../../app/helpers';
import {
  formatDateFromBackend,
  formatStringHourFromBackend,
} from '../../../../../../app/helpers/dateHelpers';
import { TProducts, useQualificationsContext } from '../../../../context';
import { EQualificationStatus } from '../../../../context/QualificationsProvider/qualifications.interfaces';
import {
  useProductsService,
  useQualificationsService,
} from '../../../../services/hooks';
import { ConditionsModal } from '../ConditionsModal';
import { EditConditionSidesheet } from '../EditConditionSidesheet';
import { EmptyContent } from '../EmptyContent';
import { PersonDetailsSideSheet } from '../PersonDetailsSideSheet';
import { ButtonContainer, Container, TableArea, TableWrapper } from './styles';
import { Table, TextM } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';
import { AddIcon, DetailsIcon } from 'app/components/Icons';
import {
  useUploadBusinessFiles,
  useUploadPersonFiles,
} from 'modules/customer/hooks';
import { ICustomVariablesRequest } from 'modules/products/services/hooks/intefaces';
import get from 'lodash.get';
import {
  ConditionsRequiredProperty,
  TQualificationWithStatusAndProduct,
} from 'modules/customer/interfaces/qualifications';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { PendenciesModal } from 'modules/customer/components/PendenciesModal';
import { routingPath } from 'app/routes';
import { AttentionCircularRedIcon } from 'app/components/Icons/AttentionCircularIcon';
import { formatInterestRate } from 'modules/products/helpers/interestRate';
import { useAppContext } from 'app/context/AppContextProvider';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

type THandleApproveCheck = {
  handleApproveParams: TConditionItems;
  fieldsToWarn: string[];
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        {' '}
        <TextL weight="bold">Pessoa</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Função</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Empresa</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Criado em</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer style={{ textAlign: 'center' }}>
        <TextL weight="bold">Situação de preenchimento</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer style={{ textAlign: 'center', width: '100%' }}>
        <TextL weight="bold">Status</TextL>{' '}
      </TableHeadCellContainer>
    ),
  },
  { id: '7', content: <div /> },
];

const PersonQualificationContent = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { borrowerStatusOptions },
  } = useQualificationsContext();
  const { showSnackbar } = useSnackbar();
  const { getProduct, getCustomVariables } = useProductsService();
  const {
    getProductQualificationsPerson,
    getQualificationStatusOptions,
    updateQualificationStatus,
    updateLimitsCondition,
    getProductQualificationsLoading,
    updateQualificationStatusLoading,
    updateLimitsConditionLoading,
  } = useQualificationsService();
  // const [isDetailSidesheetOpen, setIsDetailSidesheetOpen] = useState(false);
  const [borrowerQualificationsParsed, setBorrowerQualificationsParsed] =
    useState<(TBorrowerQualificationResponseParsed | undefined)[]>([]);
  const [applicationStatus, setApplicationStatus] =
    useState<TQualificationStatusOption[]>();
  const [selectedQualification, setSelectedQualification] = useState<
    TBorrowerQualificationResponseParsed | undefined
  >();
  const [pendenciesModalOpen, updatePendenciesModalOpen] = useState<
    TQualificationWithStatusAndProduct | undefined
  >(undefined);
  const [customVariables, updateCustomVariables] =
    useState<ICustomVariablesRequest>();
  const { fetchPersonFile } = useUploadPersonFiles();
  const { fetchBusinessFile } = useUploadBusinessFiles();
  const { is_issuer_app } = useAppContext();
  const { setSelectedOriginator } = useOriginatorContext();

  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(10);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [
    selectedQualificationForCondition,
    setSelectedQualificationForCondition,
  ] = useState<TBorrowerQualificationResponseParsed | undefined>();
  const [
    selectedQualificationForConditionEdit,
    setSelectedQualificationForConditionEdit,
  ] = useState<TBorrowerQualificationResponseParsed | undefined>();
  const [productData, setProductData] = useState<TProducts | undefined>();
  const [forceUpdateSidesheet, setForceUpdateSidesheet] = useState<Date>(
    new Date(),
  );
  const [finishRequest, setFinishRequest] = useState(false);
  const { isProfileAuthorized } = usePermify();
  const [handleApproveCheck, updateHandleApproveCheck] =
    useState<THandleApproveCheck | null>(null);
  const pid = params?.id as string;

  const handleGetBorrowerQualification = async (
    productId: string,
    page = 0,
    itemsPerPage?: number,
  ) => {
    const borrowerContent = await getProductQualificationsPerson(
      productId,
      page,
      itemsPerPage,
    );
    setBorrowerQualificationsParsed([]);
    const productDataRes = await getProduct(productId);

    if (!productDataRes) {
      showSnackbar('Ocorreu um erro ao buscar informações do produto');
      navigate('/products/main');
    }

    setTotalItemsQty(borrowerContent?.total_elements || 0);

    const parsedApplication = borrowerContent?.content.map(
      async (application) => {
        const originator = is_issuer_app ? application.originator : undefined;
        if (productDataRes?.data?.borrower_type === 'PERSON') {
          try {
            const personHttpService = new PersonHttpService();
            if (originator) {
              personHttpService.setOriginatorId(originator);
            }
            const personDetail = await personHttpService.getPerson(
              application.borrower.id,
            );
            const userUploadedFiles = await fetchPersonFile(
              application.borrower.id,
              originator,
            );

            const missingDocuments =
              (
                productDataRes?.data as TProducts
              ).variables_configuration?.borrower.person_document.filter(
                (document) => {
                  if (document.required) {
                    const inx = userUploadedFiles.findIndex(
                      (userUploadedFile) =>
                        document.type === userUploadedFile.type,
                    );
                    return inx === -1;
                  }
                  return false;
                },
              ) || [];

            const missingVariables =
              customVariables?.borrower.person.filter((variable) => {
                if (variable.required) {
                  const variableValue = get(personDetail, variable.name);
                  return typeof variableValue === 'undefined';
                }
                return false;
              }) || [];

            const missingConditions = (productDataRes?.data as TProducts)
              .conditions_required
              ? ConditionsRequiredProperty.filter((condition) => {
                  const conditionValue = get(
                    application.condition,
                    condition.name,
                    null,
                  );
                  return conditionValue === null;
                })
              : [];

            const missingBankOrPix =
              (!personDetail.external_bank_account ||
                !personDetail.external_bank_account?.bank_account) &&
              (!personDetail.pix || !personDetail.pix?.key) &&
              productData?.beneficiary_type === 'BORROWER';

            return {
              ...application,
              missingDocuments,
              missingVariables,
              missingConditions,
              missingBankOrPix,
              borrower: {
                ...application.borrower,
                taxpayer_id: personDetail.taxpayer_id,
                name: personDetail.full_name,
              },
            };
          } catch (error) {
            return {
              ...application,
              missingDocuments: [],
              missingVariables: [],
              missingConditions: [],
              missingBankOrPix: false,
              borrower: {
                ...application.borrower,
                taxpayer_id: '',
                name: '',
              },
            };
          }
        }
        if (productDataRes?.data?.borrower_type === 'BUSINESS') {
          try {
            const customerHttpService = new CustomerHttpService();
            if (originator) {
              customerHttpService.setOriginatorId(originator);
            }
            const businessDetail = await customerHttpService.getBusiness(
              application.borrower.id,
            );
            const businessUploadedFiles = await fetchBusinessFile(
              application.borrower.id,
              originator,
            );

            const missingDocuments =
              (
                productDataRes?.data as TProducts
              ).variables_configuration?.borrower.business_document.filter(
                (document) => {
                  if (document.required) {
                    const inx = businessUploadedFiles.findIndex(
                      (businessUploadedFile) =>
                        document.type === businessUploadedFile.type,
                    );
                    return inx === -1;
                  }
                  return false;
                },
              ) || [];

            const missingVariables =
              customVariables?.borrower.business.filter((variable) => {
                if (variable.required) {
                  const variableValue = get(businessDetail, variable.name);
                  return typeof variableValue === 'undefined';
                }
                return false;
              }) || [];

            const missingConditions = (productDataRes?.data as TProducts)
              .conditions_required
              ? ConditionsRequiredProperty.filter((condition) => {
                  const conditionValue = get(
                    application.condition,
                    condition.name,
                    null,
                  );
                  return conditionValue === null;
                })
              : [];

            const missingBankOrPix =
              (!businessDetail.external_bank_account ||
                !businessDetail.external_bank_account?.bank_account) &&
              (!businessDetail.pix || !businessDetail.pix?.key);

            return {
              ...application,
              missingDocuments,
              missingVariables,
              missingConditions,
              missingBankOrPix,
              borrower: {
                ...application.borrower,
                taxpayer_id: businessDetail.taxpayer_id,
                name: businessDetail.legal_name,
              },
            };
          } catch (error) {
            return {
              ...application,
              missingDocuments: [],
              missingVariables: [],
              missingConditions: [],
              missingBankOrPix: false,
              borrower: {
                ...application.borrower,
                taxpayer_id: '',
                name: '',
              },
            };
          }
        }
      },
    );
    const resolvedQualification = await Promise.all(parsedApplication || []);

    setBorrowerQualificationsParsed((state) =>
      state?.concat(
        ...[
          resolvedQualification.filter((content) => {
            const inx = state.findIndex(
              (stateContent) => stateContent?.id === content?.id,
            );
            return inx < 0;
          }),
        ],
      ),
    );
    setProductData(productDataRes?.data);
    setFinishRequest(true);
  };

  const handleGetQualificationStatus = async (productId: string) => {
    const response = await getQualificationStatusOptions(
      productId,
      (productData?.borrower_type as 'PERSON' | 'BUSINESS') || 'PERSON',
    );
    setApplicationStatus(response || []);
  };

  useEffect(() => {
    if (customVariables) {
      setFinishRequest(false);
      handleGetBorrowerQualification(pid, currentPage, linesPerPage);
    }
  }, [currentPage, linesPerPage, customVariables]);

  useEffect(() => {
    const productId = params?.id as string;
    handleGetQualificationStatus(productId);
    getCustomVariables(productId).then((cv) => updateCustomVariables(cv));
  }, []);

  const handleCloseQualificationDetail = () => {
    setSelectedQualification(undefined);
  };

  const handleUpdateQualificationStatus = async (
    qualification: TBorrowerQualificationResponseParsed | undefined,
    identifier: string,
  ) => {
    const productId = params?.id as string;

    if (identifier === 'APPROVED' || identifier === 'QUALIFIED') {
      if (
        qualification &&
        (qualification.missingDocuments.length > 0 ||
          qualification.missingVariables.length > 0 ||
          qualification.missingBankOrPix)
      ) {
        updatePendenciesModalOpen({
          ...qualification,
          productData: productData as TProducts,
        } as TQualificationWithStatusAndProduct);
        return;
      }
      if (
        qualification &&
        productData?.conditions_required &&
        qualification.missingConditions.length > 0
      ) {
        if (
          isProfileAuthorized(
            EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
          )
        ) {
          setSelectedQualificationForCondition({
            ...qualification,
            identifier,
          } as TBorrowerQualificationResponseParsed);
        }
        return;
      }
    }

    const result = await updateQualificationStatus(
      productId,
      qualification?.id || '',
      identifier,
      'BORROWER',
      qualification?.originator,
    );

    if (result) {
      setBorrowerQualificationsParsed((state) =>
        state.map((element) => {
          if (element?.id === (qualification?.id || '')) {
            return {
              ...element,
              status: identifier,
            };
          } else {
            return element;
          }
        }),
      );

      setSelectedQualification((state) => {
        if (state !== undefined) {
          return {
            ...state,
            status: identifier,
          };
        }
      });
    }
  };

  const checkHandleApprove = async (handleApproveParams: TConditionItems) => {
    const {
      creditLimit,
      interestRate,
      maxPaymentAmount,
      minRequestedAmount,
      maxGracePeriod,
      minGracePeriod,
      maxPaymentNumber,
      minPaymentNumber,
      qualification,
    } = handleApproveParams;
    const fieldsToWarn = [];
    if (
      qualification?.condition?.line_of_credit?.credit_limit &&
      (!creditLimit || creditLimit.length === 0)
    ) {
      fieldsToWarn.push('Limite total');
      handleApproveParams.creditLimit = undefined;
    }
    if (
      qualification?.condition?.schedule_based_conditions &&
      qualification?.condition?.schedule_based_conditions.length > 0 &&
      (!interestRate || interestRate.length === 0)
    ) {
      fieldsToWarn.push('Taxa de Juros');
      handleApproveParams.interestRate = undefined;
    }
    if (
      qualification?.condition?.line_of_credit?.max_payment_amount &&
      (!maxPaymentAmount || maxPaymentAmount.length === 0)
    ) {
      fieldsToWarn.push('Limite de parcela');
      handleApproveParams.maxPaymentAmount = undefined;
    }
    if (
      qualification?.condition?.min_requested_amount &&
      (!minRequestedAmount || minRequestedAmount.length === 0)
    ) {
      fieldsToWarn.push('Mínimo solicitação');
      handleApproveParams.minRequestedAmount = undefined;
    }
    // if (qualification?.condition?.due_day && (!dueDay || dueDay.length === 0)) {
    //   fieldsToWarn.push('Dia de vencimento');
    //   handleApproveParams.dueDay = undefined;
    // }
    if (
      qualification?.condition?.max_grace_period &&
      (!maxGracePeriod || maxGracePeriod.length === 0)
    ) {
      fieldsToWarn.push('Carência máxima');
      handleApproveParams.maxGracePeriod = undefined;
    }
    if (
      qualification?.condition?.min_grace_period &&
      (!minGracePeriod || minGracePeriod.length === 0)
    ) {
      fieldsToWarn.push('Carência mínima');
      handleApproveParams.minGracePeriod = undefined;
    }
    if (
      qualification?.condition?.max_payment_number &&
      (!maxPaymentNumber || maxPaymentNumber.length === 0)
    ) {
      fieldsToWarn.push('Prazo máximo');
      handleApproveParams.maxPaymentNumber = undefined;
    }
    if (
      qualification?.condition?.min_payment_number &&
      (!minPaymentNumber || minPaymentNumber.length === 0)
    ) {
      fieldsToWarn.push('Prazo mínimo');
      handleApproveParams.minPaymentNumber = undefined;
    }
    if (fieldsToWarn.length > 0) {
      updateHandleApproveCheck({
        handleApproveParams,
        fieldsToWarn,
      });
      return;
    }

    return handleApprove(handleApproveParams);
  };

  const handleApprove = async ({
    creditLimit,
    interestRate,
    maxPaymentAmount,
    qualification,
    minRequestedAmount,
    dueDay,
    maxGracePeriod,
    minGracePeriod,
    maxPaymentNumber,
    minPaymentNumber,
    tac,
  }: TConditionItems) => {
    const res = await updateLimitsCondition(
      productData?.id || '',
      qualification?.id || '',
      {
        ...qualification?.condition,
        line_of_credit: {
          ...qualification?.condition?.line_of_credit,
          credit_limit: creditLimit
            ? Number(formatToCurrencyFloat(creditLimit || ''))
            : undefined,
          max_payment_amount:
            Number(formatToCurrencyFloat(maxPaymentAmount || '')) || undefined,
        },
        due_day: Number(dueDay) || undefined,
        min_requested_amount:
          Number(formatToCurrencyFloat(minRequestedAmount || '')) || undefined,
        max_grace_period: Number(maxGracePeriod) || undefined,
        min_grace_period: Number(minGracePeriod) || undefined,
        max_payment_number: Number(maxPaymentNumber) || undefined,
        min_payment_number: Number(minPaymentNumber) || undefined,
        tac: Number(formatToCurrencyFloat(tac || '')) || undefined,
        schedule_based_conditions: interestRate
          ? [
              {
                interest_rate: formatInterestRate(interestRate),
                upper_limit:
                  (productData?.max_num_payments
                    ? productData?.max_num_payments
                    : Number(maxPaymentNumber)) || undefined,
              },
            ]
          : undefined,
      },
      qualification?.originator,
    );

    if (res) {
      if (selectedQualificationForCondition) {
        const result = await updateQualificationStatus(
          productData?.id || '',
          qualification?.borrower?.id || '',
          selectedQualificationForCondition?.identifier || '',
          'BORROWER',
        );

        if (result) {
          setBorrowerQualificationsParsed((state) =>
            state.map((element) => {
              if (element?.id === (qualification?.id || '')) {
                return {
                  ...element,
                  status: selectedQualificationForCondition?.identifier || '',
                };
              } else {
                return element;
              }
            }),
          );
        }
      }
      setSelectedQualificationForCondition(undefined);
      setSelectedQualificationForConditionEdit(undefined);
      setForceUpdateSidesheet(new Date());
      setSelectedOriginator(null);
    }
  };

  const handleEditCondition = (
    qualification: TBorrowerQualificationResponseParsed | undefined,
  ) => {
    setSelectedOriginator(qualification?.originator || '');
    setSelectedQualificationForConditionEdit(qualification);
  };

  const statusList = useMemo(
    () =>
      [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ].concat(
        applicationStatus && applicationStatus?.length > 0
          ? applicationStatus?.map((status) => ({
              label: status.description,
              value: status.identifier,
            }))
          : [],
        {
          label: 'Reprovado',
          value: EQualificationStatus.DENIED as string,
        },
      ),
    [applicationStatus],
  );

  const isLoading = getProductQualificationsLoading;

  return (
    <>
      <Container>
        <div
          style={{
            display: 'flex',
            marginBottom: '30px',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <ButtonContainer>
            {isProfileAuthorized(
              EAccountPermissions.CREATE_PERSON_BUSINESS,
            ) && (
              <ButtonPrimary
                style={{ backgroundColor: 'transparent', width: '230px' }}
                typeVariant="outline"
                iconPosition="left"
                btnIcon={
                  <AddIcon color={theme.palette.brand.primary.base as string} />
                }
                onClick={() =>
                  navigate(`/${routingPath.qualification.new}`, {
                    state: {
                      product: productData,
                    },
                  })
                }
              >
                <TextM
                  weight="Semibold"
                  style={{
                    marginLeft: '11px',
                    color: theme.palette.brand.primary.base,
                  }}
                >
                  Criar Linha de Crédito
                </TextM>
              </ButtonPrimary>
            )}
          </ButtonContainer>
        </div>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
              width: '100%',
            }}
          >
            <Spinner />
          </div>
        ) : (!getProductQualificationsLoading && !borrowerStatusOptions) ||
          (borrowerQualificationsParsed?.length <= 0 && finishRequest) ? (
          <EmptyContent titleText="Nenhum tomador com linha de crédito encontrado" />
        ) : (
          borrowerStatusOptions &&
          borrowerQualificationsParsed.length > 0 && (
            <TableArea>
              <TableWrapper>
                <Table
                  showPagination
                  overflowVisible
                  handlePageChange={(selectedPage) => {
                    setCurrentPage(selectedPage - 1);
                  }}
                  handleLinesPerPage={(selectedLinesPerPage) => {
                    setCurrentPage(0);
                    setLinesPerPage(
                      selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                    );
                  }}
                  totalElementsQty={totalItemsQty}
                  currentPage={currentPage + 1}
                  linesPerPage={linesPerPage}
                  tableHeadCell={tableHeader}
                  tableBodyContent={borrowerQualificationsParsed?.map(
                    (qualification) => {
                      const missingItems =
                        qualification &&
                        (qualification.missingConditions.length > 0 ||
                          qualification.missingDocuments.length > 0 ||
                          qualification.missingVariables.length > 0 ||
                          qualification.missingBankOrPix);
                      return {
                        id: qualification?.id,
                        cells: [
                          {
                            id: '1',
                            content: (
                              <TableBodyCellContainer>
                                <TextS weight="Bold">
                                  {qualification?.borrower?.name}
                                </TextS>
                                {qualification?.borrower?.taxpayer_id && (
                                  <TextXs>
                                    {productData?.borrower_type === 'PERSON'
                                      ? formatCPF(
                                          qualification?.borrower?.taxpayer_id,
                                        )
                                      : formatCNPJ(
                                          qualification?.borrower?.taxpayer_id,
                                        )}
                                  </TextXs>
                                )}
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '2',
                            content: (
                              <TableBodyCellContainer>
                                <TextS weight="Bold">
                                  {roleTypesTranslationParse[
                                    qualification?.role || ''
                                  ] || qualification?.role}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '3',
                            content: (
                              <TableBodyCellContainer>
                                <TextS weight="Bold">
                                  {qualification?.employer?.legal_name || '-'}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '4',
                            content: (
                              <TableBodyCellContainer>
                                <TextS>
                                  {`${formatDateFromBackend(
                                    qualification?.created_at || '',
                                  ).toLocaleDateString(
                                    'pt-br',
                                  )} às ${formatStringHourFromBackend(
                                    qualification?.created_at || '',
                                  )}`}
                                </TextS>
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '5',
                            content: (
                              <TableBodyCellContainer>
                                {!missingItems ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <CheckCircleOutline
                                      style={{ marginRight: '5px' }}
                                      color="success"
                                    />
                                    <TextM
                                      style={{
                                        color: '#2e7d32',
                                      }}
                                    >
                                      Sem pendências
                                    </TextM>
                                  </div>
                                ) : (
                                  <ButtonDanger
                                    typeVariant="ghost"
                                    style={{
                                      fontWeight: 'normal',
                                      justifyContent: 'flex-start',
                                      padding: '0px',
                                    }}
                                    iconPosition="left"
                                    onClick={() =>
                                      qualification &&
                                      updatePendenciesModalOpen({
                                        ...qualification,
                                        productData: productData as TProducts,
                                      } as TQualificationWithStatusAndProduct)
                                    }
                                    btnIcon={
                                      <ErrorOutline
                                        color="error"
                                        style={{ marginRight: '5px' }}
                                      />
                                    }
                                  >
                                    Visualizar pendências
                                  </ButtonDanger>
                                )}
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '6',
                            content: (
                              <TableBodyCellContainer>
                                <CustomTableSelect
                                  selectItems={statusList}
                                  disabled={
                                    !isProfileAuthorized(
                                      EAccountPermissions.UPDATE_QUALIFICATION,
                                    )
                                  }
                                  isLoading={
                                    updateQualificationStatusLoading ===
                                    qualification?.id
                                  }
                                  handleChange={(identifier) =>
                                    handleUpdateQualificationStatus(
                                      qualification,
                                      identifier,
                                    )
                                  }
                                  value={qualification?.status || ''}
                                />
                              </TableBodyCellContainer>
                            ),
                          },
                          {
                            id: '7',
                            content: (
                              <TableBodyCellContainer
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginLeft: '0px',
                                }}
                              >
                                <ButtonPrimary
                                  typeVariant="outline"
                                  style={{ width: 'fit-content' }}
                                  onClick={() =>
                                    setSelectedQualification(qualification)
                                  }
                                >
                                  <DetailsIcon
                                    color={
                                      theme.palette.brand.primary.base as string
                                    }
                                  />
                                </ButtonPrimary>
                              </TableBodyCellContainer>
                            ),
                          },
                        ],
                      };
                    },
                  )}
                />
              </TableWrapper>
            </TableArea>
          )
        )}
      </Container>
      <PersonDetailsSideSheet
        isOpen={!!selectedQualification}
        handleClose={handleCloseQualificationDetail}
        qualification={selectedQualification}
        handleUpdateQualificationStatus={handleUpdateQualificationStatus}
        productInfo={productData}
        statusList={statusList}
        isStatusOptionsLoading={updateQualificationStatusLoading}
        handleEditCondition={handleEditCondition}
        forceUpdate={forceUpdateSidesheet}
      />
      <ConditionsModal
        qualification={selectedQualificationForCondition}
        productInfo={productData}
        isOpen={!!selectedQualificationForCondition}
        handleModalClose={() => setSelectedQualificationForCondition(undefined)}
        handleApprove={handleApprove}
        updateQualificationConditionLoading={updateLimitsConditionLoading}
      />
      <EditConditionSidesheet
        qualification={selectedQualificationForConditionEdit}
        productInfo={productData}
        isOpen={!!selectedQualificationForConditionEdit}
        handleClose={() => {
          setSelectedQualificationForConditionEdit(undefined);
          setSelectedOriginator(null);
        }}
        handleApprove={checkHandleApprove}
        updateQualificationConditionLoading={updateLimitsConditionLoading}
      />
      <PendenciesModal
        isOpen={pendenciesModalOpen !== undefined}
        qualification={pendenciesModalOpen}
        handleClose={() => updatePendenciesModalOpen(undefined)}
      />
      <ConfirmModal
        isOpen={handleApproveCheck !== null}
        icon={<AttentionCircularRedIcon />}
        title="Atenção!"
        color="danger"
        btnConfirmText="Continuar"
        isLoading={updateLimitsConditionLoading}
        handleConfirm={() => {
          handleApprove(
            handleApproveCheck?.handleApproveParams as TConditionItems,
          );
          updateHandleApproveCheck(null);
        }}
        handleClose={() => updateHandleApproveCheck(null)}
        handleCancel={() => updateHandleApproveCheck(null)}
      >
        {handleApproveCheck?.fieldsToWarn &&
          handleApproveCheck?.fieldsToWarn.length > 1 && (
            <>
              <TextM style={{ marginBottom: '15px' }}>
                Os campos abaixo não serão mais validados nas solicitações de{' '}
                <strong>{selectedQualification?.borrower.name}</strong>
              </TextM>
              {handleApproveCheck?.fieldsToWarn.map((field) => {
                return (
                  <TextM
                    style={{ paddingLeft: '25px', textAlign: 'left' }}
                    key={field}
                  >
                    - {field}
                  </TextM>
                );
              })}
            </>
          )}
        {handleApproveCheck?.fieldsToWarn &&
          handleApproveCheck?.fieldsToWarn.length === 1 && (
            <TextM style={{ textAlign: 'left' }}>
              Os campo <strong>{handleApproveCheck?.fieldsToWarn[0]}</strong>{' '}
              não será mais validado nas solicitações de{' '}
              <strong>{selectedQualification?.borrower.name}</strong>
            </TextM>
          )}
      </ConfirmModal>
    </>
  );
};

export default PersonQualificationContent;
