import { useSnackbar } from 'app/hooks/useSnackbar';
import { formatCurrency } from 'app/utils/normalizer';
import { useAuthContext, usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import {
  CustomerHttpService,
  PersonHttpService,
} from 'modules/customer/services';
import { TQualificationStatusOption } from 'modules/products/pages/interfaces';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomTableSelect,
  ButtonPrimary,
  Spinner,
} from '../../../../../../app/components';
import {
  TextS,
  TextXs,
} from '../../../../../../app/components/typography/body';
import { TextL } from '../../../../../../app/components/typography/body/TextL';
import { formatCNPJ, formatCPF } from '../../../../../../app/helpers';
import {
  formatDateForBackendString,
  formatDateFromBackend,
  formatStringHourFromBackend,
} from '../../../../../../app/helpers/dateHelpers';
import {
  TProducts,
  TQualification,
  useQualificationsContext,
} from '../../../../context';
import {
  useApplicationsService,
  useProductsService,
} from '../../../../services/hooks';
import { ApplicationDetailsSideSheet } from '../ApplicationDetailsSideSheet';
import { routingPath } from 'app/routes';
import {
  ApplicationsContentFiltersCount,
  ButtonApplicationContainer,
  Container,
  FilterArea,
  TableArea,
} from './styles';
import { EmptyContent } from '../EmptyContent';
import {
  EApplicationStatus,
  EApplicationStatusDescription,
  TApplicationResponse,
  TQualificationRequestApplication,
} from 'modules/products/services/hooks/useApplicationsService';
import { QualificationsHttpService } from 'modules/products/services/api';
import { EQualificationStatus } from 'modules/products/context/QualificationsProvider/qualifications.interfaces';
import { TCustomSelectOption } from 'app/components/CustomTableSelect/CustomTableSelect';
import {
  applicationStatusFilters,
  handleGetStaticStatusList,
  handleStatusListPermissions,
} from 'modules/products/helpers/statusList';
import { useQualificationChangeContext } from 'modules/products/context/QualificationChangeProvider/QualificationChangeProvider';
import {
  AddIcon,
  CopyIcon,
  DetailsIcon,
  InstallmentsFiltersIcon,
} from 'app/components/Icons';
import { Table, TextM } from 'app/components';
import { useTheme } from '@mui/material';
import { TStatusItem } from '../FilterStatusDropdown/FilterStatusDropdown';
import {
  TRegisterBusinessDataRequest,
  TRegisterPersonDataRequest,
} from 'modules/customer/context';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
  TableWrapper,
} from 'app/styles/components/Table';
import { format } from 'date-fns';
import {
  useQueryParam,
  NumberParam,
  JsonParam,
  withDefault,
} from 'use-query-params';
import SearchFilter, {
  TFilterItems,
} from 'app/components/SearchFilter/SearchFilter';
import ApplicationsFilters, {
  TFilters,
} from 'modules/applications/components/ApplicationsFilters/ApplicationsFilters';
import { initalFilters } from 'modules/applications/utils/filters';
import { useAppContext } from 'app/context/AppContextProvider';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

export type TApplicationQualificationData = {
  id?: string;
  status?: string;
};

export type TApplicationsParsed = Partial<TApplicationResponse> & {
  id?: string;
  borrower: {
    taxpayer_id?: string;
    name?: string;
    personData?: TRegisterPersonDataRequest;
    businessData?: TRegisterBusinessDataRequest;
  };
  qualificationData?: TQualificationRequestApplication;
  statusList?: TCustomSelectOption[];
};

const tableHeader = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pessoa</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Originador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Solicitado</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Financiado</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">TC</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '6',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">IOF</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '7',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Criado em</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '8',
    content: (
      <TableHeadCellContainer style={{ width: '100%' }}>
        <TextL weight="bold">Status</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '9',
    content: (
      <TableHeadCellContainer>
        <div />
      </TableHeadCellContainer>
    ),
  },
];

const filterItems: TFilterItems[] = [
  {
    name: 'ID',
    parameterName: 'id',
  },
  {
    name: 'CCB',
    parameterName: 'sequential_id',
  },
];

const ApplicationContent = () => {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isFiltersOpened, toggleFiltersOpened] = useState<boolean>(false);
  const [filters, updateFilters] = useState<TFilters>(
    initalFilters as TFilters,
  );
  const applicationStatusRef = useRef<TQualificationStatusOption[]>();
  const cessionStatusRef = useRef<TQualificationStatusOption[]>();
  const { showSnackbar, showSnackbarCopy } = useSnackbar();
  const {
    state: { borrowerStatusOptions },
    // dispatch,
  } = useQualificationsContext();
  const {
    getApplications,
    getApplication,
    getQualificationStatusOptions,
    applicationLoading,
  } = useApplicationsService();
  const { getProduct } = useProductsService();
  const [productData, setProductData] = useState<TProducts>();
  const [isLoading, setIsLoading] = useState(false);
  const { is_issuer_app } = useAppContext();
  const [finishRequest, setFinishRequest] = useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [searchInputValue, setSearchInputValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useQueryParam<TFilterItems>(
    'filter',
    withDefault(JsonParam, filterItems[0]),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const [applicationStatus, setApplicationStatus] =
    useState<TQualificationStatusOption[]>();
  const [cessionStatus, setCessionStatus] =
    useState<TQualificationStatusOption[]>();
  const [applicationDetail, setApplicationDetail] =
    useState<Partial<TApplicationsParsed>>();
  const [applicationsParsed, setApplicationsParsed] = useState<
    (TApplicationsParsed | undefined)[] | undefined
  >([]);
  const { originators } = useOriginatorContext();
  const [statusFilterList, setStatusFilterList] = useQueryParam<
    typeof applicationStatusFilters
  >('status', withDefault(JsonParam, applicationStatusFilters));
  const statusFilterListRef = useRef<TStatusItem[]>(statusFilterList);
  const {
    handleUpdateQualificationStatus,
    handleUpdateQualificationStatusLoading,
  } = useQualificationChangeContext();
  const { userInfo } = useAuthContext();
  const { isProfileAuthorized } = usePermify();

  const handleGetApplicationStatus = async (productId: string) => {
    const response = await getQualificationStatusOptions(
      productId,
      undefined,
      EApplicationStatus.PENDING_QUALIFICATION,
    );
    setApplicationStatus(response || []);

    const responseCession = await getQualificationStatusOptions(
      productId,
      undefined,
      EApplicationStatus.PENDING_CESSION_QUALIFICATION,
    );
    setCessionStatus(responseCession || []);
  };

  const isProdTypePerson = () => {
    return productData?.borrower_type === 'PERSON';
  };

  const handleSearch = () => {
    setCurrentPage(0);
    setLinesPerPage(10);
    setStatusFilterList(applicationStatusFilters);
    handleGetApplicationsByProduct(0, params?.id as string, searchInputValue);
  };

  useEffect(() => {
    const productId = params?.id as string;
    handleGetApplicationStatus(productId);
  }, []);

  const handleOpenApplicationDetail = (
    application: TApplicationsParsed | undefined,
  ) => {
    setApplicationDetail(application);
  };

  const handleCloseApplicationDetail = () => {
    setApplicationDetail(undefined);
  };

  const handleUpdateQualificationStatusAction = (
    applicationId: string,
    identifier: string,
  ) => {
    const foundApplication = applicationsParsed?.find(
      (appl) => appl?.id === applicationId,
    );
    if (foundApplication?.status === identifier) return;
    handleUpdateQualificationStatus(
      foundApplication as TApplicationResponse,
      identifier,
      foundApplication?.qualificationData,
    ).then((retn) => {
      if (retn) {
        setApplicationsParsed((applicationState) => {
          return applicationState?.map((application) => {
            if (application?.id === applicationId) {
              if (retn.isQualification) {
                return {
                  ...application,
                  qualificationData:
                    retn.newStatus as TQualificationRequestApplication,
                };
              }
              return {
                ...application,
                status: retn.newStatus as string,
              };
            }
            return application;
          });
        });
      }
    });
  };

  const handleStatusList = (
    application: TApplicationResponse,
    qualificationData: TQualification,
  ) => {
    if (
      [EApplicationStatus.PENDING_CESSION_QUALIFICATION].includes(
        application.status as EApplicationStatus,
      ) &&
      qualificationData
    ) {
      return [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ].concat(
        cessionStatusRef.current && cessionStatusRef?.current?.length > 0
          ? cessionStatusRef.current?.map((status) => ({
              label: status.description,
              value: status.identifier,
            }))
          : [],
        {
          label: 'Reprovado',
          value: EQualificationStatus.DENIED as string,
        },
      );
    }

    if (
      [EApplicationStatus.PENDING_QUALIFICATION].includes(
        application.status as EApplicationStatus,
      ) &&
      qualificationData
    ) {
      return [
        {
          label: 'Aprovado',
          value: EQualificationStatus.APPROVED as string,
        },
      ].concat(
        applicationStatusRef.current &&
          applicationStatusRef?.current?.length > 0
          ? applicationStatusRef.current?.map((status) => ({
              label: status.description,
              value: status.identifier,
            }))
          : [],
        {
          label: 'Reprovado',
          value: EQualificationStatus.DENIED as string,
        },
      );
    }
    if (
      [
        EApplicationStatus.PENDING_QUALIFICATION,
        EApplicationStatus.PENDING_CESSION_QUALIFICATION,
      ].includes(application.status as EApplicationStatus) &&
      !qualificationData
    ) {
      return [
        {
          label:
            EApplicationStatusDescription[EApplicationStatus.PENDING_RANDOM],
          value: EQualificationStatus.PENDING_RANDOM as string,
        },
        {
          label: EApplicationStatusDescription[EApplicationStatus.CANCELED],
          value: EQualificationStatus.CANCELED as string,
        },
      ];
    }
    return handleGetStaticStatusList(application.status);
  };

  const handleParseApplicationData = async (
    applications: TApplicationResponse[],
    product?: TProducts,
  ): Promise<(TApplicationsParsed | undefined)[] | undefined> => {
    const personHttpService = new PersonHttpService();
    const customerHttpService = new CustomerHttpService();
    const qualificationHttpService = new QualificationsHttpService();

    try {
      const applicationFilteredWithBorrowerDataPromise = applications.map(
        async (application) => {
          const originator = application?.originator;
          if (originator && is_issuer_app) {
            personHttpService.setOriginatorId(originator.id);
            customerHttpService.setOriginatorId(originator.id);
            qualificationHttpService.setOriginatorId(originator.id);
          }
          if (product?.borrower_type === 'PERSON') {
            try {
              const personDetail = await personHttpService.getPerson(
                application.borrower.id,
              );

              const qualificationRes = [
                EApplicationStatus.PENDING_QUALIFICATION,
                EApplicationStatus.PENDING_CESSION_QUALIFICATION,
              ].includes(application.status as EApplicationStatus)
                ? await qualificationHttpService.getQualificationByApplication(
                    product?.id || '',
                    application?.id || '',
                    application.status ===
                      EApplicationStatus.PENDING_CESSION_QUALIFICATION
                      ? 'CESSION'
                      : 'APPLICATION',
                  )
                : null;

              const statusList = handleStatusList(
                application,
                qualificationRes?.data?.content?.[0],
              ).filter(
                handleStatusListPermissions(
                  application,
                  handleShowApplicationStatus(application),
                  userInfo,
                ),
              );

              return {
                ...application,
                borrower: {
                  ...application.borrower,
                  taxpayer_id: personDetail?.taxpayer_id,
                  name: personDetail?.full_name,
                  personData: personDetail || undefined,
                },
                qualificationData: qualificationRes?.data?.content?.[0],
                statusList: statusList,
              };
            } catch (error) {
              return {
                ...application,
                borrower: {
                  ...application.borrower,
                  taxpayer_id: '',
                  name: '',
                  personData: undefined,
                },
                qualificationData: undefined,
                statusList: [
                  {
                    label:
                      EApplicationStatusDescription[
                        EApplicationStatus.PENDING_RANDOM
                      ],
                    value: EApplicationStatus.PENDING_RANDOM,
                  },
                  {
                    label:
                      EApplicationStatusDescription[
                        EApplicationStatus.CANCELED
                      ],
                    value: EApplicationStatus.CANCELED,
                  },
                ],
              };
            }
          }
          if (product?.borrower_type === 'BUSINESS') {
            try {
              const businessDetail = await customerHttpService.getBusiness(
                application.borrower.id,
              );

              const qualificationRes =
                await qualificationHttpService.getQualificationByApplication(
                  product?.id || '',
                  application?.id || '',
                );

              const statusList = handleStatusList(
                application,
                qualificationRes?.data?.content?.[0],
              ).filter(
                handleStatusListPermissions(
                  application,
                  handleShowApplicationStatus(application),
                  userInfo,
                ),
              );

              return {
                ...application,
                borrower: {
                  ...application.borrower,
                  taxpayer_id: businessDetail.taxpayer_id,
                  name: businessDetail.legal_name,
                  businessData: businessDetail,
                },
                qualificationData: qualificationRes?.data?.content?.[0],
                statusList: statusList,
              };
            } catch (error) {
              return {
                ...application,
                borrower: {
                  ...application.borrower,
                  taxpayer_id: '',
                  name: '',
                  businessData: undefined,
                },
                qualificationData: undefined,
                statusList: [
                  {
                    label:
                      EApplicationStatusDescription[
                        EApplicationStatus.PENDING_RANDOM
                      ],
                    value: EApplicationStatus.PENDING_RANDOM,
                  },
                  {
                    label:
                      EApplicationStatusDescription[
                        EApplicationStatus.CANCELED
                      ],
                    value: EApplicationStatus.CANCELED,
                  },
                ],
              };
            }
          }
        },
      );

      const parsedApplications = await Promise.all(
        applicationFilteredWithBorrowerDataPromise || [],
      );

      return parsedApplications;
    } catch (err) {
      console.log(err);
    }
  };

  const handleGetApplicationsByProduct = async (
    page = 0,
    productId: string,
    searchApplicationId?: string,
  ) => {
    try {
      setIsLoading(true);
      setApplicationsParsed([]);
      const filterStatusSelect: string[] = [];

      statusFilterListRef.current.map((item) => {
        if (item.selected) {
          filterStatusSelect.push(item.value);
        }
      });

      const applicationsData =
        typeof searchApplicationId === 'string' &&
        searchApplicationId.length > 0 &&
        selectedFilter?.parameterName === 'id'
          ? await getApplication(searchApplicationId).then((application) => ({
              total_elements: 1,
              content:
                application && application.product.id === productId
                  ? [application]
                  : [],
            }))
          : await getApplications(
              page,
              linesPerPage,
              {
                product_id: productId || '',
                borrower_id: filters?.borrower,
                created_date_from: formatDateForBackendString(
                  filters?.minCreatedAtDate,
                ),
                created_date_to: formatDateForBackendString(
                  filters?.maxCreatedAtDate,
                ),
                disbursement_date_from: formatDateForBackendString(
                  filters?.minDisbursementDate,
                ),
                disbursement_date_to: formatDateForBackendString(
                  filters?.maxDisbursementDate,
                ),
                disbursement_processed_date_from: formatDateForBackendString(
                  filters.minDisbursementProcessedAtDate,
                ),
                disbursement_processed_date_to: formatDateForBackendString(
                  filters.maxDisbursementProcessedAtDate,
                ),
                originator_id: filters?.originator,
                funding_id: filters?.fundingId,
                ...(selectedFilter?.parameterName &&
                searchApplicationId &&
                searchApplicationId.length > 0
                  ? { [selectedFilter.parameterName]: searchApplicationId }
                  : {}),
              },
              filters.status &&
                filters.status.length === initalFilters.status.length
                ? undefined
                : filters.status,
            );

      if (
        !applicationsData?.content ||
        applicationsData?.content.length === 0
      ) {
        setApplicationsParsed([]);
        setTotalItemsQty(0);
        setIsLoading(false);
        setFinishRequest(true);
        return;
      }
      const productResponse = await getProduct(productId);
      if (!productResponse) {
        showSnackbar('Ocorreu um erro ao buscar informações do produto');
        navigate('/products/main');
        return;
      }
      setProductData(productResponse?.data);

      const applicationFilteredParsedData = await handleParseApplicationData(
        applicationsData?.content || [],
        productResponse?.data,
      );

      setTotalItemsQty(applicationsData?.total_elements || 0);

      setApplicationsParsed((state) =>
        state?.concat(
          ...[
            applicationFilteredParsedData?.filter((content) => {
              const inx = state?.findIndex(
                (stateContent) => stateContent?.id === content?.id,
              );
              return inx < 0;
            }),
          ],
        ),
      );
      setCurrentPage(page);
      setIsLoading(false);
      setFinishRequest(true);
    } catch (err) {
      setIsLoading(false);
      setFinishRequest(true);
    }
  };

  const handleShowApplicationStatus = (
    application: TApplicationsParsed | undefined,
  ) => {
    if (
      [
        EApplicationStatus.PENDING_QUALIFICATION,
        EApplicationStatus.PENDING_CESSION_QUALIFICATION,
      ].includes(application?.status as EApplicationStatus)
    ) {
      return (
        application?.qualificationData?.status ||
        EApplicationStatus.PENDING_RANDOM
      );
    } else {
      return application?.status;
    }
  };

  const handleCopyItems = () => {
    const header =
      'created_at\tid\tborrower_id\tproduct_id\trequested_amount' +
      '\tinterest_rate\ttac_amount\tfinance_fee\tnum_payments' +
      '\tfirst_payment_date\tdisbursement_date\tissue_date\tstatus' +
      '\tstatus_description\tsequential_id\tfunding_id\tfunding_name' +
      '\tloan_total_processing_cost\tloan_annual_interest_rate\tloan_annual_effective_interest_rate' +
      '\tloan_monthly_effective_interest_rate\tloan_total_amount_owed' +
      '\tloan_disbursement_amount\tloan_financed_amount\tloan_iof_daily_rate\tloan_iof_amount' +
      '\tloan_iof_base_rate\tloan_payment_amount\tloan_issue_date\tloan_last_payment_date' +
      '\tborrower_taxpayer_id\tborrower_full_name\tborrower_phone' +
      '\tborrower_email_address\tborrower_pix_key\tborrower_pix_key_type' +
      '\tborrower_created_at\tborrower_birth_date\r\n';

    const content = applicationsParsed
      ?.map((item) => {
        return `${
          (item?.created_at
            ? format(new Date(item?.created_at), 'yyyy-MM-dd')
            : '') || ''
        }\t${item?.id || ''}\t${item?.borrower?.id || ''}\t${
          item?.product?.id || ''
        }\t${item?.requested_amount?.toLocaleString() || ''}\t${
          item?.interest_rate?.toLocaleString() || ''
        }\t${item?.tac_amount?.toLocaleString() || ''}\t${
          item?.finance_fee?.toLocaleString() || ''
        }\t${item?.num_payments || ''}\t${item?.first_payment_date || ''}\t${
          item?.disbursement_date || ''
        }\t${item?.issue_date || ''}\t${item?.status || ''}\t${
          item?.status_description || ''
        }\t${item?.sequential_id || ''}\t${item?.funding?.id || ''}\t${
          item?.funding?.legal_name || ''
        }\t${
          item?.loan_details?.total_processing_cost?.toLocaleString() || ''
        }\t${
          item?.loan_details?.annual_interest_rate?.toLocaleString() || ''
        }\t${
          item?.loan_details?.annual_effective_interest_rate.toLocaleString() ||
          ''
        }\t${item?.loan_details?.monthly_effective_interest_rate?.toLocaleString()}\t${
          item?.loan_details?.total_amount_owed?.toLocaleString() || ''
        }\t${
          item?.loan_details?.disbursement_amount?.toLocaleString() || ''
        }\t${item?.loan_details?.financed_amount?.toLocaleString() || ''}\t${
          item?.loan_details?.iof_daily_rate?.toLocaleString() || ''
        }\t${item?.loan_details?.iof_amount?.toLocaleString() || ''}\t${
          item?.loan_details?.iof_base_rate?.toLocaleString() || ''
        }\t${item?.loan_details?.payment_amount?.toLocaleString() || ''}\t${
          item?.loan_details?.issue_date || ''
        }\t${item?.loan_details?.last_payment_date || ''}\t${
          item?.borrower?.taxpayer_id || ''
        }\t${item?.borrower.name}\t${
          isProdTypePerson()
            ? (item?.borrower?.personData?.phone?.country_code || '') +
              (item?.borrower?.personData?.phone?.country_code || '') +
              (item?.borrower?.personData?.phone?.number || '')
            : (item?.borrower?.businessData?.phone?.country_code || '') +
              (item?.borrower?.businessData?.phone?.area_code || '') +
              (item?.borrower?.businessData?.phone?.number || '')
        }\t${
          isProdTypePerson()
            ? item?.borrower?.personData?.email_address || ''
            : item?.borrower?.businessData?.email_address || ''
        }\t${
          isProdTypePerson()
            ? item?.borrower?.personData?.pix?.key || ''
            : item?.borrower?.businessData?.pix?.key || ''
        }\t${
          isProdTypePerson()
            ? item?.borrower?.personData?.pix?.key_type || ''
            : item?.borrower?.businessData?.pix?.key_type || ''
        }\t${
          isProdTypePerson()
            ? (item?.borrower?.personData?.created_at
                ? format(
                    new Date(item?.borrower?.personData?.created_at),
                    'yyyy-MM-dd',
                  )
                : '') || ''
            : item?.borrower?.businessData?.created_at
            ? format(
                new Date(item?.borrower?.businessData?.created_at),
                'yyyy-MM-dd',
              )
            : ''
        }\t${
          isProdTypePerson()
            ? item?.borrower?.personData?.birth_date || ''
            : item?.borrower?.businessData?.foundation_date || ''
        }`;
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${content}`);
    showSnackbarCopy();
  };

  useEffect(() => {
    handleGetApplicationsByProduct(
      currentPage,
      params?.id as string,
      searchInputValue,
    );
  }, []);

  useEffect(() => {
    handleGetApplicationsByProduct(0, params?.id as string, searchInputValue);
    if (currentPage > 0) setCurrentPage(0);
  }, [filters]);

  useEffect(() => {
    if (applicationStatus) {
      applicationStatusRef.current = applicationStatus;
    }
  }, [applicationStatus]);

  useEffect(() => {
    if (cessionStatus) {
      cessionStatusRef.current = cessionStatus;
    }
  }, [cessionStatus]);

  const filterCount = useMemo(() => {
    let counter = 0;
    if (filters.borrower) counter++;
    if (filters.originator) counter++;
    if (filters.minCreatedAtDate || filters.maxCreatedAtDate) counter++;
    if (filters.minDisbursementDate || filters.maxDisbursementDate) counter++;
    if (
      filters.minDisbursementProcessedAtDate ||
      filters.maxDisbursementProcessedAtDate
    )
      counter++;
    if (filters.productId) counter++;
    if (filters.fundingId) counter++;

    if (filters.status?.length !== 6) counter++;
    return counter;
  }, [filters]);

  const onApplyFilters = useCallback((f: TFilters) => {
    updateFilters(f);
    toggleFiltersOpened(false);
  }, []);

  return (
    <Container>
      <ButtonApplicationContainer>
        <div style={{ display: 'flex', alignItems: 'center', width: '52%' }}>
          <FilterArea>
            <SearchFilter
              inputValue={searchInputValue}
              handleClearInput={() => {
                setCurrentPage(0);
                setLinesPerPage(10);
                setSearchInputValue(() => '');
                setTimeout(() =>
                  handleGetApplicationsByProduct(0, params?.id as string),
                );
              }}
              handleInputChange={(evt) =>
                setSearchInputValue(() => evt.target.value)
              }
              filterItems={filterItems}
              handleSelectFilterItem={(selectedFilterValue) => {
                setSelectedFilter(() => selectedFilterValue);
              }}
              selectedFilter={selectedFilter?.name}
              handleSearch={handleSearch}
            />
          </FilterArea>
          <ButtonPrimary
            style={{ maxWidth: filterCount > 0 ? '202px' : '170px' }}
            typeVariant="outline"
            iconPosition="left"
            btnIcon={
              <InstallmentsFiltersIcon
                color={theme.palette.brand.primary.base as string}
              />
            }
            onClick={() => toggleFiltersOpened(() => true)}
          >
            <TextM
              weight="Bold"
              style={{
                marginLeft: '11px',
              }}
            >
              Mais filtros
            </TextM>
            {filterCount > 0 && (
              <ApplicationsContentFiltersCount>
                {filterCount}
              </ApplicationsContentFiltersCount>
            )}
          </ButtonPrimary>
        </div>

        <div style={{ display: 'flex' }}>
          <ButtonPrimary
            style={{
              backgroundColor: 'transparent',
              width: '220px',
              marginRight: '10px',
            }}
            typeVariant="outline"
            iconPosition="left"
            btnIcon={
              <CopyIcon color={theme.palette.brand.primary.base as string} />
            }
            onClick={() => handleCopyItems()}
          >
            <TextM
              weight="Semibold"
              style={{
                marginLeft: '11px',
                color: theme.palette.brand.primary.base,
              }}
            >
              Copiar resultado
            </TextM>
          </ButtonPrimary>
          {isProfileAuthorized(EAccountPermissions.CREATE_APPLICATION) && (
            <ButtonPrimary
              style={{ backgroundColor: 'transparent', width: '220px' }}
              typeVariant="outline"
              iconPosition="left"
              btnIcon={
                <AddIcon color={theme.palette.brand.primary.base as string} />
              }
              onClick={() =>
                navigate(`/${routingPath.products.applicationRequest}`, {
                  state: {
                    product: productData,
                  },
                })
              }
            >
              <TextM
                weight="Semibold"
                style={{
                  marginLeft: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Criar solicitação
              </TextM>
            </ButtonPrimary>
          )}
        </div>
      </ButtonApplicationContainer>
      <hr
        style={{
          marginTop: '30px',
          borderColor: '#e6e5ea',
          opacity: 0.3,
        }}
      />
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            width: '100%',
            marginTop: '50px',
          }}
        >
          <Spinner />
        </div>
      ) : (!isLoading || !applicationLoading) &&
        finishRequest &&
        applicationsParsed &&
        applicationsParsed?.length <= 0 ? (
        <EmptyContent titleText="Nenhum empréstimo solicitado" />
      ) : borrowerStatusOptions &&
        applicationsParsed &&
        applicationsParsed?.length > 0 ? (
        <TableArea>
          <TableWrapper isIssuer={is_issuer_app}>
            <Table
              showPagination
              handlePageChange={(selectedPage) => {
                setCurrentPage(selectedPage - 1);
                handleGetApplicationsByProduct(
                  selectedPage - 1,
                  params?.id as string,
                );
              }}
              handleLinesPerPage={(selectedLinesPerPage) => {
                setCurrentPage(0);
                setLinesPerPage(
                  selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                );
              }}
              totalElementsQty={totalItemsQty}
              currentPage={currentPage + 1}
              linesPerPage={linesPerPage}
              tableHeadCell={tableHeader.filter(
                (item) => is_issuer_app || item.id !== '2',
              )}
              tableFooterCell={[
                {
                  id: '1',
                  content: null,
                },
                {
                  id: '2',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>{`Total (${applicationsParsed.length})`}</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              applicationsParsed
                                .map(
                                  (application) =>
                                    application?.requested_amount || 0,
                                )
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '3',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>{`Total (${applicationsParsed.length})`}</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              applicationsParsed
                                .map(
                                  (application) =>
                                    application?.loan_details
                                      ?.financed_amount || 0,
                                )
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '4',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>{`Total (${applicationsParsed.length})`}</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              applicationsParsed
                                .map(
                                  (application) => application?.tac_amount || 0,
                                )
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '5',
                  content: (
                    <TableBodyCellContainer>
                      <div style={{ display: 'block' }}>
                        <div>
                          <TextM>{`Total (${applicationsParsed.length})`}</TextM>
                        </div>
                        <div>
                          <TextM weight="Bold">
                            {formatCurrency(
                              applicationsParsed
                                .map(
                                  (application) =>
                                    application?.loan_details?.iof_amount || 0,
                                )
                                .reduce((a, b) => a + b, 0),
                            )}
                          </TextM>
                        </div>
                      </div>
                    </TableBodyCellContainer>
                  ),
                },
                {
                  id: '6',
                  content: null,
                },
                {
                  id: '7',
                  content: null,
                },
                {
                  id: '8',
                  content: null,
                },
              ]}
              tableBodyContent={applicationsParsed?.map(
                (application, index) => {
                  const originator = originators?.find(
                    (o) => o.id === application?.originator?.id,
                  );
                  return {
                    id: application?.id || String(index),
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {application?.borrower?.name || ''}
                            </TextS>
                            {application?.borrower?.taxpayer_id && (
                              <TextXs>
                                {productData?.borrower_type === 'PERSON'
                                  ? formatCPF(
                                      application?.borrower?.taxpayer_id,
                                    )
                                  : formatCNPJ(
                                      application?.borrower?.taxpayer_id,
                                    )}
                              </TextXs>
                            )}
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {originator?.legal_name ??
                                (application?.originator?.legal_name || '')}
                            </TextS>
                            {originator?.taxpayer_id && (
                              <TextXs>
                                {formatCNPJ(originator?.taxpayer_id)}
                              </TextXs>
                            )}
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {formatCurrency(application?.requested_amount) ||
                                '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <TextS weight="Bold">
                              {formatCurrency(
                                application?.loan_details?.financed_amount,
                              ) || '-'}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {formatCurrency(application?.tac_amount)}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {formatCurrency(
                                application?.loan_details?.iof_amount,
                              )}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '7',
                        content: (
                          <TableBodyCellContainer>
                            <TextS>
                              {`${formatDateFromBackend(
                                application?.created_at || '',
                              ).toLocaleDateString('pt-BR')} às
                              ${formatStringHourFromBackend(
                                application?.created_at || '',
                              )}`}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '8',
                        content: (
                          <TableBodyCellContainer>
                            <CustomTableSelect
                              selectItems={
                                application?.statusList || [
                                  { label: '', value: '' },
                                ]
                              }
                              disabled={
                                !isProfileAuthorized(
                                  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION,
                                )
                              }
                              isLoading={
                                handleUpdateQualificationStatusLoading ===
                                application?.qualificationData?.id
                              }
                              handleChange={(identifier) => {
                                handleUpdateQualificationStatusAction(
                                  application?.id as string,
                                  identifier,
                                );
                              }}
                              value={
                                handleShowApplicationStatus(application) || ''
                              }
                            />
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '9',
                        content: (
                          <TableBodyCellContainer
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '0px',
                            }}
                          >
                            <ButtonPrimary
                              typeVariant="outline"
                              style={{ width: 'fit-content' }}
                              onClick={() =>
                                handleOpenApplicationDetail(application)
                              }
                            >
                              <DetailsIcon
                                color={
                                  theme.palette.brand.primary.base as string
                                }
                              />
                            </ButtonPrimary>
                          </TableBodyCellContainer>
                        ),
                      },
                    ].filter((item) => is_issuer_app || item.id !== '2'),
                  };
                },
              )}
            />
          </TableWrapper>
        </TableArea>
      ) : (
        <></>
      )}
      <ApplicationDetailsSideSheet
        handleClose={handleCloseApplicationDetail}
        application={applicationDetail}
        isOpen={!!applicationDetail}
        productInfo={productData}
        handleUpdateQualificationStatus={handleUpdateQualificationStatusAction}
      />
      <ApplicationsFilters
        filters={filters}
        handleApply={onApplyFilters}
        handleClose={() => toggleFiltersOpened(false)}
        isLoading={applicationLoading}
        isOpen={isFiltersOpened}
        hideProduct={true}
        hideBorrower={false}
        hideApplication={true}
      />
    </Container>
  );
};

export default ApplicationContent;
