/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { TRegisterPersonData, TRegisterPersonDataRequest } from '../../context';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { PersonHttpService } from './personHttpService';
import {
  IPersonList,
  TBatchImportResponse,
  TCreatePersonDataRequest,
} from '../../context/PersonProvider/person.interfaces';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import { IList } from 'modules/products/services/hooks/intefaces';
import {
  TLimit,
  TQualification,
} from 'modules/customer/interfaces/qualifications';
import { TKyc, TPersonKyc } from 'modules/customer/interfaces/kyc';
import { TScrList, TScrResponse } from 'modules/customer/interfaces/scr';
import { useAppContext } from 'app/context/AppContextProvider';

export interface IUpatePersonData
  extends Omit<TRegisterPersonDataRequest, 'taxpayer_id'> {
  taxpayer_id?: string | null;
}

const usePersonService = () => {
  const [creatPersonLoading, setCreatPersonLoading] = useState(false);
  const [getApplicationsLoading, setGetApplicationsLoading] = useState(false);
  const [personListLoading, setPersonListLoading] = useState(false);
  const [getPersonLoading, setGetPersonLoading] = useState(false);
  const [editPersonLoading, setEditPersonLoading] = useState(false);
  const [getQualificationsLoading, setQualificationsLoading] = useState(false);
  const [getLimitsLoading, setGetLimitsLoading] = useState(false);
  const [getPersonKycLoading, setPersonKycLoading] = useState(false);
  const [requestPersonKycLoading, setRequestPersonKycLoading] = useState(false);
  const [personBatchImportLoading, setPersonBatchImportLoading] =
    useState(false);
  const [personBatchImportResultLoading, setPersonBatchImportResultLoading] =
    useState(false);
  const [getPersonScrLoading, setPersonScrLoading] = useState(false);
  const [requestPersonScrLoading, setRequestPersonScrLoading] = useState(false);
  const [
    requestAcceptanceTermsScrLoading,
    setRequestAcceptanceTermsScrLoading,
  ] = useState(false);
  const { is_issuer_app } = useAppContext();
  const { showSnackbar } = useSnackbar();

  const parseCustomVariables = (data: TRegisterPersonData) => {
    const { customVariables } = data;
    const parsedCustomVariables = {};

    customVariables
      ? customVariables.map((cVariable) => {
          if (
            cVariable.value?.trim() !== '' &&
            cVariable.value !== null &&
            cVariable.value !== undefined
          ) {
            Object.assign(parsedCustomVariables, {
              [cVariable.name || '']: cVariable.value?.split('T')[0],
            });
          }
        })
      : [];

    return parsedCustomVariables;
  };

  const creatPerson = async (data: TRegisterPersonData) => {
    const parsedCustomVariables = parseCustomVariables(data);

    const creatPersonParsedData: TCreatePersonDataRequest = {
      birth_date: data?.birth_date,
      email_address: data?.email_address,
      full_name: data?.full_name?.trim(),
      nationality: data?.nationality?.trim(),
      occupation: data?.occupation?.trim(),
      pep: data?.pep && data.pep?.value ? data.pep.value === 'true' : undefined,
      ...(data?.id_document?.number &&
        data?.id_document?.number?.length > 0 && {
          id_document: {
            ...data?.id_document,
            type: data?.id_document?.type?.value,
          },
        }),
      marital_property_system: data?.marital_property_system?.value,
      marital_status: data?.marital_status?.value,
      mothers_name: data?.mothers_name ? data?.mothers_name?.trim() : undefined,
      sex: data?.sex?.value,
      spouse_full_name: data?.spouse_full_name?.trim(),
      phone: {
        area_code: data?.phone.number.replace(/\D+/g, '').slice(0, 2),
        country_code: data?.phone?.country_code || '55',
        number: data?.phone.number?.replace(/\D+/g, '').slice(2),
      },
      taxpayer_id: data?.taxpayer_id?.replace(/\D+/g, ''),
      address: {
        city: data?.address?.city.value?.trim(),
        state_code: data?.address?.state_code.value,
        country_code: data?.address?.country_code.value,
        district: data?.address?.district?.trim(),
        extra_info: data?.address?.extra_info?.trim(),
        postal_code: data?.address?.postal_code?.replace(/\D+/g, ''),
        street_name: data?.address?.street_name?.trim(),
        street_number: data?.address?.street_number?.trim(),
      },
      qualification_request: data?.hasQualificationRequest
        ? {
            condition: data.qualification_request?.condition
              ? {
                  due_day: data.qualification_request?.condition?.due_day,
                  line_of_credit: {
                    credit_limit:
                      data.qualification_request?.condition?.line_of_credit
                        ?.credit_limit,
                    max_payment_amount:
                      data.qualification_request?.condition?.line_of_credit
                        ?.max_payment_amount,
                  },
                  max_grace_period:
                    data.qualification_request?.condition?.max_grace_period,
                  min_grace_period:
                    data.qualification_request?.condition?.min_grace_period,
                  max_payment_number:
                    data.qualification_request?.condition?.max_payment_number,
                  min_payment_number:
                    data.qualification_request?.condition?.min_payment_number,
                  min_requested_amount:
                    data.qualification_request?.condition?.min_requested_amount,
                  tac: data.qualification_request?.condition?.tac,
                  schedule_based_conditions: [
                    {
                      interest_rate:
                        data.qualification_request?.condition
                          ?.schedule_based_conditions?.[0]?.interest_rate,
                      upper_limit:
                        data.qualification_request?.condition
                          ?.schedule_based_conditions?.[0]?.upper_limit,
                    },
                  ],
                }
              : undefined,
            product: {
              id: data?.productId as string,
            },
            funding: {
              id: data?.fundingId as string,
            },
            role: data?.role,
          }
        : undefined,
      ...(data?.external_bank_account &&
        data?.external_bank_account?.bank_account.length > 0 && {
          external_bank_account: data?.external_bank_account,
        }),
      ...(data?.pix &&
        data.pix?.key.length > 0 && {
          pix: data?.pix,
        }),
      ...(data.employer?.id && {
        employer: {
          id: data.employer?.id,
        },
      }),
      custom_variables: {
        values: {
          ...parsedCustomVariables,
        },
      },
      spouse_date_of_birth: data?.spouse_date_of_birth,
      spouse_taxpayer_id:
        data?.spouse_taxpayer_id && data?.spouse_taxpayer_id?.length > 0
          ? data?.spouse_taxpayer_id?.replace(/\D+/g, '')
          : null,
      monthly_income: data?.monthly_income,
      birthplace: data?.birthplace,
    };

    setCreatPersonLoading(true);
    try {
      const personService = new PersonHttpService();
      const res = await personService.creatPerson(creatPersonParsedData);
      setCreatPersonLoading(false);
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao criar pessoa');
      setCreatPersonLoading(false);
      return false;
    }
  };

  const getPersonList = async (
    page: number,
    searchParameterName?: string,
    searchParameterValue?: string,
    itemsPerPage?: number,
    skipIssuerRequest?: boolean,
  ): Promise<IPersonList | null> => {
    setPersonListLoading(true);
    try {
      const customerService = new PersonHttpService();
      const personList = await customerService.getPersonList(
        page,
        searchParameterName,
        searchParameterValue,
        itemsPerPage,
        is_issuer_app && !skipIssuerRequest,
      );
      return personList;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar a lista de pessoas');
    } finally {
      setPersonListLoading(false);
    }
    return null;
  };

  const personBatchImport = async (
    csvFile: FormData,
  ): Promise<TBatchImportResponse | null> => {
    try {
      setPersonBatchImportLoading(true);
      const personService = new PersonHttpService();
      const res = await personService.personBatchImport(csvFile);
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao enviar o arquivo de pessoas');
      throw error;
    } finally {
      setPersonBatchImportLoading(false);
    }
  };

  const getPersonBatchImportStatus = async (
    id: string,
  ): Promise<TBatchImportResponse | null> => {
    setPersonBatchImportLoading(true);
    try {
      const personService = new PersonHttpService();
      const person = await personService.getPersonBatchImportStatus(id);
      return person;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar status da importação');
      throw error;
    } finally {
      setPersonBatchImportLoading(false);
    }
  };

  const getPersonBatchImportResult = async (id: string): Promise<string> => {
    setPersonBatchImportResultLoading(true);
    try {
      const personService = new PersonHttpService();
      const resultFile = await personService.getPersonBatchImportResult(id);
      return resultFile;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar status da importação');
      throw error;
    } finally {
      setPersonBatchImportResultLoading(false);
    }
  };

  const getPerson = async (
    personId: string,
    hideError?: boolean,
    originatorId?: string,
    isIssuer?: boolean,
  ): Promise<TRegisterPersonDataRequest | null> => {
    setGetPersonLoading(true);
    try {
      const personService = new PersonHttpService();
      if (originatorId) {
        personService.setOriginatorId(originatorId);
      }
      const person = await personService.getPerson(personId, isIssuer);
      return person;
    } catch {
      if (!hideError) showSnackbar('Ocorreu um erro ao buscar pessoa');
    } finally {
      setGetPersonLoading(false);
    }
    return null;
  };

  const updatePerson = async (
    personId: string,
    personData: IUpatePersonData,
  ): Promise<boolean> => {
    setEditPersonLoading(true);
    try {
      const personService = new PersonHttpService();
      await personService.updatePerson(personId, personData);
      return true;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao editar uma pessoa');
      return false;
    } finally {
      setEditPersonLoading(false);
    }
  };

  const getApplications = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
    parameters?: { [key: string]: string | undefined },
    filterStatusSelect?: string[],
  ): Promise<IList<TApplicationResponse> | null> => {
    setGetApplicationsLoading(true);
    try {
      const personService = new PersonHttpService();
      return await personService.getApplicationsFromPerson(
        personId,
        page,
        itemsPerPage,
        parameters,
        filterStatusSelect,
      );
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar as solicitações de uma pessoa');
    } finally {
      setGetApplicationsLoading(false);
    }
    return null;
  };

  const getQualifications = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TQualification> | null> => {
    setQualificationsLoading(true);
    try {
      const personService = new PersonHttpService();
      return await personService.getLimits(personId, page, itemsPerPage);
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao buscar as linhas de crédito de uma pessoa',
      );
    } finally {
      setQualificationsLoading(false);
    }
    return null;
  };

  const getLimits = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TLimit> | null> => {
    setGetLimitsLoading(true);
    try {
      const personService = new PersonHttpService();
      return await personService.getLimits(personId, page, itemsPerPage);
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao buscar as linhas de crédito de uma pessoa',
      );
    } finally {
      setGetLimitsLoading(false);
    }
    return null;
  };

  const getPersonKyc = async (
    taxpayerId: string,
  ): Promise<TKyc<TPersonKyc>[] | null> => {
    setPersonKycLoading(true);
    try {
      const personService = new PersonHttpService();
      return await personService.getPersonKyc(taxpayerId);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de KYC');
    } finally {
      setPersonKycLoading(false);
    }
    return null;
  };

  const requestPersonKyc = async (
    name: string,
    taxpayerId: string,
  ): Promise<TKyc<TPersonKyc>[] | null> => {
    setRequestPersonKycLoading(true);
    try {
      const personService = new PersonHttpService();
      return await personService.requestPersonKyc(name, taxpayerId);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de KYC');
    } finally {
      setRequestPersonKycLoading(false);
    }
    return null;
  };

  const getPersonScr = async (
    personId: string,
    scrId: string,
  ): Promise<TScrResponse | null> => {
    setPersonScrLoading(true);
    try {
      const personService = new PersonHttpService();
      personService.setHeader({
        handled: true,
      });
      return await personService.getPersonScr(personId, scrId);
    } catch (error) {
      return null;
    } finally {
      setPersonScrLoading(false);
    }
  };

  const getPersonScrList = async (
    personId: string,
    page: number,
    itemsPerPage?: number,
  ): Promise<TScrList | null> => {
    setPersonScrLoading(true);
    try {
      const customerService = new PersonHttpService();
      customerService.setHeader({
        handled: true,
      });
      return await customerService.getPersonScrList(
        personId,
        page,
        itemsPerPage,
      );
    } catch (error) {
      return null;
    } finally {
      setPersonScrLoading(false);
    }
  };

  const requestPersonScr = async (
    personId: string,
    referenceDate?: string,
  ): Promise<TScrResponse | null> => {
    setRequestPersonScrLoading(true);
    try {
      const personService = new PersonHttpService();
      return await personService.requestPersonScr(personId, referenceDate);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de SCR');
    } finally {
      setRequestPersonScrLoading(false);
    }
    return null;
  };

  const requestAcceptanceTermsScr = async (
    personId: string,
  ): Promise<{ status: string } | null> => {
    setRequestAcceptanceTermsScrLoading(true);
    try {
      const personService = new PersonHttpService();
      const response = await personService.requestAcceptanceTermsScr(personId);
      showSnackbar('Termo de aceite enviado com sucesso');
      return response;
    } catch (error) {
      return null;
    } finally {
      setRequestAcceptanceTermsScrLoading(false);
    }
  };

  return {
    creatPersonLoading,
    personListLoading,
    getApplicationsLoading,
    personBatchImportLoading,
    getPersonLoading,
    editPersonLoading,
    getPersonKycLoading,
    requestPersonKycLoading,
    requestPersonScrLoading,
    getPersonScrLoading,
    getPersonScr,
    requestPersonScr,
    getPersonScrList,
    getPersonKyc,
    requestPersonKyc,
    creatPerson,
    getPersonList,
    getApplications,
    personBatchImport,
    getPerson,
    updatePerson,
    getPersonBatchImportStatus,
    getQualifications,
    getLimits,
    getLimitsLoading,
    getQualificationsLoading,
    getPersonBatchImportResult,
    personBatchImportResultLoading,
    requestAcceptanceTermsScr,
    requestAcceptanceTermsScrLoading,
  };
};

export default usePersonService;
