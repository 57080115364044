import {
  QRCodeInitiationType,
  ValidateEmvResponse,
} from 'modules/products/interfaces/qrCode';
import { ButtonDanger, Heading4, TextL } from '..';
import {
  QRCodeDetailsContainer,
  QRCodeDetailsContent,
  QRCodeDetailsHeader,
  QRCodeDetailsItem,
  QRCodeDetailsItemDescription,
  QRCodeDetailsItemIcon,
  QRCodeDetailsItems,
} from './styles';
import {
  QRCodeAbatementIcon,
  QRCodeAddressIcon,
  QRCodeAmountIcon,
  QRCodeBusinessIcon,
  QRCodeCalendarIcon,
  QRCodeCommentIcon,
  QRCodeDiscountIcon,
  QRCodeFineIcon,
  QRCodeIcon,
  QRCodeInterestIcon,
  QRCodePersonIcon,
  QRCodeTaxpayerIcon,
  TrashIcon,
} from '../Icons';
import { ReactNode, useMemo } from 'react';
import { formatCurrency, normalizeCpfCnpj } from 'app/utils/normalizer';
import { formatCep, formatDate } from 'app/helpers';
import { format } from 'date-fns';
import { useTheme } from '@mui/material';

type DetailItem = {
  icon: ReactNode;
  label: string;
  value: string;
};

type DetailItems = DetailItem[][];

function createDetailItem(
  items: DetailItems,
  item?: Partial<DetailItem> | Partial<DetailItem>[],
) {
  if (item) {
    if (!Array.isArray(item) && item.value) {
      items.push([item as DetailItem]);
    } else if (Array.isArray(item)) {
      items.push(item.filter((i) => !!i.value) as DetailItem[]);
    }
  }
}

function renderDetailItems(items: DetailItems) {
  return (
    <QRCodeDetailsContent>
      {items.map((item) => {
        if (item.length === 0) {
          return (
            <QRCodeDetailsItem key={item[0].label}>
              <QRCodeDetailsItemIcon>{item[0].icon}</QRCodeDetailsItemIcon>
              <QRCodeDetailsItemDescription>
                <span>{item[0].label}</span>
                <span>{item[0].value}</span>
              </QRCodeDetailsItemDescription>
            </QRCodeDetailsItem>
          );
        }

        return (
          <QRCodeDetailsItems key={`${item[0].label}-list`}>
            {item.map((subItem) => {
              return (
                <QRCodeDetailsItem key={subItem.label}>
                  <QRCodeDetailsItemIcon>{subItem.icon}</QRCodeDetailsItemIcon>
                  <QRCodeDetailsItemDescription>
                    <span>{subItem.label}</span>
                    <span>{subItem.value}</span>
                  </QRCodeDetailsItemDescription>
                </QRCodeDetailsItem>
              );
            })}
          </QRCodeDetailsItems>
        );
      })}
    </QRCodeDetailsContent>
  );
}

type QRCodeDetailsProps = {
  simulator?: boolean;
  review?: boolean;
  qrcodeEmvResponse: ValidateEmvResponse;
  onRemove?: () => void;
};
const QRCodeDetails = ({
  simulator,
  review,
  qrcodeEmvResponse,
  onRemove,
}: QRCodeDetailsProps) => {
  const theme = useTheme();

  const creditorItems: DetailItems = useMemo(() => {
    const _items: DetailItems = [];

    createDetailItem(_items, {
      icon: <QRCodePersonIcon />,
      label: 'Nome',
      value: qrcodeEmvResponse?.creditor?.name,
    });

    createDetailItem(_items, [
      {
        icon: <QRCodeTaxpayerIcon />,
        label: 'CPF / CNPJ',
        value:
          qrcodeEmvResponse?.creditor?.tax_payer_id &&
          normalizeCpfCnpj(qrcodeEmvResponse?.creditor?.tax_payer_id),
      },
      {
        icon: <QRCodeBusinessIcon />,
        label: 'Nome fantasia',
        value: qrcodeEmvResponse?.creditor?.fantasy_name,
      },
    ]);

    createDetailItem(_items, {
      icon: <QRCodeAddressIcon />,
      label: 'CEP',
      value:
        qrcodeEmvResponse?.creditor?.postal_code &&
        formatCep(qrcodeEmvResponse?.creditor?.postal_code),
    });

    const address = [
      qrcodeEmvResponse?.creditor?.address,
      qrcodeEmvResponse?.creditor?.city,
      qrcodeEmvResponse?.creditor?.state,
    ].filter(Boolean);
    if (address.length > 0) {
      createDetailItem(_items, {
        icon: <QRCodeAddressIcon />,
        label: 'Endereço',
        value: address.join(' - '),
      });
    }
    return _items;
  }, [qrcodeEmvResponse]);

  const qrCodesItems: DetailItems = useMemo(() => {
    const _items: DetailItems = [];

    createDetailItem(_items, {
      icon: <QRCodeIcon />,
      label: 'Tipo de QR Code',
      value:
        qrcodeEmvResponse.qrcode_type === QRCodeInitiationType.STATIC_QRCODE
          ? 'QR Code estático'
          : 'QR Code dinâmico',
    });

    createDetailItem(_items, {
      icon: <QRCodeIcon />,
      label: 'URL de pagamento do QR Code',
      value: qrcodeEmvResponse.emv,
    });

    createDetailItem(_items, {
      icon: <QRCodeIcon />,
      label: 'ID da transação',
      value: qrcodeEmvResponse.tx_id,
    });

    createDetailItem(_items, {
      icon: <QRCodeCommentIcon />,
      label: 'Comentário',
      value: qrcodeEmvResponse.description,
    });

    return _items;
  }, [qrcodeEmvResponse]);

  const chargeItems: DetailItems = useMemo(() => {
    const _items: DetailItems = [];

    createDetailItem(_items, [
      {
        icon: <QRCodeCalendarIcon />,
        label: 'Vencimento',
        value:
          qrcodeEmvResponse.due_date && formatDate(qrcodeEmvResponse.due_date),
      },
      {
        icon: <QRCodeCalendarIcon />,
        label: 'Data de expiração',
        value:
          qrcodeEmvResponse.expiration_date &&
          format(new Date(qrcodeEmvResponse.expiration_date), 'dd/MM/yyyy'),
      },
    ]);

    createDetailItem(_items, [
      {
        icon: <QRCodeInterestIcon />,
        label: 'URL de pagamento do QR Code',
        value:
          qrcodeEmvResponse.interest && `${qrcodeEmvResponse.interest}% ao mês`,
      },
      {
        icon: <QRCodeFineIcon />,
        label: 'Multa',
        value:
          qrcodeEmvResponse.fine &&
          formatCurrency(parseFloat(qrcodeEmvResponse.fine)),
      },
    ]);

    createDetailItem(_items, [
      {
        icon: <QRCodeAbatementIcon />,
        label: 'Abatimento',
        value:
          qrcodeEmvResponse.abatement &&
          formatCurrency(parseFloat(qrcodeEmvResponse.abatement)),
      },
      {
        icon: <QRCodeDiscountIcon />,
        label: 'Juros',
        value:
          qrcodeEmvResponse.discount &&
          formatCurrency(parseFloat(qrcodeEmvResponse.discount)),
      },
    ]);

    createDetailItem(_items, [
      {
        icon: <QRCodeAmountIcon />,
        label: 'Valor',
        value: formatCurrency(qrcodeEmvResponse.amount),
      },
    ]);

    return _items;
  }, [qrcodeEmvResponse]);

  return (
    <QRCodeDetailsContainer simulator={simulator} review={review}>
      {!review && (
        <QRCodeDetailsHeader>
          <Heading4 style={{ color: '#1E1360' }}>
            Informações da cobrança
          </Heading4>
          {typeof onRemove === 'function' && (
            <ButtonDanger
              style={{
                width: '140px',
                color: theme.palette?.brand?.error?.[1000],
              }}
              typeVariant="outline"
              onClick={onRemove}
              btnIcon={
                <div style={{ paddingLeft: '8px' }}>
                  <TrashIcon />
                </div>
              }
            >
              Remover
            </ButtonDanger>
          )}
        </QRCodeDetailsHeader>
      )}

      <TextL style={{ color: '#1E1360', marginBottom: '1rem' }} weight="bold">
        Dados do cobrador
      </TextL>
      {renderDetailItems(creditorItems)}

      <TextL
        style={{ color: '#1E1360', marginBottom: '1rem', marginTop: '40px' }}
        weight="bold"
      >
        Dados do QR Code
      </TextL>
      {renderDetailItems(qrCodesItems)}

      <TextL
        style={{ color: '#1E1360', marginBottom: '1rem', marginTop: '40px' }}
        weight="bold"
      >
        Cobrança
      </TextL>
      {renderDetailItems(chargeItems)}
    </QRCodeDetailsContainer>
  );
};

export default QRCodeDetails;
