import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useState } from 'react';
import {
  ButtonContainer,
  HeaderBlock,
  HeaderContainer,
  OptionsContainer,
  PageInfoContainer,
} from './styles';
import { Badge, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { TextM } from '../typography';
import { Breadcrumbs } from '../Breadcrumbs';
import {
  drawerActionType,
  useBreadcrumbsContext,
  useDrawerContext,
} from '../../context';
import { UserSideSheet } from '../UserSideSheet';
import { useAuthContext } from 'modules/auth/context';
import { useAppContext } from 'app/context/AppContextProvider';

const Header = () => {
  const theme = useTheme();
  const { breadcrumbs, breadcrumbsIco } = useBreadcrumbsContext();
  const { dispatch } = useDrawerContext();
  const smWindowSize = useMediaQuery(theme.breakpoints.down('laptop'));
  const [isUserSideSheetOpen, toggleUserSideSheetOpen] = useState(false);
  const { user } = useAuthContext();
  const { is_issuer_app } = useAppContext();

  const [firstName] = String(user?.attributes.name).split(' ');

  const handleMenu = () => {
    dispatch({
      type: drawerActionType.TOGGLE_DRAWER,
    });
  };

  return (
    <>
      <HeaderContainer>
        <HeaderBlock>
          <PageInfoContainer>
            {smWindowSize && (
              <IconButton onClick={handleMenu}>
                <MenuOutlinedIcon />
              </IconButton>
            )}
            <div
              style={{
                marginLeft: smWindowSize ? '10px' : '0px',
              }}
            >
              {is_issuer_app && (
                <TextM weight="Bold" style={{ marginBottom: '5px' }}>
                  Portal de Issuer
                </TextM>
              )}
              {breadcrumbs && (
                <Breadcrumbs
                  items={breadcrumbs}
                  mainIco={breadcrumbsIco || undefined}
                  separator="/"
                />
              )}
            </div>
          </PageInfoContainer>
        </HeaderBlock>
        <HeaderBlock>
          <OptionsContainer>
            <ButtonContainer onClick={() => toggleUserSideSheetOpen(true)}>
              <IconButton
                sx={{ backgroundColor: theme.palette.brand.neutral.base }}
              >
                <Badge variant="dot">
                  <PersonOutlineOutlinedIcon />
                </Badge>
              </IconButton>
              {!smWindowSize && (
                <>
                  <p style={{ marginLeft: '10px' }}>{`Olá ${firstName}!`}</p>
                  <KeyboardArrowDownOutlinedIcon />
                </>
              )}
            </ButtonContainer>
          </OptionsContainer>
        </HeaderBlock>
      </HeaderContainer>
      <UserSideSheet
        open={isUserSideSheetOpen}
        handleClose={() => toggleUserSideSheetOpen(false)}
      />
    </>
  );
};

export default Header;
