import { BaseHttpService } from 'app/services';
import { IList } from 'modules/products/services/hooks/intefaces';
import { OriginatorType } from './types';

export class OriginatorServiceHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  getOriginators = async (): Promise<IList<OriginatorType>> => {
    const res = await this.get(`${this.BASE_URL}/issuer/originators?size=9999`);
    return res.data;
  };
}
