import { useState } from 'react';
import {
  customerActionType,
  TRegisterBusinessData,
  TRegisterBusinessDataRequest,
  useCustomerContext,
} from '../../context';
import { useSnackbar } from '../../../../app/hooks/useSnackbar';
import { CustomerHttpService } from './customerHttpService';
import {
  IBusinessList,
  TCreateBusinessDataRequest,
} from '../../context/CustomerProvider/customer.interfaces';
import { IBusinessPersonRelation } from './interfaces';
import { RelationPerson } from 'modules/customer/interfaces/businessRelation';
import { IList } from 'modules/products/services/hooks/intefaces';
import { TApplicationResponse } from 'modules/products/services/hooks/useApplicationsService';
import {
  TLimit,
  TQualification,
} from 'modules/customer/interfaces/qualifications';
import { TBusinessKyc, TKyc } from 'modules/customer/interfaces/kyc';
import { TScrList, TScrResponse } from 'modules/customer/interfaces/scr';
import { useAppContext } from 'app/context/AppContextProvider';

export interface IUpdateBusinessData
  extends Omit<TRegisterBusinessDataRequest, 'taxpayer_id'> {
  taxpayer_id: string | null;
}

const useCustomerService = () => {
  const [creatBusinessLoading, setCreatBusinessLoading] = useState(false);
  const [getApplicationsLoading, setGetApplicationsLoading] = useState(false);
  const [getQualificationsLoading, setQualificationsLoading] = useState(false);
  const [getBusinessKycLoading, setBusinessKycLoading] = useState(false);
  const [getLimitsLoading, setGetLimitsLoading] = useState(false);
  const [requestBusinessKycLoading, setRequestBusinessKycLoading] =
    useState(false);
  const [businessRelationsLoading, setBusinessRelationsLoading] =
    useState(false);
  const [getBusinessScrLoading, setBusinessScrLoading] = useState(false);
  const [requestBusinessScrLoading, setRequestBusinessScrLoading] =
    useState(false);
  const [
    requestAcceptanceTermsScrLoading,
    setRequestAcceptanceTermsScrLoading,
  ] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { is_issuer_app } = useAppContext();
  const { dispatch } = useCustomerContext();

  const getBusiness = async (
    businessId: string,
    hideError?: boolean,
    originatorId?: string,
    isIssuer?: boolean,
  ): Promise<TRegisterBusinessDataRequest | null> => {
    setCreatBusinessLoading(true);
    try {
      const customerService = new CustomerHttpService();
      if (originatorId) {
        customerService.setOriginatorId(originatorId);
      }
      const business = await customerService.getBusiness(businessId, isIssuer);
      return business;
    } catch {
      if (!hideError) showSnackbar('Ocorreu um erro ao buscar a empresa');
    } finally {
      setCreatBusinessLoading(false);
    }
    return null;
  };

  const getBusinessList = async (
    page = 0,
    searchParameterName?: string,
    searchParameterValue?: string,
    itemsPerPage?: number,
    skipIssuerRequest?: boolean,
  ): Promise<IBusinessList | null> => {
    setCreatBusinessLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const businessList = await customerService.getBusinessList(
        page,
        searchParameterName,
        searchParameterValue,
        itemsPerPage,
        is_issuer_app && !skipIssuerRequest,
      );
      return businessList;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar a lista de empresas');
    } finally {
      setCreatBusinessLoading(false);
    }
    return null;
  };

  const updateBusiness = async (
    businessId: string,
    businessData: IUpdateBusinessData,
  ): Promise<boolean> => {
    setCreatBusinessLoading(true);
    try {
      const customerService = new CustomerHttpService();
      await customerService.updateBusiness(businessId, businessData);
      return true;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao editar uma empresa');
      return false;
    } finally {
      setCreatBusinessLoading(false);
    }
  };

  const parseCustomVariables = (data: TRegisterBusinessData) => {
    const { customVariables } = data;
    const parsedCustomVariables = {};

    customVariables
      ? customVariables.map((cVariable) => {
          if (
            cVariable.value?.trim?.() !== '' &&
            cVariable.value !== null &&
            cVariable.value !== undefined
          ) {
            Object.assign(parsedCustomVariables, {
              [cVariable.name || '']: cVariable.value,
            });
          }
        })
      : [];

    return parsedCustomVariables;
  };

  const createBusinessRelations = async (
    businessId: string,
    selectedRelationPersonList: RelationPerson[] | undefined,
  ) => {
    setBusinessRelationsLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const createBusinessRelationPromises = selectedRelationPersonList?.map(
        async (person) => {
          await customerService.createBusinessRelation(businessId, person);
        },
      );
      await Promise.all(createBusinessRelationPromises || []);
      return true;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao configurar representantes legais');
    } finally {
      setBusinessRelationsLoading(false);
    }
  };

  const getBusinessRelations = async (businessId: string) => {
    setBusinessRelationsLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const res = await customerService.getBusinessRelations(businessId);
      return res as IBusinessPersonRelation[];
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar lista de representantes');
    } finally {
      setBusinessRelationsLoading(false);
    }
  };

  const updateBusinessRelations = async (
    businessId: string,
    relationId: string,
    relationData: IBusinessPersonRelation | undefined,
  ) => {
    setBusinessRelationsLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const res = await customerService.updateBusinessRelations(
        businessId,
        relationId,
        {
          ...relationData,
          share: relationData?.share ? relationData?.share / 100 : undefined,
        } as IBusinessPersonRelation,
      );
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao atualizar representante');
    } finally {
      setBusinessRelationsLoading(false);
    }
  };

  const deleteBusinessRelations = async (
    businessId: string,
    relationId: string,
  ) => {
    setBusinessRelationsLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const res = await customerService.deleteBusinessRelations(
        businessId,
        relationId,
      );
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao excluir representante');
    } finally {
      setBusinessRelationsLoading(false);
    }
  };

  const creatBusiness = async (
    data: TRegisterBusinessData,
    selectedRelationPersonList: RelationPerson[] | undefined,
  ) => {
    const parsedCustomVariables = parseCustomVariables(data);

    const parsedAddress = {
      city: data?.address?.city.value?.trim(),
      state_code: data?.address?.state_code.value,
      country_code: data?.address?.country_code.value,
      district: data?.address?.district?.trim(),
      extra_info: data?.address?.extra_info?.trim(),
      postal_code: data?.address?.postal_code?.replace(/\D+/g, ''),
      street_name: data?.address?.street_name?.trim(),
      street_number: data?.address?.street_number?.trim(),
    };

    const creatBusinessParsedData: TCreateBusinessDataRequest = {
      foundation_date: data?.foundation_date,
      email_address: data?.email_address?.trim(),
      legal_name: data?.legal_name?.trim(),
      name: data?.name ? data?.name?.trim() : undefined,
      phone: {
        area_code: data?.phone.number.replace(/\D+/g, '').slice(0, 2),
        country_code: data?.phone?.country_code || '55',
        number: data?.phone.number?.replace(/\D+/g, '').slice(2),
      },
      taxpayer_id: data?.taxpayer_id?.replace(/\D+/g, ''),
      address: parsedAddress,
      tax_regime: data?.tax_regime?.value || 'SIMPLE',
      qualification_request: data?.hasQualificationRequest
        ? {
            product: {
              id: data?.productId as string,
            },
            funding: {
              id: data?.fundingId as string,
            },
            role: data?.role,
          }
        : undefined,
      custom_variables: {
        values: {
          ...parsedCustomVariables,
        },
      },
      ...(data?.external_bank_account &&
        data?.external_bank_account?.bank_account.length > 0 && {
          external_bank_account: data?.external_bank_account,
        }),
      ...(data?.pix &&
        data.pix?.key.length > 0 && {
          pix: data?.pix,
        }),
      share_capital: data?.share_capital,
      monthly_revenue: data?.monthly_revenue,
      ...(data?.billing_address
        ? {
            billing_address: {
              city: data?.billing_address?.city.value?.trim(),
              state_code: data?.billing_address?.state_code.value,
              country_code: data?.billing_address?.country_code.value,
              district: data?.billing_address?.district?.trim(),
              extra_info: data?.billing_address?.extra_info?.trim(),
              postal_code: data?.billing_address?.postal_code?.replace(
                /\D+/g,
                '',
              ),
              street_name: data?.billing_address?.street_name?.trim(),
              street_number: data?.billing_address?.street_number?.trim(),
            },
          }
        : {
            billing_address: parsedAddress,
          }),
      incorporation_type:
        data?.incorporation_type?.value &&
        data?.incorporation_type?.value.length > 0
          ? data?.incorporation_type?.value
          : undefined,
      industry_classification: data?.industry_classification || undefined,
    };

    setCreatBusinessLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const res = await customerService.creatBusiness(creatBusinessParsedData);
      await createBusinessRelations(res.data.id, selectedRelationPersonList);
      dispatch({
        type: customerActionType.RESET_STATE,
      });
      setCreatBusinessLoading(false);
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao criar uma empresa');
      setCreatBusinessLoading(false);
    }
  };

  const getApplications = async (
    businessId: string,
    page: number,
    itemsPerPage = 10,
    parameters?: { [key: string]: string | undefined },
    filterStatusSelect?: string[],
  ): Promise<IList<TApplicationResponse> | null> => {
    setGetApplicationsLoading(true);
    try {
      const businessService = new CustomerHttpService();
      return await businessService.getApplicationsFromBusiness(
        businessId,
        page,
        itemsPerPage,
        parameters,
        filterStatusSelect,
      );
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar as solicitações de uma empresa');
    } finally {
      setGetApplicationsLoading(false);
    }
    return null;
  };

  const getQualifications = async (
    businessId: string,
    role: 'BORROWER' | 'EMPLOYER',
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TQualification> | null> => {
    setQualificationsLoading(true);
    try {
      const businessService = new CustomerHttpService();
      return businessService.getQualifications(
        businessId,
        role,
        page,
        itemsPerPage,
      );
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao buscar as linhas de crédito de uma pessoa',
      );
    } finally {
      setQualificationsLoading(false);
    }
    return null;
  };

  const getLimits = async (
    businessId: string,
    role: 'BORROWER' | 'EMPLOYER',
    page: number,
    itemsPerPage?: number,
  ): Promise<IList<TLimit> | null> => {
    setGetLimitsLoading(true);
    try {
      const businessService = new CustomerHttpService();
      return businessService.getLimits(businessId, role, page, itemsPerPage);
    } catch (error) {
      showSnackbar(
        'Ocorreu um erro ao buscar as linhas de crédito de uma pessoa',
      );
    } finally {
      setGetLimitsLoading(false);
    }
    return null;
  };

  const getBusinessKyc = async (
    taxpayerId: string,
  ): Promise<TKyc<TBusinessKyc>[] | null> => {
    setBusinessKycLoading(true);
    try {
      const personService = new CustomerHttpService();
      return await personService.getBusinessKyc(taxpayerId);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de KYC');
    } finally {
      setBusinessKycLoading(false);
    }
    return null;
  };

  const requestBusinessKyc = async (
    name: string,
    taxpayerId: string,
  ): Promise<TKyc<TBusinessKyc>[] | null> => {
    setRequestBusinessKycLoading(true);
    try {
      const personService = new CustomerHttpService();
      return await personService.requestBusinessKyc(name, taxpayerId);
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de KYC');
    } finally {
      setRequestBusinessKycLoading(false);
    }
    return null;
  };

  const getBusinessScr = async (
    businessId: string,
    scrId: string,
  ): Promise<TScrResponse | null> => {
    setBusinessScrLoading(true);
    try {
      const customerService = new CustomerHttpService();
      customerService.setHeader({
        handled: true,
      });
      return await customerService.getBusinessScr(businessId, scrId);
    } catch (error) {
      return null;
    } finally {
      setBusinessScrLoading(false);
    }
  };

  const requestAcceptanceTermsScr = async (
    businessId: string,
  ): Promise<{ status: string } | null> => {
    setRequestAcceptanceTermsScrLoading(true);
    try {
      const customerService = new CustomerHttpService();
      const response = await customerService.requestAcceptanceTermsScr(
        businessId,
      );
      showSnackbar('Termo de aceite enviado com sucesso');
      return response;
    } catch (error) {
      return null;
    } finally {
      setRequestAcceptanceTermsScrLoading(false);
    }
  };

  const getBusinessScrList = async (
    businessId: string,
    page: number,
    itemsPerPage?: number,
  ): Promise<TScrList | null> => {
    setBusinessScrLoading(true);
    try {
      const customerService = new CustomerHttpService();
      customerService.setHeader({
        handled: true,
      });
      return await customerService.getBusinessScrList(
        businessId,
        page,
        itemsPerPage,
      );
    } catch (error) {
      return null;
    } finally {
      setBusinessScrLoading(false);
    }
  };

  const requestBusinessScr = async (
    businessId: string,
    referenceDate?: string,
  ): Promise<TScrResponse | null> => {
    setRequestBusinessScrLoading(true);
    try {
      const customerService = new CustomerHttpService();
      return await customerService.requestBusinessScr(
        businessId,
        referenceDate,
      );
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os dados de SCR');
    } finally {
      setRequestBusinessScrLoading(false);
    }
    return null;
  };

  return {
    creatBusinessLoading,
    businessRelationsLoading,
    getApplicationsLoading,
    getQualificationsLoading,
    getBusinessKycLoading,
    requestBusinessKycLoading,
    getBusinessScr,
    getBusinessScrLoading,
    requestBusinessScr,
    requestBusinessScrLoading,
    getLimitsLoading,
    getBusinessScrList,
    getBusinessKyc,
    requestBusinessKyc,
    getQualifications,
    getBusinessRelations,
    deleteBusinessRelations,
    updateBusinessRelations,
    createBusinessRelations,
    updateBusiness,
    creatBusiness,
    getBusiness,
    getApplications,
    getBusinessList,
    getLimits,
    requestAcceptanceTermsScr,
    requestAcceptanceTermsScrLoading,
  };
};

export default useCustomerService;
