export type ValidateEmv = {
  emv: string;
};

export enum QRCodeInitiationType {
  STATIC_QRCODE = 'STATIC_QRCODE',
  DYNAMIC_QRCODE = 'DYNAMIC_QRCODE',
}

export type ValidateEmvResponse = {
  emv: string;
  amount: number;
  description: string;
  key: string;
  qrcode_type: QRCodeInitiationType;
  tx_id?: string;
  creditor?: {
    name?: string;
    tax_payer_id?: string;
    postal_code?: string;
    city?: string;
    state?: string;
    address?: string;
    fantasy_name?: string;
  };
  debtor?: {
    name?: string;
    tax_payer_id?: string;
    postal_code?: string;
    city?: string;
    state?: string;
    address?: string;
    email?: string;
  };
  due_date?: string;
  expiration_date?: string;
  discount?: string;
  fine?: string;
  abatement?: string;
  interest?: string;
  original_amount?: string;
  validation_hash?: string;
};

export type ValidateEmvError = {
  message: string;
};
