const QRCodeCommentIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.07519 17.3527L1.66603 18.3327L2.64603 13.9235C2.00063 12.7163 1.66396 11.3682 1.66603 9.99935C1.66603 5.39685 5.39686 1.66602 9.99936 1.66602C14.6019 1.66602 18.3327 5.39685 18.3327 9.99935C18.3327 14.6018 14.6019 18.3327 9.99936 18.3327C8.63049 18.3347 7.28237 17.9981 6.07519 17.3527ZM6.31686 15.5919L6.86103 15.8835C7.82648 16.3995 8.90469 16.6683 9.99936 16.666C11.3179 16.666 12.6068 16.275 13.7032 15.5425C14.7995 14.8099 15.654 13.7687 16.1586 12.5506C16.6631 11.3324 16.7952 9.99195 16.5379 8.69875C16.2807 7.40554 15.6458 6.21765 14.7134 5.2853C13.7811 4.35295 12.5932 3.71802 11.3 3.46078C10.0068 3.20355 8.66631 3.33557 7.44814 3.84015C6.22996 4.34474 5.18877 5.19922 4.45623 6.29555C3.72369 7.39188 3.33269 8.68081 3.33269 9.99935C3.33269 11.111 3.60353 12.181 4.11603 13.1377L4.40686 13.6819L3.86102 16.1377L6.31686 15.5919Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeCommentIcon;
