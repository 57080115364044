import { useEffect, useMemo, useRef, useState } from 'react';
import { BoxSection } from 'app/components';
import {
  BalancePageContentWrapper,
  FundingsSelector,
  HeadingArea,
} from './styles';
import { Heading4, TextL } from 'app/components';
import { useDisbursementService } from 'modules/disbursement/services/useDisbursementService';
import {
  FundingAccount,
  TBalance,
} from 'modules/disbursement/interfaces/disbursement';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import BalanceStatementContent from './BalanceStatementContent';
import MovementsStatementContent, {
  MovementsStatementContentHandler,
} from './MovementsStatementContent';
import { useAuthContext } from 'modules/auth/context';
import { CustomSelect } from 'app/components/CustomSelect';
import { IFunding } from 'modules/products/services/hooks/intefaces';
import { useFundingsService } from 'modules/products/services/hooks';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
// import { useOriginatorContext } from 'modules/common/OriginatorProvider';
// import { useAppContext } from 'app/context/AppContextProvider';

const BalancePageContent = () => {
  // const { is_issuer_app } = useAppContext();
  const [fundings, updateFundings] = useState<IFunding[]>([]);
  const [balanceData, setBalanceData] = useState<TBalance>();
  const [fundingAccount, setFundingAccount] = useState<FundingAccount>();
  const movementsStatementContentHandler =
    useRef<MovementsStatementContentHandler>(null);
  const { userInfo } = useAuthContext();
  const { getAccount, getBalance, getBalanceLoading, getAccountLoading } =
    useDisbursementService();
  // const { originators, selectedOriginator, setSelectedOriginator } =
  //   useOriginatorContext();
  const { getFundings } = useFundingsService();
  const { id: fundingId } = useParams();
  const navigate = useNavigate();

  const [dateFrom] = useQueryParam('dateFrom', withDefault(StringParam, null));
  const [dateTo] = useQueryParam('dateTo', withDefault(StringParam, null));
  const [filterType] = useQueryParam(
    'filterType',
    withDefault(StringParam, null),
  );

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  const fetchBalance = async () => {
    const account = await getAccount(fundingId);
    setFundingAccount(account);
    const result = await getBalance(fundingId);
    setBalanceData(result?.data);
  };

  // const fetchBalanceWithOriginator = async () => {
  //   if (!selectedOriginator && is_issuer_app) {
  //     setSelectedOriginator(originators[0].id);
  //   }
  //   setTimeout(() => fetchBalance());
  // };

  useEffect(() => {
    fetchBalance();
    if (userInfo) {
      getFundings(userInfo.fundings).then((fundingResponse) => {
        if (fundingResponse) {
          updateFundings(fundingResponse.content);
        }
      });
    }
  }, [fundingId]);

  const selectWidth = useMemo(() => {
    if (fundings.length > 0) {
      const largerFundingName = fundings
        .map((funding) => ({
          label: funding.alias
            ? `${funding.legal_name} (${funding.alias})`
            : funding.legal_name,
          value: funding.id,
        }))
        .sort((a, b) => b.label.length - a.label.length)[0];
      return largerFundingName.label.length * 10;
    }
  }, [fundings]);

  const selectedFunding = fundings.find((funding) => funding.id === fundingId);
  // const selectedOriginatorContent = originators.find(
  //   (originator) => originator.id === selectedOriginator,
  // );
  return (
    <BalancePageContentWrapper>
      <BoxSection boxVariant="rounded">
        <HeadingArea>
          <Heading4 fontWeight="Sb">Saldo e Conciliação</Heading4>
          {/* {is_issuer_app && (
            <FundingsSelector
              style={{
                width: `${selectWidth}px`,
                minWidth: '400px',
              }}
            >
              <CustomSelect
                hideLineItem={true}
                value={
                  selectedOriginatorContent
                    ? {
                        label: selectedOriginatorContent.legal_name,
                        value: selectedOriginatorContent.id,
                      }
                    : undefined
                }
                options={originators.map((originator) => ({
                  label: originator.legal_name,
                  value: originator.id,
                }))}
                handleInputChange={(e) => {
                  setSelectedOriginator(e.currentTarget.value.value);
                  setTimeout(() => fetchBalance());
                }}
                name="fundingId"
                isClearable={false}
                placeholder="Selecione o funding"
              />
            </FundingsSelector>
          )} */}
          {hasMultiplesFundings ? (
            <FundingsSelector
              style={{
                width: `${selectWidth}px`,
                minWidth: '400px',
              }}
            >
              <CustomSelect
                hideLineItem={true}
                value={
                  selectedFunding
                    ? {
                        label: selectedFunding.alias
                          ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
                          : selectedFunding.legal_name,
                        value: selectedFunding.id,
                      }
                    : undefined
                }
                options={fundings.map((funding) => ({
                  label: funding.alias
                    ? `${funding.legal_name} (${funding.alias})`
                    : funding.legal_name,
                  value: funding.id,
                }))}
                handleInputChange={(e) => {
                  const params: string[] = [];
                  if (dateFrom) params.push(`dateFrom=${dateFrom}`);
                  if (dateTo) params.push(`dateTo=${dateTo}`);
                  if (filterType) params.push(`filterType=${filterType}`);
                  navigate(
                    `/disbursement/${e.currentTarget.value.value}/balance${
                      params.length > 0 ? `?${params.join('&')}` : ''
                    }`,
                  );
                }}
                name="fundingId"
                isClearable={false}
                placeholder="Selecione o funding"
              />
            </FundingsSelector>
          ) : (
            <TextL style={{ marginTop: '12px' }}>
              {selectedFunding?.alias
                ? `${selectedFunding.legal_name} (${selectedFunding.alias})`
                : selectedFunding?.legal_name}
            </TextL>
          )}
        </HeadingArea>
        <BalanceStatementContent
          balanceData={balanceData}
          fetchBalance={fetchBalance}
          fetchMovements={() => {
            movementsStatementContentHandler.current?.reloadMovement();
          }}
          fundingId={fundingId as string}
          balanceDataLoading={getBalanceLoading}
          fundingAccount={fundingAccount}
          fundingAccountLoading={getAccountLoading}
        />
        <div style={{ padding: '0px 48px' }}>
          <Divider />
        </div>
        <MovementsStatementContent
          funding={selectedFunding}
          ref={movementsStatementContentHandler}
        />
      </BoxSection>
    </BalancePageContentWrapper>
  );
};

export default BalancePageContent;
