const QRCodeFineIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13752_216520)">
        <path
          d="M18.2099 8.56688C18.2906 9.03248 18.3327 9.51133 18.3327 10C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 10C1.66602 5.3975 5.39685 1.66667 9.99935 1.66667C10.4882 1.66667 10.9673 1.70876 11.433 1.78952C11.1672 2.28042 10.9811 2.82083 10.8922 3.39336C10.5979 3.3536 10.2997 3.33333 9.99935 3.33333C8.23124 3.33333 6.53555 4.03571 5.2853 5.28595C4.03506 6.5362 3.33268 8.23189 3.33268 10C3.33268 11.7681 4.03506 13.4638 5.2853 14.714C6.53555 15.9643 8.23124 16.6667 9.99935 16.6667C11.7675 16.6667 13.4632 15.9643 14.7134 14.714C15.9636 13.4638 16.666 11.7681 16.666 10C16.666 9.69985 16.6458 9.40178 16.606 9.10763C17.1786 9.01873 17.719 8.83269 18.2099 8.56688Z"
          fill={color}
        />
        <path
          d="M12.916 8.2287V8.33333H8.33268C8.22218 8.33333 8.11619 8.37723 8.03805 8.45537C7.95991 8.53351 7.91602 8.63949 7.91602 8.75C7.91602 8.86051 7.95991 8.96649 8.03805 9.04463C8.11619 9.12277 8.22218 9.16667 8.33268 9.16667H11.666C12.2186 9.16667 12.7485 9.38616 13.1392 9.77686C13.5299 10.1676 13.7493 10.6975 13.7493 11.25C13.7493 11.8025 13.5299 12.3324 13.1392 12.7231C12.7485 13.1138 12.2186 13.3333 11.666 13.3333H10.8327V15H9.16602V13.3333H7.08268V11.6667H11.666C11.7765 11.6667 11.8825 11.6228 11.9606 11.5446C12.0388 11.4665 12.0827 11.3605 12.0827 11.25C12.0827 11.1395 12.0388 11.0335 11.9606 10.9554C11.8825 10.8772 11.7765 10.8333 11.666 10.8333H8.33268C7.78015 10.8333 7.25024 10.6138 6.85954 10.2231C6.46884 9.83244 6.24935 9.30253 6.24935 8.75C6.24935 8.19746 6.46884 7.66756 6.85954 7.27686C7.25024 6.88616 7.78015 6.66667 8.33268 6.66667H9.16602V5H10.8327V6.66667H11.5014C11.8574 7.28235 12.341 7.81507 12.916 8.2287Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.8327 8.33333C18.1339 8.33333 19.9993 6.46785 19.9993 4.16667C19.9993 1.86548 18.1339 0 15.8327 0C13.5315 0 11.666 1.86548 11.666 4.16667C11.666 6.46785 13.5315 8.33333 15.8327 8.33333ZM15.7806 0.83374C15.3572 0.83374 15.0217 1.19094 15.0481 1.61347L15.2273 4.48059C15.2455 4.77278 15.4878 5.00041 15.7806 5.00041C16.0734 5.00041 16.3157 4.77278 16.3339 4.48059L16.5131 1.61347C16.5395 1.19094 16.204 0.83374 15.7806 0.83374ZM15.7806 7.08374C16.2121 7.08374 16.5618 6.73396 16.5618 6.30249C16.5618 5.87102 16.2121 5.52124 15.7806 5.52124C15.3491 5.52124 14.9993 5.87102 14.9993 6.30249C14.9993 6.73396 15.3491 7.08374 15.7806 7.08374Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13752_216520">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QRCodeFineIcon;
