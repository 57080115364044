import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const CreditSimulatorWrapper = styled.div`
  position: relative;
   
  @media screen and (max-width: 1920px) {
    zoom: 0.9;
  }

  @media screen and (max-width: 1800px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1706px) {
    zoom: 0.8;
  }

  @media screen and (max-width: 1537px) {
    zoom: 0.75;
  }
`;

export const CreditSimulatorContentWrapper = styled.div`
  overflow-y: hidden;
  display: flex;
  width: 100%;
`;

export const CreditSimulatorContentBox = styled.div`
  display: flex;
  gap: 12px;
  overflow-y: hidden;
  width: 100%;
`;

export const ProductArea = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;

  .product-name-area {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .product-name {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
`;

export const ProductListContent = styled.div`
  width: 454px;
`;

export interface ProductListItemProps {
  isSelected?: boolean | undefined;
}

export const ProductListItem = styled.div<ProductListItemProps>`
  width: 100%;
  padding: 12px 24px;
  cursor: pointer;

  p {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ isSelected, theme }) =>
      isSelected &&
      css`
      color: ${theme.palette?.brand.primary.base};
    `}
  }
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-right: 24px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 28px;
      margin-right: 28px;
  }
`;

export const CreditSimulatorContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 120px;
  background-color: #FDFDFD;
  width: 90vw;
  height: auto;
  max-width: 1500px;
  /* max-height: 850px; */
  border: 2px solid #E6E5EA;
  border-radius: 20px;
  overflow-y: hidden;
  padding: 24px;
`;

export const Arrow = styled.div`
  position: fixed;
  height: 20px;
  width: 20px;
  border: 2px solid #E6E5EA;
  border-radius: 2px;
  background-color: #FDFDFD;
  bottom: 75px;
  right: 110.8px;
  transform: rotate(45deg);
  border-left: 0px;
  border-bottom: 0px;
`;

export const InputArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  border-left: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-top: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-bottom: ${({ mode }) =>
    mode === 'MODAL' ? 'none' : '2px solid #E6E5EA'};
  border-top-left-radius: ${({ mode }) => (mode === 'MODAL' ? '0px' : '20px')};
  border-bottom-left-radius: ${({ mode }) =>
    mode === 'MODAL' ? '0px' : '20px'};

  background-color: ${({ theme }) => theme.palette?.brand.neutral.base} ;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: 100%;
  flex: 1;
  

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #EDEDED;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette?.brand.primary.base};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette?.brand.primary.base};
  }
`;

export const InputAreaHeader = styled.div`
  width: 100%;
  padding: 38px 48px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      padding: 30px 48px;
  }
`;

export const InputContent = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  width: 100%;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: ${({ mode }) => (mode === 'MODAL' ? '0px' : '18px')};
`;

export const InputButtonArea = styled.div`
  width: 100%;
  padding: 22px 0px;
  padding-bottom: 0px;
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const PreviewArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  border: 1px solid rgba(128, 109, 251, 0.16);
  border-radius: 20px;

  background-color: ${({ theme }) => theme.palette?.brand.background.base};
  padding: 24px 40px;
  width: ${({ mode }) => (mode === 'MODAL' ? '30%' : '40%')};
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      padding: 22px;
  }
`;

export const TabArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 24px;
  margin: ${({ mode }) => (mode === 'MODAL' ? '0px 0px' : '40px 0px')};
`;

export const InputsWrapper = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`  
  margin-top: ${({ mode }) => (mode === 'MODAL' ? '20px' : '0px')};
  width: 100% ;
  padding: ${({ mode }) => (mode === 'MODAL' ? '0px' : '24px 40px')};
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 28px;
  }
`;

export const InputsGrid = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  margin-top: 12px;
  display: grid;
  grid-template-columns: ${({ mode }) =>
    mode === 'MODAL' ? '1fr 1fr 1fr' : '1fr 1fr'};
  grid-gap: 24px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      grid-gap: 16px;
  }
`;

export const NumPaymentContent = styled.div`
  height: 100%;
`;

export const DisbursementDatesSelector = styled.div`
  max-width: 300px;
  margin-bottom: 1rem;
`;

export const MultipleDisbursementDateContainer = styled.div`
  width: 100%;
  min-width: 100%;
  grid-column: 1 / span 2;
`;

export const MultipleDisbursementDatesSelector = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  margin-top: 1rem;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      gap: 16px;
  }
`;

export const DisbursementDateInput = styled.div<{
  disabled?: boolean;
  isMultiplesDisbursementDate?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ isMultiplesDisbursementDate }) =>
    isMultiplesDisbursementDate ? '50%' : 'auto'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ disabled, theme }) =>
    disabled &&
    css`
    .MuiInputBase-root {
      color: ${theme.palette?.grey?.[600]};
    }

    .MuiFormLabel-root + div > div > div:first-of-type {
      background-color: ${theme.palette?.grey?.[200]};
    }

    .MuiIconButton-root {
      svg {
        fill: ${theme.palette?.grey?.[600]};
      }
    }
  `};

  small {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.palette?.grey?.[600]};
  }
`;

export const PreviewItems = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  margin-top: 12px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 20px;
  }
`;

export const PreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
`;

export const PreviewButtonsArea = styled.div<{ mode: 'MODAL' | 'COMPONENT' }>`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptopL + 'px'}){
      margin-top: 20px;
  }
`;
