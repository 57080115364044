import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import CelcoinLogo from './CelcoinLogo.png';
import { formatCurrency, normalizeBoleto } from 'app/utils/normalizer';
import { format } from 'date-fns';
import { PaymentOrderBilletInfo } from 'modules/products/services/hooks/useApplicationsService';

export interface BilletsReceiptPdfProps {
  billetResults: PaymentOrderBilletInfo[];
}

Font.register({
  family: 'Urbanist',
  fonts: [
    {
      src: `/Urbanist.ttf`,
    },
    {
      src: `/Urbanist-SemiBold.ttf`,
      fontWeight: 'semibold',
    },
  ],
});

const styles = StyleSheet.create({
  hero: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#806DFB',
    padding: 10,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    width: '100%',
    color: '#1E1360',
    marginTop: 20,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 13,
    textAlign: 'center',
    width: '100%',
    color: '#6F6B82',
  },
  amount: {
    marginTop: 3,
    fontSize: 13,
    textAlign: 'center',
    width: '100%',
    color: '#806DFB',
  },
  sectionWrapper: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    border: 2,
    borderStyle: 'solid',
    borderColor: '#F7F7F7',
    padding: 20,
    borderRadius: 5,
  },
  sectionTitle: {
    fontSize: 14,
    width: '100%',
    fontWeight: 'semibold',
  },
  sectionRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginTop: 12,
  },
  rowTitle: {
    fontSize: 10,
    fontWeight: 'semibold',
  },
  rowValue: {
    fontSize: 10,
    color: '#6F6B82',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F7F7F7',
    padding: 20,
  },
  footerColumn: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
});

const BilletsReceiptPdf = ({ billetResults }: BilletsReceiptPdfProps) => {
  return (
    <Document>
      {billetResults.map((billetResult) => (
        <Page key={billetResult.digitable} size="A4">
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <View>
              <View style={styles.hero}>
                <View
                  style={{
                    height: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Image src={CelcoinLogo} style={{ width: '182px' }} />
                </View>
              </View>
              <View>
                <Text style={styles.title}>Comprovante de Pagamento</Text>
              </View>

              <View>
                <Text style={{ ...styles.subtitle, marginTop: 16 }}>Valor</Text>
                <Text style={styles.amount}>
                  {formatCurrency(billetResult.amount)}
                </Text>
              </View>

              <View style={styles.sectionWrapper}>
                <View
                  style={{
                    ...styles.section,
                    paddingTop: 5,
                    paddingBottom: 15,
                    marginTop: 10,
                  }}
                >
                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Valor do documento</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {formatCurrency(billetResult.original_amount)}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Multa</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {billetResult.fine_amount
                          ? formatCurrency(parseFloat(billetResult.fine_amount))
                          : '-'}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Desconto</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {billetResult.discount_amount
                          ? `-${formatCurrency(
                              parseFloat(billetResult.discount_amount),
                            )}`
                          : '-'}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Vencimento</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {format(new Date(billetResult.due_date), 'dd/MM/yyyy')}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.sectionWrapper}>
                <View style={{ ...styles.section, marginTop: -20 }}>
                  <Text style={styles.sectionTitle}>Dados do boleto:</Text>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Pagador</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text
                        style={styles.rowValue}
                      >{`${billetResult.payer} - ${billetResult.document_payer}`}</Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Beneficiário</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {`${billetResult.recipient} - ${billetResult.document_recipient}`}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Código de barras</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {normalizeBoleto(billetResult.digitable)}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Identificador</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                        textTransform: 'uppercase',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {billetResult.validation_hash}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Data de pagamento</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {billetResult.settle_date}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.sectionRow}>
                    <View
                      style={{
                        width: '30%',
                      }}
                    >
                      <Text style={styles.rowTitle}>Valor do documento</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Text style={styles.rowValue}>
                        {formatCurrency(billetResult.amount)}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.footer}>
              <View style={styles.footerColumn}>
                <Text style={{ fontSize: 10, color: '#6F6B82' }}>
                  Gerado em
                </Text>
                <Text style={{ fontSize: 10, color: '#6F6B82' }}>
                  {`${format(new Date(), 'dd/MM/yyyy')} às ${format(
                    new Date(),
                    'HH:mm',
                  )}`}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: '#6F6B82',
                    marginTop: 10,
                  }}
                >
                  Código de Identificação
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: '#6F6B82',
                    textTransform: 'uppercase',
                  }}
                >
                  {billetResult.validation_hash}
                </Text>
              </View>
              <View style={styles.footerColumn}>
                <Text style={{ fontSize: 10, color: '#6F6B82' }}>
                  Celcoin Instituição de Pagamento S.A
                </Text>
                <Text style={{ fontSize: 10, color: '#6F6B82' }}>
                  13.935.893/0001-09
                </Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default BilletsReceiptPdf;
