import { useState } from 'react';
import {
  ButtonDefault,
  ButtonPrimary,
  CustomCircularIndicator,
  Table,
  TextM,
} from '../';
import { Add } from '@mui/icons-material';
import {
  BillPaymentInput,
  BillPaymentInputGroup,
  TableWrapper,
} from './styles';
import { CustomInput } from 'app/components';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { BilletIcon, TrashIcon } from 'app/components/Icons';
import useBilletsService from 'modules/products/services/hooks/useBilletsService';
import { ValidateBilletsResponse } from 'modules/products/interfaces/billets';

const tableHeader = ['', 'Boletos', 'Ação'];

type BillPaymentQueryProps = {
  billets: string[];
  onValidateBillets: (billetsResponse: ValidateBilletsResponse) => void;
};
const BillPaymentQuery = ({
  billets,
  onValidateBillets,
}: BillPaymentQueryProps) => {
  const { validateBillets, validateBilletsLoading } = useBilletsService();
  const [error, setError] = useState<string | undefined>();
  const [billPayments, updateBillPayments] = useState<string[]>([]);
  const [billPaymentValue, updateBillPaymentValue] = useState('');

  const onAdd = () => {
    if (
      !billPayments.includes(billPaymentValue) &&
      !billets.includes(billPaymentValue)
    ) {
      updateBillPayments([...billPayments, billPaymentValue]);
      updateBillPaymentValue('');
    } else {
      setError('Boleto já inserido');
    }
  };

  const onRemove = (billet: string) => {
    updateBillPayments((prev) => prev.filter((b) => b !== billet));
  };

  const startValidateBillets = () => {
    validateBillets({
      billets: billets.concat(billPayments).map((billet) => ({
        digitable_or_bar_code: billet,
      })),
    }).then((_billets) => {
      if (_billets) {
        updateBillPayments([]);
        onValidateBillets(_billets);
      }
    });
  };

  return (
    <>
      <BillPaymentInputGroup>
        <BillPaymentInput>
          <CustomInput
            name="billPaymentValue"
            id="billPaymentValue"
            style={{ height: '45px' }}
            value={billPaymentValue}
            handleInputChange={(e) => {
              updateBillPaymentValue(e.target.value);
              if (error) {
                setError(undefined);
              }
            }}
            placeholder="Digite aqui"
            labelValue="Inserir linha digitável do boleto*"
            validationError={error}
          />
          <ButtonPrimary
            style={{
              width: '170px',
              marginTop: '1.8rem',
            }}
            iconPosition="right"
            btnIcon={<Add style={{ marginLeft: '8px' }} />}
            onClick={onAdd}
            disabled={!billPaymentValue || billPaymentValue.length === 0}
          >
            Adicionar
          </ButtonPrimary>
        </BillPaymentInput>
      </BillPaymentInputGroup>
      {billPayments.length > 0 && (
        <>
          <TableWrapper>
            <Table
              tableHeadCell={tableHeader.map((header, index) => ({
                id: String(index),
                content: (
                  <TableHeadCellContainer>
                    <TextM
                      weight="Bold"
                      style={{ marginLeft: index >= 2 ? '12px' : '0px' }}
                    >
                      {header}
                    </TextM>
                  </TableHeadCellContainer>
                ),
              }))}
              tableBodyContent={billPayments.map((billet, inx) => {
                return {
                  id: '1',
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <TextM weight="Bold">{inx + 1}</TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer
                          style={{
                            maxWidth: '950px',
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <TextM
                            style={{ width: 'fit-content' }}
                            weight="Regular"
                          >
                            {billet}
                          </TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <ButtonDefault
                            style={{
                              background: 'transparent',
                              border: 'none',
                              padding: '5px',
                            }}
                            onClick={() => onRemove(billet)}
                          >
                            <TrashIcon />
                          </ButtonDefault>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                };
              })}
            />
          </TableWrapper>

          <ButtonPrimary
            style={{
              width: '240px',
              marginTop: '32px',
            }}
            iconPosition="right"
            disabled={validateBilletsLoading}
            btnIcon={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  paddingTop: validateBilletsLoading ? '2px' : '0px',
                }}
              >
                {validateBilletsLoading ? (
                  <CustomCircularIndicator
                    size={22}
                    sx={{
                      color: 'white',
                    }}
                  />
                ) : (
                  <BilletIcon />
                )}
              </div>
            }
            onClick={startValidateBillets}
          >
            Consultar boletos
          </ButtonPrimary>
        </>
      )}
    </>
  );
};

export default BillPaymentQuery;
