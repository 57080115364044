import { DOCUMENT_TYPE } from 'app/helpers/documentTypes';
import { productsActionType } from './productsActionType';
import { EPersonFunctionProduct } from 'modules/customer/context/PersonProvider/person.interfaces';
import { Condition } from 'modules/customer/context/CustomerProvider/customer.interfaces';

export enum TypeProductEmission {
  BILLET_PAYMENT = 'BILLET_PAYMENT',
  COMMERCIAL_PAPER = 'COMMERCIAL_PAPER',
  CCB = 'CCB',
  QRCODE_PIX = 'QRCODE_PIX',
}

export type TProductVariableConfiguration = {
  description: string | null;
  display_name: string | null;
  id: string | null;
  name: string;
  required: boolean;
  type: string | null;
};

export type TProductDocumentConfiguration = {
  required: boolean;
  type: DOCUMENT_TYPE;
};

export type TProductQualificationRequest = {
  borrower?: {
    id: string;
  };
  employer?: {
    id: string;
  };
  funding: {
    id: string;
  };
  role: string;
  condition?: Condition;
};

export type TProducts = {
  additional_interest?: string | null;
  description?: string;
  agreement_template?: { id: string };
  borrower_type?: string;
  conditions_required?: boolean;
  created_at?: string;
  custom_simulator?: string;
  fine_rate?: string;
  funding_signature_required?: boolean;
  tac_not_included_cession_amount?: boolean;
  id: string;
  interest_type?: string;
  iof_zero?: boolean;
  total_applications?: number;
  total_amount_applications?: number;
  beneficiary_type:
    | 'BORROWER'
    | 'EXTERNAL_UNREGISTERED_ACCOUNT'
    | 'EXTERNAL_REGISTERED_ACCOUNT';
  max_interest_rate?: number;
  max_num_payments?: number;
  min_interest_rate?: number;
  min_num_payments?: number;
  name: string;
  originator_signature_required?: boolean;
  qualification_required?: boolean;
  type_product_emission?: TypeProductEmission;
  regulator_product_code?: string;
  schedule_type?: string;
  interest_compounding_type?: string;
  multiple_disbursements_dates?: boolean;
  financed_iof?: boolean;
  amortization_type?: string;
  status?: string;
  updated_at?: string;
  variables_configuration?: {
    application: TProductVariableConfiguration[];
    [EPersonFunctionProduct.BORROWER]: {
      business: TProductVariableConfiguration[];
      business_document: TProductDocumentConfiguration[];
      person: TProductVariableConfiguration[];
      person_document: TProductDocumentConfiguration[];
    };
    [EPersonFunctionProduct.CO_SIGNER]: {
      business: TProductVariableConfiguration[];
      business_document: TProductDocumentConfiguration[];
      person: TProductVariableConfiguration[];
      person_document: TProductDocumentConfiguration[];
    };
    employer: TProductVariableConfiguration[];
    employer_document: TProductDocumentConfiguration[];
    [EPersonFunctionProduct.GUARANTOR]: {
      business: TProductVariableConfiguration[];
      business_document: TProductDocumentConfiguration[];
      person: TProductVariableConfiguration[];
      person_document: TProductDocumentConfiguration[];
    };
  };
};

export interface IProductsState {
  content: TProducts[];
  has_next?: boolean;
  total_elements?: number;
  total_pages?: number;
}

export type IDispatch = (action: IProductsAction) => void;

export interface IProductsContext {
  state: IProductsState;
  dispatch: IDispatch;
}

export type IProductsAction =
  | {
      type: productsActionType.SET_PRODUCTS;
      payload: TProducts[];
    }
  | {
      type: productsActionType.SET_PRODUCT;
      payload: TProducts;
    };
