const QRCodeAbatementIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0007 3.33398C15.0631 3.33398 19.1673 5.57232 19.1673 8.33398V11.6673C19.1673 14.429 15.0631 16.6673 10.0007 16.6673C5.02815 16.6673 0.980651 14.5082 0.838151 11.8148L0.833984 11.6673V8.33398C0.833984 5.57232 4.93815 3.33398 10.0007 3.33398ZM10.0007 13.334C6.90065 13.334 4.15898 12.4948 2.50065 11.209V11.6673C2.50065 13.2357 5.73648 15.0007 10.0007 15.0007C14.1756 15.0007 17.3657 13.309 17.4965 11.7657L17.5006 11.6673L17.5015 11.209C15.8431 12.494 13.1015 13.334 10.0007 13.334ZM10.0007 5.00065C5.73648 5.00065 2.50065 6.76565 2.50065 8.33398C2.50065 9.90232 5.73648 11.6673 10.0007 11.6673C14.2648 11.6673 17.5006 9.90232 17.5006 8.33398C17.5006 6.76565 14.2648 5.00065 10.0007 5.00065Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeAbatementIcon;
