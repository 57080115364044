import { useState } from 'react';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { BilletsHttpService } from '../api/billetsHttpService';
import {
  ValidateBillets,
  ValidateBilletsResponse,
} from 'modules/products/interfaces/billets';

const useBilletsService = () => {
  const [validateBilletsLoading, setValidateBilletsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const validateBillets = async (
    _validateBillets: ValidateBillets,
  ): Promise<ValidateBilletsResponse | undefined> => {
    setValidateBilletsLoading(true);
    try {
      const validateService = new BilletsHttpService();
      const res = await validateService.validateBillets(_validateBillets);
      return res;
    } catch (error) {
      showSnackbar('Ocorreu um erro ao buscar os boletos');
    } finally {
      setValidateBilletsLoading(false);
    }
  };

  return {
    validateBilletsLoading,
    validateBillets,
  };
};

export default useBilletsService;
