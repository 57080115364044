import styled from '@emotion/styled';

export const ProductCardStyle = {
  width: '49%',
  padding: '32px 24px',
  height: '199px',
  cursor: 'pointer',
  boxShadow: '0px 4px 6px 0px #0000001A',
  borderRadius: '10px',
  border: '1px solid #EDEDED',
  display: 'flex',
  flexDirection: 'column',
};

export const ProductCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & svg {
    color: ${({ theme }) => theme.palette?.brand?.primary.base} !important;
  }
`;

export const ProductCardContent = styled.div`
  margin-top: 26px;
`;

export const ProductCardFooter = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
