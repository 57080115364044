import React, {
  ReactNode,
  useMemo,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { CustomTabIcon, CustomTabsContent } from './styles';
import { useNavigate, useParams } from 'react-router-dom';

export type TItems = {
  title: string | ReactNode;
  icon?: ReactNode;
  route: string;
};

export interface ICustomTabs {
  value?: number;
  handleChange?: (newValue: number, evt?: React.SyntheticEvent) => void;
  items?: TItems[];
  route: string;
  tabRoute?: string;
  showBorder?: boolean;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
}

const CustomTabs = ({
  value,
  handleChange,
  route,
  tabRoute = '/:tabRoute?',
  items,
  showBorder = true,
  variant = 'fullWidth',
}: ICustomTabs) => {
  const params = useParams();
  const navigate = useNavigate();
  const [tabPosition, updateTabPosition] = useState({
    width: '0px',
    left: '0px',
  });

  const originalRoute = useMemo(() => {
    const indexOf = route.indexOf(tabRoute);
    const routing = route.slice(0, indexOf);
    return Object.keys(params).reduce((finalRouting, param) => {
      return finalRouting.replace(`:${param}`, params[param] || '');
    }, routing);
  }, [route, tabRoute, params]);

  const selectedRouter = useMemo(() => {
    if (!params[tabRoute.replace(/[^a-zA-Z0-9]/g, '')]) return 0;
    return items?.findIndex(
      ({ route: itemRoute }) =>
        itemRoute === `/${params[tabRoute.replace(/[^a-zA-Z0-9]/g, '')]}`,
    );
  }, [items, params]);

  const onChangeEvent = (event: React.SyntheticEvent, newValue: number) => {
    if (items) navigate(`/${originalRoute}${items[newValue].route}`);
    if (typeof handleChange === 'function') handleChange?.(newValue, event);
  };

  useEffect(() => {
    if (value !== selectedRouter && handleChange) {
      handleChange(selectedRouter || 0);
    }
  }, [selectedRouter, value]);

  useLayoutEffect(() => {
    const tabElement = document.getElementById(`tab-${value}`);
    updateTabPosition({
      width: `${tabElement?.clientWidth ?? 0}px !important`,
      left: `${tabElement?.offsetLeft ?? 0}px !important`,
    });
  }, [value]);

  useLayoutEffect(() => {
    const tabElement = document.getElementById(`tab-${value}`);
    updateTabPosition({
      width: `${tabElement?.clientWidth ?? 0}px !important`,
      left: `${tabElement?.offsetLeft ?? 0}px !important`,
    });
  }, []);

  return (
    <Box sx={{ borderBottom: showBorder ? 1 : 0, borderColor: 'divider' }}>
      <CustomTabsContent>
        <Tabs
          value={value && value >= 0 ? value : 0}
          onChange={onChangeEvent}
          variant={variant}
          TabIndicatorProps={{
            sx: {
              minWidth: tabPosition.width,
              left: tabPosition.left,
            },
          }}
        >
          {items?.map((tab, index) => (
            <Tab
              sx={{ textTransform: 'none' }}
              key={tab.title?.toString()}
              label={tab.title}
              id={`tab-${index}`}
              iconPosition="start"
              icon={
                <CustomTabIcon isSelected={value === index}>
                  {tab.icon}
                </CustomTabIcon>
              }
            />
          ))}
        </Tabs>
      </CustomTabsContent>
    </Box>
  );
};

export default CustomTabs;
