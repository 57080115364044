const BilletIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99951 2V16H17.9995V2H1.99951ZM0.999512 0H18.9995C19.5518 0 19.9995 0.44772 19.9995 1V17C19.9995 17.5523 19.5518 18 18.9995 18H0.999512C0.447232 18 -0.000488281 17.5523 -0.000488281 17V1C-0.000488281 0.44772 0.447232 0 0.999512 0ZM3.99951 4H6.99951V14H3.99951V4ZM7.99951 4H9.99951V14H7.99951V4ZM10.9995 4H11.9995V14H10.9995V4ZM12.9995 4H15.9995V14H12.9995V4Z"
      fill="#F4F4FF"
    />
  </svg>
);

export default BilletIcon;
