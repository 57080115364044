import { useFormik } from 'formik';
import { useContext, useState, useCallback } from 'react';
import {
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
} from '../../../../../app/components';
import { StepContent } from '../../../components';
import {
  AboutStepExistingBusiness,
  StepContentButtonsArea,
  StepContentInput,
  StepContentInputArea,
} from './styles';
import { ArrowBack } from '@mui/icons-material';
import { LegalPersonHandlerStepperContext } from '../LegalPersonHandler';
import { useCustomer } from '../../../hooks';
import { useCustomerContext } from '../../../context';
import {
  formatCNPJ,
  formatPhone,
  isValidCNPJ,
} from '../../../../../app/helpers';
import { aboutStepSchema } from '../../../helpers/businessValidation';
import {
  EIncorporationType,
  ETaxRegime,
  TRegisterBusinessDataRequest,
} from '../../../context/CustomerProvider/customer.interfaces';
import { formatDateFromBackend } from '../../../../../app/helpers/dateHelpers';
import { useCustomerService } from 'modules/customer/services';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { routingPath } from 'app/routes';
import {
  CustomSelect,
  CustomInput,
  CalendarInput,
  CustomCurrencyInput,
  TextS,
} from 'app/components';
import { formatCurrency, formatToCurrencyFloat } from 'app/utils/normalizer';
import { formatBRLCurrencyMaskInput } from 'app/utils/currency';
import { FormLegendCardWrapper } from 'modules/customer/components/FormLegendCard/styles';
import FormLegendCard from 'modules/customer/components/FormLegendCard/FormLegendCard';

export type TReactChangeInput = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export const INCORPORATION_TYPES = [
  { value: EIncorporationType.EIRELI, label: 'EIRELI' },
  { value: EIncorporationType.LTDA, label: 'LTDA' },
  { value: EIncorporationType.MEI, label: 'MEI' },
  { value: EIncorporationType.SA, label: 'SA' },
  { value: EIncorporationType.SS, label: 'SS' },
];

const AboutStep = () => {
  const { onForward, onBack } = useContext(LegalPersonHandlerStepperContext);
  const [isCheckingBusiness, updateIsCheckingBusiness] = useState(false);
  const [isExistingBusinessModalVisible, toggleExistingBusinessModalVisible] =
    useState(false);
  const { setBusinessData } = useCustomer();
  const { getBusinessList } = useCustomerService();
  const [existsBusiness, updateExistsBusiness] =
    useState<TRegisterBusinessDataRequest | null>(null);
  const {
    state: { registerData },
  } = useCustomerContext();
  const formik = useFormik({
    initialValues: {
      tax_regime: registerData.tax_regime,
      taxpayer_id: registerData.taxpayer_id || '',
      legal_name: registerData.legal_name || '',
      name: registerData.name || '',
      phone: registerData.phone.number || '',
      email_address: registerData.email_address || '',
      monthly_revenue:
        formatCurrency(registerData.monthly_revenue, false) || '0,00',
      share_capital:
        formatCurrency(registerData.share_capital, false) || '0,00',
      foundation_date: registerData.foundation_date
        ? formatDateFromBackend(registerData.foundation_date).toISOString()
        : '',
      incorporation_type: registerData.incorporation_type || {
        label: '',
        value: undefined,
      },
      industry_classification: registerData.industry_classification || '',
    },
    validationSchema: aboutStepSchema,
    onSubmit: (values) => {
      setBusinessData({
        ...values,
        monthly_revenue: Number(formatToCurrencyFloat(values.monthly_revenue)),
        share_capital: Number(formatToCurrencyFloat(values.share_capital)),
        foundation_date: new Date(values.foundation_date).toISOString(),
        phone: {
          area_code: '',
          country_code: '',
          number: values.phone,
        },
      });
      onForward();
    },
  });

  const onChangeTaxpayerId = useCallback(
    async (taxpayerId: string): Promise<boolean> => {
      try {
        if (!isValidCNPJ(taxpayerId)) return false;
        updateIsCheckingBusiness(true);
        const business = await getBusinessList(
          0,
          'taxpayer_id',
          taxpayerId,
          undefined,
          true,
        );
        if (business && business.content.length > 0) {
          updateExistsBusiness(business.content[0]);
          toggleExistingBusinessModalVisible(true);
          return true;
        }
        updateExistsBusiness(null);
        return false;
      } catch {
        return false;
      } finally {
        updateIsCheckingBusiness(false);
      }
    },
    [],
  );

  const getFormatedPhoneFromExistingBusiness = useCallback(() => {
    if (existsBusiness) {
      const phoneNumber = existsBusiness?.phone?.number || '';
      const areaCodeNumber = existsBusiness?.phone?.area_code || '';

      if (!phoneNumber || !areaCodeNumber) return null;
      return formatPhone(areaCodeNumber + phoneNumber);
    }
    return null;
  }, [existsBusiness]);

  const handleMonthlyIncomeChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'monthly_revenue',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  const handleShareCapitalChange = useCallback((evt: TReactChangeInput) => {
    formik.setFieldValue(
      'share_capital',
      formatBRLCurrencyMaskInput(evt.target.value || ''),
    );
  }, []);

  return (
    <>
      <FormLegendCardWrapper>
        <FormLegendCard>
          <TextS>* - Campos obrigatórios</TextS>
        </FormLegendCard>
      </FormLegendCardWrapper>
      <StepContent
        title="Sobre"
        description="Informe os dados referentes a entidade"
      >
        <StepContentInputArea>
          <StepContentInput>
            <CustomInput
              name="taxpayer_id"
              id="taxpayer_id"
              isLoading={isCheckingBusiness}
              value={formatCNPJ(formik.values.taxpayer_id)}
              handleInputChange={(e) => {
                formik.handleChange(e);
                const taxpayerId = e?.currentTarget?.value;
                if (taxpayerId.length >= 18) {
                  onChangeTaxpayerId(taxpayerId.replace(/\D+/g, ''));
                }
              }}
              onBlur={formik.handleBlur}
              placeholder="00.000.000/0000-00"
              labelValue="CNPJ*"
              validationError={
                formik.touched.taxpayer_id ? formik.errors.taxpayer_id : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="legal_name"
              value={formik.values.legal_name}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: Empresa S.A."
              labelValue="Razão social*"
              validationError={
                formik.touched.legal_name ? formik.errors.legal_name : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="name"
              value={formik.values.name}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: Empresa XPTO"
              labelValue="Nome fantasia"
              validationError={formik.touched.name ? formik.errors.name : ''}
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="phone"
              value={formatPhone(formik.values.phone)}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="(00) 00000-0000"
              labelValue="Telefone*"
              validationError={formik.touched.phone ? formik.errors.phone : ''}
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="email_address"
              value={formik.values.email_address}
              handleInputChange={formik.handleChange}
              placeholder="exemplo@email.com"
              labelValue="E-mail*"
              validationError={
                formik.touched.email_address ? formik.errors.email_address : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CalendarInput
              name="foundation_date"
              labelValue="Data de Constituição*"
              placeholder="dd/mm/aaaa"
              value={formik.values.foundation_date}
              onChange={(e) =>
                formik.handleChange({
                  currentTarget: {
                    value: e,
                    name: 'foundation_date',
                  },
                })
              }
              validationError={
                formik.touched.foundation_date
                  ? formik.errors.foundation_date
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomCurrencyInput
              labelValue="Faturamento mensal"
              value={formik.values.monthly_revenue}
              handleInputChange={handleMonthlyIncomeChange}
              name="monthly_revenue"
              validationError={
                formik.touched.monthly_revenue
                  ? formik.errors.monthly_revenue
                  : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomCurrencyInput
              labelValue="Capital Social"
              value={formik.values.share_capital}
              handleInputChange={handleShareCapitalChange}
              name="share_capital"
              validationError={
                formik.touched.share_capital ? formik.errors.share_capital : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomSelect
              labelValue="Regime Fiscal"
              name="tax_regime"
              placeholder="Selecione o regime fiscal"
              value={formik.values.tax_regime}
              handleInputChange={formik.handleChange}
              options={[
                { value: ETaxRegime.SIMPLE, label: 'Simples Nacional' },
                { value: ETaxRegime.REAL_PROFIT, label: 'Lucro real' },
                { value: ETaxRegime.PRESUMED_PROFIT, label: 'Lucro presumido' },
              ]}
              validationError={
                formik.touched.tax_regime ? formik.errors.tax_regime : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomSelect
              labelValue="Formato Jurídico"
              name="incorporation_type"
              placeholder="Selecione o regime fiscal"
              value={formik.values.incorporation_type}
              handleInputChange={formik.handleChange}
              options={INCORPORATION_TYPES}
              validationError={
                formik.touched.tax_regime ? formik.errors.tax_regime : ''
              }
            />
          </StepContentInput>
          <StepContentInput>
            <CustomInput
              name="industry_classification"
              value={formik.values.industry_classification}
              handleInputChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Ex.: Comércio de roupas e acessórios"
              labelValue="Classificação da indústria"
              validationError={
                formik.touched.industry_classification
                  ? formik.errors.industry_classification
                  : ''
              }
            />
          </StepContentInput>
        </StepContentInputArea>
        <ConfirmModal
          isColumnButtons={true}
          isOpen={isExistingBusinessModalVisible}
          icon={<AttentionCircularIcon />}
          title="O CNPJ informado já está cadastrado"
          btnConfirmText="Limpar e informar outro CNPJ"
          handleConfirm={() => {
            formik.setFieldValue('taxpayer_id', '');
            formik.setFieldTouched('taxpayer_id', false);
            updateExistsBusiness(null);
            toggleExistingBusinessModalVisible(false);
            setTimeout(() => {
              document.querySelector<HTMLInputElement>('#taxpayer_id')?.focus();
            });
          }}
          handleClose={() => toggleExistingBusinessModalVisible(false)}
          handleCancel={() => toggleExistingBusinessModalVisible(false)}
        >
          <AboutStepExistingBusiness>
            <span style={{ marginBottom: '8px' }}>
              <strong>{formatCNPJ(existsBusiness?.taxpayer_id || '')}</strong>
            </span>
            <span>{existsBusiness?.legal_name}</span>
            {getFormatedPhoneFromExistingBusiness() && (
              <span>{getFormatedPhoneFromExistingBusiness()}</span>
            )}
            <span>{existsBusiness?.email_address}</span>
            <span>...</span>
            <a
              href={`#/${routingPath.records.legal}/${existsBusiness?.id}`}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Abrir perfil completo em uma nova aba
            </a>
          </AboutStepExistingBusiness>
        </ConfirmModal>
        <StepContentButtonsArea>
          <ButtonDefault margin={true} onClick={() => onBack()}>
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary
            onClick={() => {
              if (existsBusiness !== null) {
                toggleExistingBusinessModalVisible(true);
              } else {
                formik.submitForm();
              }
            }}
          >
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </StepContent>
    </>
  );
};

export default AboutStep;
