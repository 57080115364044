import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { useBreadcrumbsContext } from '../../../../app/context';
import {
  ContentHeader,
  HeaderButtons,
  OperationsMainContainer,
  PendingCount,
  TabsArea,
} from './styles';
import { CustomTabs } from 'app/components/CustomTabs';
import { routingPath } from 'app/routes';
import { WarningIcon } from 'app/components/Icons';
import { EPermission } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import { useParams } from 'react-router-dom';
import {
  ApplicationContent,
  PendingApplicationsStatus,
} from './components/ApplicationContent';
import { Heading3 } from 'app/components';
import { useApplicationsService } from 'modules/products/services/hooks';

const renderContent = (tabName: string) => {
  switch (tabName) {
    case 'applications':
      return <ApplicationContent />;
    case 'pending':
      return <ApplicationContent pending={true} />;
    default:
      return <ApplicationContent />;
  }
};

const OperationsMain = () => {
  const theme = useTheme();
  const params = useParams();
  const { isAuthorized } = usePermify();
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const [totalPending, updateTotalPending] = useState(0);
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { getApplications } = useApplicationsService();

  useEffect(() => {
    setItems(
      [{ to: '/operations', label: 'Operações' }],
      <ReceiptLongOutlinedIcon />,
    );
    getApplications(0, 1, undefined, PendingApplicationsStatus).then((res) => {
      if (res) {
        updateTotalPending(res.total_elements ?? 0);
      }
    });
    return resetBreadcrumbs;
  }, []);

  const tabItems = [
    {
      title: 'Todas as operações',
      route: '/applications',
      isEnabled: true,
      icon: (
        <ReceiptLongOutlinedIcon
          style={{ color: theme.palette.brand.primary.base as string }}
        />
      ),
      permission: EPermission.PRODUCTS_APPLICATIONS,
    },
    {
      title: (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          Operações pendentes{' '}
          {totalPending > 0 && <PendingCount>{totalPending}</PendingCount>}
        </div>
      ),
      route: '/pending',
      isEnabled: true,
      icon: <WarningIcon color={theme.palette.brand.primary.base as string} />,
      permission: EPermission.PRODUCTS_APPLICATIONS,
    },
  ];

  return (
    <OperationsMainContainer>
      <ContentHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Heading3 fontWeight="Sb">Operações</Heading3>
          </div>
          <HeaderButtons>
            {/* <ButtonPrimary
              style={{ backgroundColor: 'transparent' }}
              typeVariant="outline"
              btnIcon={
                <AddIcon color={theme.palette.brand.primary.base as string} />
              }
              iconPosition="right"
              onClick={() => {
                console.log('Gerar Relatório');
              }}
            >
              <TextM
                weight="Semibold"
                style={{
                  marginRight: '11px',
                  color: theme.palette.brand.primary.base,
                }}
              >
                Gerar Relatório
              </TextM>
            </ButtonPrimary> */}
          </HeaderButtons>
        </div>
        <TabsArea>
          <CustomTabs
            value={currentTabValue}
            route={routingPath.operations.main}
            variant="scrollable"
            items={tabItems
              .filter((tab) => isAuthorized(tab.permission) && tab.isEnabled)
              .map((tab) => ({
                title: tab.title,
                icon: tab.icon,
                route: tab.route,
              }))}
            handleChange={(value) => setCurrentTabValue(value)}
            showBorder={false}
          />
        </TabsArea>
      </ContentHeader>
      {renderContent(params.tabRoute || 'applications')}
    </OperationsMainContainer>
  );
};

export default OperationsMain;
