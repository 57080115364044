import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InputLabel } from '@mui/material';

export const CustomCurrencyInputContainer = styled.div<{
  withSelect?: boolean;
}>`
  width: 100%;
  height: 100%;
  
  .MuiInputBase-root {
    width: 100%;
    color: ${({ theme }) => theme.palette?.brand?.primary.base};
    
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
    
    fieldset {
      border: 2px solid;
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
      border-radius: 10px;
      ${({ withSelect }) =>
        withSelect
          ? css`
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right: none;
            `
          : ''}
    }
  }

  .MuiInputBase-root:hover {
    fieldset {
      border-color: ${({ theme }) => theme.palette?.brand?.gray[300]};
    }
  }

  .MuiInputBase-root:focus-within {
    fieldset {
      border-color: ${({ theme, withSelect }) =>
        withSelect
          ? theme.palette?.brand?.gray[300]
          : theme.palette?.brand?.primary.base};
    }
  }

  
`;

export const LineItem = styled.div<{ disabled?: boolean }>`
  position: absolute;
  /* background-color: ${({ theme }) =>
    theme.palette?.brand?.neutral?.light2}; */
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.palette?.brand?.neutral?.light2
      : theme.palette?.brand.primary.base};
  height: 40%;
  width: 4px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  left: 2px;
`;

export const InputLabelContainer = styled(InputLabel)`
  color: ${({ theme }) => theme.palette?.brand?.secondary.base};
  font-weight: 600;
  font-size: 14px;
  font-family: 'Inter';
`;

export const TextFieldArea = styled.div`
  margin-top: 10px;
  position: relative;

  input {
    &::placeholder{
      font-weight: 500;
      font-size: 14px;
      font-family: 'Inter';
      color: #C3C1CA;
    }
    padding-left: 35px;
  }

  p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette?.brand.primary.base}
  }
`;
