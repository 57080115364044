import { TextM } from '../';
import { BilletsInfo } from 'modules/products/interfaces/billets';
import {
  ErrorList,
  ErrorListArea,
  ErrorListAreaIcon,
  ErrorListItemsList,
  ErrorListItemsListItem,
} from '../ErrorList';
import { ErrorListCircularIcon } from 'app/components/Icons';

type BillPaymentErrorsProps = {
  errorBillets: BilletsInfo[];
};
const BillPaymentErrors = ({ errorBillets }: BillPaymentErrorsProps) => {
  return (
    <>
      {errorBillets.length > 0 && (
        <ErrorListArea>
          <ErrorListAreaIcon>
            <ErrorListCircularIcon />
          </ErrorListAreaIcon>
          <ErrorList>
            <TextM weight="Bold" style={{ color: '#220B64' }}>
              {`Erro na consulta de ${errorBillets.length} ${
                errorBillets.length > 1 ? 'boletos' : 'boleto'
              }`}
            </TextM>
            <ErrorListItemsList>
              {errorBillets.map((errorBillet) => {
                return (
                  <ErrorListItemsListItem
                    key={errorBillet.request.digitable_or_bar_code as string}
                  >
                    <strong>{`Boleto ${errorBillet.request.digitable_or_bar_code}:`}</strong>
                    : {errorBillet.error_message}
                  </ErrorListItemsListItem>
                );
              })}
            </ErrorListItemsList>
          </ErrorList>
        </ErrorListArea>
      )}
    </>
  );
};

export default BillPaymentErrors;
