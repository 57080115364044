import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TBoxVariant } from './BoxSection';

export interface IBoxSectionContainerProps {
  level?: 'content' | 'wrapper';
  boxVariant?: TBoxVariant;
}

export const BoxSectionContainer = styled.section<IBoxSectionContainerProps>`
  background-color: ${({ theme }) => theme.palette?.brand.neutral.base};
  box-shadow: 0px -1px 0px -6px rgba(0, 0, 0, 0.04), 0px 4px 12px -4px rgba(0, 0, 0, 0.04);
  /* border-radius: 16px; */
  padding: ${({ level }) => (level === 'wrapper' ? '48px' : '24px')} ;
  min-height: 295px;
  position: relative;
  border-bottom: 1px solid #DBD6FE;
  border-left: 1px solid #DBD6FE;
  border-right: 1px solid #DBD6FE;
  border-radius: 0px 0px 16px 16px;

  ${({ boxVariant }) =>
    boxVariant === 'rounded' &&
    css`
    border-radius: 20px;
    border-top: 1px solid #DBD6FE;
  `}

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.tablet + 'px'}){
      padding: 20px;
  };
  
`;
