import { useEffect, useState } from 'react';
import { AccountBalanceOutlined } from '@mui/icons-material';
import { useBreadcrumbsContext } from '../../../../app/context';
import { ProductsDetailsContent } from './components/ProductsDetailsContent';
import { ProductDetailContainer } from './styles';
import { useParams } from 'react-router-dom';
import { useProductsService } from '../../services/hooks';
import { TProducts } from '../../context';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

const ProductDetail = () => {
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const { getProduct } = useProductsService();
  const params = useParams();
  const [productData, setProductsData] = useState<TProducts>();
  const { setSelectedOriginator } = useOriginatorContext();

  const fetchProduct = async (productId: string) => {
    const res = await getProduct(productId);
    setProductsData(res?.data);
  };

  useEffect(() => {
    setSelectedOriginator(null);

    const productId = params.id;
    if (productId) {
      setTimeout(() => fetchProduct(productId));
    }
  }, []);

  useEffect(() => {
    setItems(
      [
        { to: '/products/main', label: 'Produtos' },
        {
          to: `/products/detail/${productData?.id}/applications?page=0`,
          label: productData?.name || '',
        },
      ],
      <AccountBalanceOutlined />,
    );

    return () => resetBreadcrumbs();
  }, [productData]);

  return (
    <ProductDetailContainer>
      <ProductsDetailsContent productData={productData ? productData : null} />
    </ProductDetailContainer>
  );
};

export default ProductDetail;
