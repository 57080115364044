import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Heading3,
} from 'app/components';
import { TProducts, useProductsContext } from 'modules/products/context';
import { useProductsService } from 'modules/products/services/hooks';
import { useCallback, useEffect, useState, useContext } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { ListBox, ListBoxItem, ListBoxItemTitle } from '../components';
import { StepContentButtonsArea } from '../styles';
import { ProductStepContent } from './styles';
import { useLocation } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useAppContext } from 'app/context/AppContextProvider';

const ProductStep = () => {
  const location = useLocation();
  const {
    product,
    borrowerData,
    hasMultiplesFundings,
    onBack,
    updateProduct,
    onForward,
  } = useContext(ApplicationRequestContext);
  const [productList, setProductList] = useState<TProducts[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<TProducts | null>(
    product,
  );
  const { getProducts } = useProductsService();
  const { is_issuer_app } = useAppContext();
  const {
    state: { content, has_next },
  } = useProductsContext();

  const onSearch = useCallback(
    (field: string, value: string) => {
      if (value === '') {
        setProductList(content);
        return;
      }

      const filteredProducts = content.filter((item: TProducts) => {
        if (
          item[field as keyof TProducts]
            ?.toString()
            ?.toLowerCase()
            ?.includes(value?.toLocaleLowerCase())
        ) {
          return item;
        }
      });

      setProductList(filteredProducts);
    },
    [content],
  );

  const handleGetProducts = useCallback(async () => {
    await getProducts();
  }, []);

  useEffect(() => {
    handleGetProducts();
  }, []);

  useEffect(() => {
    if (content) {
      setProductList(content);
      if (typeof location.state?.product === 'object') {
        setSelectedProduct(location.state?.product);
        history.replaceState({}, document.title);
      }
    }
  }, [content]);

  useEffect(() => {
    if (selectedProduct?.id !== product?.id) {
      setSelectedProduct(product);
    }
  }, [product]);

  return (
    <BoxSection boxVariant="rounded">
      <ProductStepContent>
        <Heading3>
          Qual o <strong>produto </strong>
          dessa solicitação?
        </Heading3>
        <ListBox
          filterItems={[
            { name: 'Nome', parameterName: 'name' },
            { name: 'Descrição', parameterName: 'description' },
          ]}
          hasMoreItems={has_next}
          items={productList?.filter(
            (productListItem) =>
              !borrowerData ||
              (productListItem.borrower_type === 'PERSON' &&
                borrowerData.taxpayer_id.length === 11) ||
              (productListItem.borrower_type === 'BUSINESS' &&
                borrowerData.taxpayer_id.length > 11),
          )}
          onSearch={onSearch}
        >
          {(i) => {
            const item = i as TProducts;
            return (
              <ListBoxItem
                className={
                  selectedProduct?.name === item.name ? 'selected' : ''
                }
                onClick={() => setSelectedProduct(item)}
                key={item.name}
              >
                <ListBoxItemTitle>{item.name}</ListBoxItemTitle>
                <span>{item.description}</span>
              </ListBoxItem>
            );
          }}
        </ListBox>
        <StepContentButtonsArea>
          {(hasMultiplesFundings || is_issuer_app) && (
            <ButtonDefault margin={true} onClick={() => onBack()}>
              <ArrowBack />
            </ButtonDefault>
          )}
          <ButtonPrimary
            disabled={!selectedProduct}
            onClick={() => {
              if (selectedProduct) {
                updateProduct(selectedProduct);
                setTimeout(() => onForward());
              }
            }}
          >
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </ProductStepContent>
    </BoxSection>
  );
};

export default ProductStep;
