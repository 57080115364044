import { useContext } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Heading3,
} from '../../../../../app/components';
import { ArrowBack } from '@mui/icons-material';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { StepContent, QRCodeButtonsArea } from './styles';
import { QRCodeDetails, QRCodeQuery } from 'app/components/QRCode';

const QRCodeStep = () => {
  const {
    qrcodeEmvValidationResponse,
    updateQrcodeEmvValidationResponse,
    onBack,
    onForward,
  } = useContext(ApplicationRequestContext);

  const onSubmit = () => {
    onForward();
  };

  return (
    <BoxSection boxVariant="rounded">
      <Heading3>Informe o Pix copia e cola para desembolso</Heading3>
      <StepContent>
        <QRCodeQuery
          qrcodeEmv={qrcodeEmvValidationResponse}
          onValidateQRCode={updateQrcodeEmvValidationResponse}
        />
        {qrcodeEmvValidationResponse && (
          <QRCodeDetails
            qrcodeEmvResponse={qrcodeEmvValidationResponse}
            onRemove={() => updateQrcodeEmvValidationResponse(null)}
          />
        )}
      </StepContent>
      <QRCodeButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>

        <ButtonPrimary
          disabled={!qrcodeEmvValidationResponse}
          onClick={onSubmit}
        >
          Avançar
        </ButtonPrimary>
      </QRCodeButtonsArea>
    </BoxSection>
  );
};

export default QRCodeStep;
