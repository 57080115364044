const QRCodeDiscountIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.72548 3.547C7.99881 0.864499 12.8363 1.05117 15.8921 4.107C19.1463 7.36117 19.1463 12.6378 15.8921 15.892C12.638 19.1462 7.36131 19.1462 4.10715 15.892C3.1085 14.8947 2.37971 13.66 1.9892 12.3038C1.59868 10.9475 1.55927 9.51431 1.87465 8.13867L1.93881 7.87783L3.55048 8.30283C3.15093 9.82138 3.30022 11.4322 3.97204 12.8515C4.64386 14.2707 5.79509 15.4073 7.22284 16.0609C8.65059 16.7145 10.2632 16.8431 11.7765 16.4241C13.2898 16.0052 14.6067 15.0655 15.495 13.7707C16.3833 12.4759 16.7861 10.9091 16.6323 9.34638C16.4786 7.78369 15.7782 6.32543 14.6545 5.22859C13.5309 4.13174 12.0562 3.46672 10.4902 3.35072C8.92427 3.23472 7.36764 3.67519 6.09465 4.5945L5.91048 4.73283L6.75798 5.58033L2.92798 6.46367L3.81131 2.63367L4.72548 3.547ZM10.833 4.9995V6.66617H12.9163V8.33283H8.33298C8.22886 8.33264 8.12844 8.37144 8.05149 8.44159C7.97455 8.51174 7.92666 8.60815 7.91725 8.71185C7.90784 8.81555 7.9376 8.91901 8.00066 9.00186C8.06373 9.08471 8.15552 9.14095 8.25798 9.1595L8.33298 9.16617H11.6663C12.2188 9.16617 12.7488 9.38566 13.1395 9.77636C13.5302 10.1671 13.7496 10.697 13.7496 11.2495C13.7496 11.802 13.5302 12.3319 13.1395 12.7226C12.7488 13.1133 12.2188 13.3328 11.6663 13.3328H10.833V14.9995H9.16631V13.3328H7.08298V11.6662H11.6663C11.7704 11.6664 11.8709 11.6276 11.9478 11.5574C12.0247 11.4873 12.0726 11.3908 12.082 11.2871C12.0915 11.1835 12.0617 11.08 11.9986 10.9971C11.9356 10.9143 11.8438 10.8581 11.7413 10.8395L11.6663 10.8328H8.33298C7.78044 10.8328 7.25054 10.6133 6.85984 10.2226C6.46914 9.83194 6.24965 9.30203 6.24965 8.7495C6.24965 8.19697 6.46914 7.66706 6.85984 7.27636C7.25054 6.88566 7.78044 6.66617 8.33298 6.66617H9.16631V4.9995H10.833Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeDiscountIcon;
