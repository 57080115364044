const QRCodeInterestIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13752_216515)">
        <path
          d="M18.2099 8.56716C18.2906 9.03275 18.3327 9.5116 18.3327 10.0003C18.3327 14.6028 14.6018 18.3336 9.99935 18.3336C5.39685 18.3336 1.66602 14.6028 1.66602 10.0003C1.66602 5.39777 5.39685 1.66694 9.99935 1.66694C10.4882 1.66694 10.9673 1.70903 11.433 1.78979C11.1672 2.28069 10.9811 2.8211 10.8922 3.39364C10.5979 3.35387 10.2997 3.33361 9.99935 3.33361C8.23124 3.33361 6.53555 4.03599 5.2853 5.28623C4.03506 6.53647 3.33268 8.23216 3.33268 10.0003C3.33268 11.7684 4.03506 13.4641 5.2853 14.7143C6.53555 15.9646 8.23124 16.6669 9.99935 16.6669C11.7675 16.6669 13.4632 15.9646 14.7134 14.7143C15.9636 13.4641 16.666 11.7684 16.666 10.0003C16.666 9.70012 16.6458 9.40205 16.606 9.1079C17.1786 9.019 17.719 8.83296 18.2099 8.56716Z"
          fill={color}
        />
        <path
          d="M12.916 8.22897V8.33361H8.33268C8.22218 8.33361 8.11619 8.37751 8.03805 8.45564C7.95991 8.53378 7.91602 8.63977 7.91602 8.75027C7.91602 8.86078 7.95991 8.96676 8.03805 9.0449C8.11619 9.12304 8.22218 9.16694 8.33268 9.16694H11.666C12.2186 9.16694 12.7485 9.38643 13.1392 9.77713C13.5299 10.1678 13.7493 10.6977 13.7493 11.2503C13.7493 11.8028 13.5299 12.3327 13.1392 12.7234C12.7485 13.1141 12.2186 13.3336 11.666 13.3336H10.8327V15.0003H9.16602V13.3336H7.08268V11.6669H11.666C11.7765 11.6669 11.8825 11.623 11.9606 11.5449C12.0388 11.4668 12.0827 11.3608 12.0827 11.2503C12.0827 11.1398 12.0388 11.0338 11.9606 10.9556C11.8825 10.8775 11.7765 10.8336 11.666 10.8336H8.33268C7.78015 10.8336 7.25024 10.6141 6.85954 10.2234C6.46884 9.83271 6.24935 9.30281 6.24935 8.75027C6.24935 8.19774 6.46884 7.66783 6.85954 7.27713C7.25024 6.88643 7.78015 6.66694 8.33268 6.66694H9.16602V5.00027H10.8327V6.66694H11.5014C11.8574 7.28263 12.341 7.81534 12.916 8.22897Z"
          fill={color}
        />
        <path
          d="M15.8327 8.33333C13.5315 8.33333 11.666 6.46783 11.666 4.16667C11.666 1.86548 13.5315 0 15.8327 0C18.1339 0 19.9993 1.86548 19.9993 4.16667C19.9993 6.46783 18.1339 8.33333 15.8327 8.33333ZM16.2493 3.95467V2.08333H14.9993V5.20467H17.916V3.95467H16.2493Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_13752_216515">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QRCodeInterestIcon;
