const QRCodeAddressIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17.2047L14.1248 13.0798C16.4028 10.8018 16.4028 7.10834 14.1248 4.83029C11.8468 2.55223 8.15327 2.55223 5.87521 4.83029C3.59715 7.10834 3.59715 10.8018 5.87521 13.0798L10 17.2047ZM10 19.5617L4.6967 14.2584C1.76777 11.3294 1.76777 6.58071 4.6967 3.65178C7.62563 0.722845 12.3743 0.722845 15.3033 3.65178C18.2323 6.58071 18.2323 11.3294 15.3033 14.2584L10 19.5617ZM10 10.6217C10.9205 10.6217 11.6667 9.87558 11.6667 8.95508C11.6667 8.0346 10.9205 7.28841 10 7.28841C9.0795 7.28841 8.33333 8.0346 8.33333 8.95508C8.33333 9.87558 9.0795 10.6217 10 10.6217ZM10 12.2884C8.15905 12.2884 6.66667 10.796 6.66667 8.95508C6.66667 7.11413 8.15905 5.62174 10 5.62174C11.8409 5.62174 13.3333 7.11413 13.3333 8.95508C13.3333 10.796 11.8409 12.2884 10 12.2884Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeAddressIcon;
