import { BoxSection, ButtonPrimary, Heading3 } from 'app/components';
import { useEffect, useState, useContext } from 'react';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { ListBox, ListBoxItem, ListBoxItemTitle } from '../components';
import { StepContentButtonsArea } from '../styles';
import { OriginatorStepContent } from './styles';
import { formatCNPJ } from 'app/helpers';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import { OriginatorType } from 'modules/common/OriginatorProvider/types';

const OriginatorStep = () => {
  const { originators } = useOriginatorContext();
  const [searchText, updateSearchText] = useState('');
  const { originator, updateOriginator, onForward } = useContext(
    ApplicationRequestContext,
  );
  const [selectedOriginator, setSelectedOriginator] = useState<string | null>();

  useEffect(() => {
    if (!selectedOriginator || originator !== selectedOriginator) {
      setSelectedOriginator(originator);
    }
  }, [originator]);

  return (
    <BoxSection boxVariant="rounded">
      <OriginatorStepContent>
        <Heading3>
          Qual o <strong>originador </strong>
          dessa solicitação?
        </Heading3>
        <ListBox
          filterItems={[{ name: 'Nome', parameterName: 'legal_name' }]}
          hasMoreItems={false}
          items={originators?.filter(
            (originatorItem) =>
              !searchText ||
              originatorItem.legal_name
                .toLowerCase()
                .includes(searchText.toLowerCase()),
          )}
          onSearch={(field, value) => updateSearchText(value)}
        >
          {(i) => {
            const item = i as OriginatorType;
            return (
              <ListBoxItem
                className={selectedOriginator === item.id ? 'selected' : ''}
                onClick={() => setSelectedOriginator(item.id)}
                key={item.legal_name}
              >
                <ListBoxItemTitle>{item.legal_name}</ListBoxItemTitle>
                <span>{formatCNPJ(item.taxpayer_id)}</span>
              </ListBoxItem>
            );
          }}
        </ListBox>
        <StepContentButtonsArea>
          <ButtonPrimary
            disabled={!selectedOriginator}
            onClick={() => {
              if (selectedOriginator) {
                updateOriginator(selectedOriginator);
                setTimeout(() => onForward());
              }
            }}
          >
            Avançar
          </ButtonPrimary>
        </StepContentButtonsArea>
      </OriginatorStepContent>
    </BoxSection>
  );
};

export default OriginatorStep;
