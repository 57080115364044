import styled from '@emotion/styled';

export const BillPaymentInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  .MuiFormLabel-root + div > div:first-of-type {
    background-color: ${({ theme }) => theme.palette?.brand?.primary?.base};
  }
`;

export const StepContent = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 32px;
  margin-top: 32px;
  border: 1px solid #E6E5EA;
  border-radius: 15px;
  padding: 24px;
`;

export const BillPaymentInputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const BillPaymentButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    width: auto;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 32px;
  
  th:nth-of-type(1) {
    width: 3%;
  }
  td:nth-of-type(1) {
    width: 3%;
  }

  th:nth-of-type(2) {
    width: 87%;
  }
  td:nth-of-type(2) {
    width: 87%;
  }

  th:nth-of-type(3) {
    width: 8%;
  }
  td:nth-of-type(3) {
    width: 8%;
  }
`;

export const TableDetailsWrapper = styled.div`
  margin-top: 1rem;
  
`;
