import { useProductsContext } from '../../../../context';
import { useNavigate } from 'react-router-dom';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { ButtonPrimary, Table, TextL, TextS } from 'app/components';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import EyeIcon from 'app/components/Icons/EyeIcon';
import { useTheme } from '@mui/material';
import { formatCurrency } from 'app/utils/normalizer';

const tableHeadContent = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Produto</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Descrição</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Número de operações</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Valor total movimentado</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: <div>{null}</div>,
  },
];

type ProductTableProps = {
  searchText: string;
};
const ProductTable = ({ searchText }: ProductTableProps) => {
  const navigate = useNavigate();
  const {
    state: { content },
  } = useProductsContext();
  const theme = useTheme();
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );

  const handleProductCardClick = (id: string) => {
    navigate(`/products/detail/${id}/applications?page=0`);
  };

  return (
    <Table
      showPagination
      handlePageChange={(selectedPage) => {
        setCurrentPage(selectedPage - 1);
      }}
      handleLinesPerPage={(selectedLinesPerPage) => {
        setCurrentPage(0);
        setLinesPerPage(selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage);
      }}
      totalElementsQty={content.length}
      currentPage={currentPage + 1}
      linesPerPage={linesPerPage}
      tableHeadCell={tableHeadContent}
      tableBodyContent={content
        ?.filter((item) => {
          return (
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description?.toLowerCase()?.includes(searchText.toLowerCase())
          );
        })
        ?.slice(
          currentPage * linesPerPage,
          currentPage * linesPerPage + linesPerPage,
        )
        .map((item) => {
          return {
            id: '1',
            cells: [
              {
                id: '1',
                content: (
                  <TableBodyCellContainer style={{ maxWidth: '280px' }}>
                    <TextS>{item?.name || ''}</TextS>
                  </TableBodyCellContainer>
                ),
              },
              {
                id: '2',
                content: (
                  <TableBodyCellContainer>
                    <TextS>{item?.description || ''}</TextS>
                  </TableBodyCellContainer>
                ),
              },
              {
                id: '3',
                content: (
                  <TableBodyCellContainer>
                    <TextS>
                      {typeof item?.total_applications === 'number'
                        ? item?.total_applications
                        : '0'}
                    </TextS>
                  </TableBodyCellContainer>
                ),
              },
              {
                id: '4',
                content: (
                  <TableBodyCellContainer>
                    <TextS>
                      {typeof item?.total_amount_applications === 'number'
                        ? formatCurrency(item?.total_amount_applications)
                        : 'R$ 0,00'}
                    </TextS>
                  </TableBodyCellContainer>
                ),
              },
              {
                id: '5',
                content: (
                  <TableBodyCellContainer
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0px',
                    }}
                  >
                    <ButtonPrimary
                      typeVariant="ghost"
                      style={{ width: 'fit-content' }}
                      onClick={() => handleProductCardClick(item.id)}
                    >
                      <EyeIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                    </ButtonPrimary>
                  </TableBodyCellContainer>
                ),
              },
            ],
          };
        })}
    />
  );
};

export default ProductTable;
