import { useContext } from 'react';
import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  Heading3,
} from '../../../../../app/components';
import { ArrowBack } from '@mui/icons-material';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { StepContent, BillPaymentButtonsArea } from './styles';
import { ValidateBilletsResponse } from 'modules/products/interfaces/billets';
import { getBilletNumber } from 'modules/products/helpers/billets';
import {
  BillPaymentDetails,
  BillPaymentQuery,
} from 'app/components/BillPayment';
import BillPaymentErrors from 'app/components/BillPayment/BillPaymentErrors';

const BillPaymentStep = () => {
  const {
    billetValidationResponse,
    updateBilletValidationResponse,
    onBack,
    onForward,
  } = useContext(ApplicationRequestContext);

  const onSubmit = () => {
    onForward();
  };

  const onValidateBillets = (billetsResponse: ValidateBilletsResponse) => {
    updateBilletValidationResponse(billetsResponse);
  };

  const successBillets =
    billetValidationResponse?.billets_info?.filter(
      (billet) => billet.succeeded,
    ) ?? [];

  const errorBillets =
    billetValidationResponse?.billets_info?.filter(
      (billet) => !billet.succeeded,
    ) ?? [];

  return (
    <BoxSection boxVariant="rounded">
      <Heading3>Informe os boletos para desembolso</Heading3>
      <StepContent>
        <BillPaymentQuery
          billets={
            successBillets.map((billet) => getBilletNumber(billet.request)) ??
            []
          }
          onValidateBillets={onValidateBillets}
        />
        <BillPaymentDetails
          billets={successBillets}
          total={billetValidationResponse?.total_value ?? 0}
          onRemoveBillet={(billet) => {
            updateBilletValidationResponse({
              total_value:
                billetValidationResponse!.total_value - billet.result.amount,
              billets_info:
                billetValidationResponse?.billets_info?.filter(
                  (b) =>
                    b.request.digitable_or_bar_code !==
                    billet.request.digitable_or_bar_code,
                ) ?? [],
            });
          }}
        />
        <BillPaymentErrors errorBillets={errorBillets} />
      </StepContent>
      <BillPaymentButtonsArea>
        <ButtonDefault margin={true} onClick={() => onBack()}>
          <ArrowBack />
        </ButtonDefault>

        <ButtonPrimary
          disabled={successBillets.length === 0}
          onClick={onSubmit}
        >
          Avançar
        </ButtonPrimary>
      </BillPaymentButtonsArea>
    </BoxSection>
  );
};

export default BillPaymentStep;
