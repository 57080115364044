import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import { OriginatorContextType, OriginatorType } from './types';
import { ORIGINATOR_COOKIE } from './constants';
import { useAppContext } from 'app/context/AppContextProvider';
import { OriginatorServiceHttpService } from './OriginatorService';
import { useAuthContext } from 'modules/auth/context';

export const OriginatorContext = createContext<OriginatorContextType>({
  originators: [],
  selectedOriginator: null,
  setSelectedOriginator: () => {
    throw new Error('Método não implementado');
  },
});

export interface IOriginatorProviderProps {
  children?: React.ReactNode;
}

export const OriginatorProvider = ({ children }: IOriginatorProviderProps) => {
  const { is_issuer_app } = useAppContext();
  const { isLogged } = useAuthContext();
  const [originators, setOriginators] = useState<OriginatorType[]>([]);
  const [selectedOriginator, setSelectedOriginator] = useState<null | string>(
    null,
  );

  const onSelectOriginator = useCallback(
    (originator: string | null) => {
      if (is_issuer_app) {
        if (originator === null) {
          Cookies.remove(ORIGINATOR_COOKIE);
        } else {
          Cookies.set(ORIGINATOR_COOKIE, originator);
        }
        setSelectedOriginator(originator);
      }
    },
    [is_issuer_app],
  );

  useEffect(() => {
    if (is_issuer_app && isLogged) {
      const originatorService = new OriginatorServiceHttpService();
      originatorService.getOriginators().then((res) => {
        setOriginators(res.content);
      });
    }
  }, [is_issuer_app, isLogged]);

  return (
    <OriginatorContext.Provider
      value={{
        originators,
        selectedOriginator,
        setSelectedOriginator: onSelectOriginator,
      }}
    >
      {children}
    </OriginatorContext.Provider>
  );
};

export const useOriginatorContext = () => {
  const context = useContext(OriginatorContext);

  if (context === undefined) {
    throw new Error(
      'useOriginatorContext must be used within a OriginatorProvider',
    );
  }
  return context;
};
