import {
  createContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useBreadcrumbsContext } from 'app/context';
import {
  QualificationRequestContainer,
  QualificationRequestStepContainer,
  QualificationRequestStepperContainer,
} from './styles';
import { Stepper } from 'app/components';
import {
  ProductStep,
  BorrowerStep,
  CustomVariablesStep,
  DocumentsStep,
  ConditionsStep,
  FundingStep,
} from './steps';
import { Rule } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Condition,
  EFunctions,
  TQualificationRequest,
} from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { TProducts } from 'modules/products/context';
import {
  EQualificationError,
  TCustomVariableValue,
  TQualificationBorrower,
} from './types';
import { IBreadcrumbsItem } from 'app/context/BreadcrumbsProvider/BreadcrumbsProvider';
import {
  useCustomerService,
  usePersonService,
} from 'modules/customer/services';
import {
  useProductsService,
  useQualificationsService,
} from 'modules/products/services/hooks';
import { TQualification } from 'modules/customer/interfaces/qualifications';
import { ConfirmModal } from 'app/components';
import { useSnackbar } from 'app/hooks/useSnackbar';
import { useTheme } from '@mui/material';
import { useAuthContext, usePermify } from 'modules/auth/context';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { BorrowerTypeModal } from './components/BorrowerTypeModal';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';
import { useAppContext } from 'app/context/AppContextProvider';
import OriginatorStep from './steps/OriginatorStep';
import { StepItem } from 'app/components/Stepper/Stepper';

type IQualificationRequestStepperContext = {
  activeStep: number;
  onForward: () => void;
  onBack: () => void;

  qualificationData: TQualificationRequest;
  onChangeQualificationData: (
    qualificationData: Partial<TQualificationRequest>,
  ) => void;

  productData: TProducts | null;
  onChangeProductData: (productData: TProducts | null) => void;

  borrowerData: TQualificationBorrower | null;
  onChangeBorrowerData: (borrowerData: TQualificationBorrower | null) => void;

  conditionData: Condition | null;
  onChangeConditionData: (conditionData: Condition | null) => void;

  isSavingBorrower: boolean;
  onSaveBorrower: (
    customVariablesArray: TCustomVariableValue[],
  ) => Promise<boolean>;

  isCreatingQualification: boolean;
  onCreateQualification: (
    skipCondition: boolean,
  ) => Promise<TQualification | EQualificationError>;

  onStartCreateQualififcation: (skipCondition: boolean) => void;

  borrowerType: EFunctions | null;
  onChangeBorrowerType: (borrowerType: EFunctions | null) => void;
  onSelectBorrowerType: () => void;

  funding: string | null;
  hasMultiplesFundings: boolean;
  updateFunding: (funding: string) => void;

  isBusiness: boolean;
  isPerson: boolean;
  isForEmployer: boolean;

  isOriginatorStepVisible: boolean;
  originator: string | null;
  updateOriginator: (originator: string | null) => void;
};

export const QualificationRequestStepperContext =
  createContext<IQualificationRequestStepperContext>({
    activeStep: 1,
    onForward: () => {
      throw new Error('Método não implementado');
    },
    onBack: () => {
      throw new Error('Método não implementado');
    },

    qualificationData: {} as TQualificationRequest,
    onChangeQualificationData: () => {
      throw new Error('Método não implementado');
    },

    productData: null,
    onChangeProductData: () => {
      throw new Error('Método não implementado');
    },

    borrowerData: null,
    onChangeBorrowerData: () => {
      throw new Error('Método não implementado');
    },

    conditionData: null,
    onChangeConditionData: () => {
      throw new Error('Método não implementado');
    },

    isSavingBorrower: false,
    onSaveBorrower: () => {
      throw new Error('Método não implementado');
    },

    isCreatingQualification: false,
    onCreateQualification: () => {
      throw new Error('Método não implementado');
    },

    onStartCreateQualififcation: () => {
      throw new Error('Método não implementado');
    },

    borrowerType: null,
    onChangeBorrowerType: () => {
      throw new Error('Método não implementado');
    },
    onSelectBorrowerType: () => {
      throw new Error('Método não implementado');
    },

    funding: null,
    hasMultiplesFundings: false,
    updateFunding: () => {
      throw new Error('Método não implementado');
    },

    isBusiness: false,
    isPerson: false,
    isForEmployer: false,

    isOriginatorStepVisible: false,
    originator: null,
    updateOriginator: () => {
      throw new Error('Método não implementado');
    },
  });

const QualificationRequest = () => {
  const location = useLocation();
  const { updatePerson, editPersonLoading } = usePersonService();
  const { updateBusiness, creatBusinessLoading } = useCustomerService();
  const { setItems, resetBreadcrumbs } = useBreadcrumbsContext();
  const [borrowerType, updateBorrowerType] = useState<EFunctions | null>(null);
  const [activeStep, updateActiveStep] = useState<number>(0);
  const [productData, updateProductData] = useState<TProducts | null>(null);
  const { createQualificationRequest, createQualificationRequestLoading } =
    useProductsService();
  const [borrowerData, updateBorrowerData] =
    useState<TQualificationBorrower | null>(null);
  const [qualificationData, updateQualificationData] =
    useState<TQualificationRequest>({} as TQualificationRequest);
  const {
    updateQualificationCondition,
    updateQualificationConditionLoading,
    updateQualificationStatusLoading,
  } = useQualificationsService();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isProfileAuthorized } = usePermify();
  const [isBorrowerTypeModalOpen, toggleBorrowerTypeModalOpen] =
    useState(false);
  const [selectedFunding, updateSelectedFunding] = useState<string | null>(
    null,
  );
  const [stepsInfo, updateStepsInfo] = useState<StepItem[]>([]);
  const [isOriginatorStepVisible, toggleOriginatorStepVisible] =
    useState(false);
  const { selectedOriginator, setSelectedOriginator } = useOriginatorContext();
  const { userInfo } = useAuthContext();
  const [isForEmployer, toggleIsForEmployer] = useState(false);
  const [isConfirmModalOpen, toggleConfirmModalOpen] = useState(false);
  const [isSkipCondition, toggleSkipCondition] = useState(false);
  const [conditionData, updateConditionData] = useState<Condition | null>(null);
  const { is_issuer_app } = useAppContext();
  const [backTo, updateBackTo] = useState<IBreadcrumbsItem>({
    to: '/products/main',
    label: 'Lista de Produtos',
  });

  const hasMultiplesFundings = useMemo(
    () => userInfo && userInfo.fundings.length > 1,
    [userInfo],
  );

  const isBusiness = useMemo(
    () =>
      (borrowerData && borrowerData.taxpayer_id.length > 11) ||
      productData?.borrower_type === 'BUSINESS' ||
      (productData?.borrower_type === 'PERSON' &&
        borrowerType === EFunctions.EMPLOYER) ||
      isForEmployer,
    [borrowerData, borrowerType, productData, isForEmployer],
  );

  const isPerson = useMemo(
    () =>
      (borrowerData && borrowerData.taxpayer_id.length < 12) ||
      (productData?.borrower_type === 'PERSON' &&
        borrowerType === EFunctions.BORROWER),
    [borrowerData, borrowerType, productData],
  );

  const steps = useMemo(
    () => [
      {
        step: 0,
        title: 'Originador',
        clickable: false,
        element: <OriginatorStep />,
      },
      {
        step: 1,
        title: 'Funding',
        clickable: false,
        element: <FundingStep />,
      },
      {
        step: 2,
        title: 'Produto',
        clickable: false,
        element: <ProductStep />,
      },
      {
        step: 3,
        title: isBusiness ? 'Empresa' : isPerson ? 'Pessoa' : 'Tomador',
        clickable: false,
        element: <BorrowerStep />,
      },
      {
        step: 4,
        title: 'Campos adicionais',
        clickable: false,
        element: <CustomVariablesStep />,
      },
      {
        step: 5,
        title: 'Documentos',
        clickable: false,
        element: <DocumentsStep />,
      },
      {
        step: 6,
        title: 'Condições',
        clickable: false,
        element: <ConditionsStep />,
      },
    ],
    [isBusiness, isPerson, hasMultiplesFundings],
  );

  useEffect(() => {
    let updatedSteps: StepItem[] = steps;
    if (!hasMultiplesFundings) {
      updatedSteps = updatedSteps.filter((item) => item.title !== 'Funding');
    }

    if (!is_issuer_app) {
      updatedSteps = updatedSteps.filter((item) => item.title !== 'Originador');
    }

    updateStepsInfo(updatedSteps);
  }, [hasMultiplesFundings, steps, is_issuer_app]);

  const hasBorrowerPerson = useMemo<TQualificationBorrower | null>(() => {
    if (typeof location.state?.person === 'object') {
      history.replaceState({}, document.title);
      return location.state?.person;
    }
    return null;
  }, [location]);

  const hasBorrowerBusiness = useMemo<TQualificationBorrower | null>(() => {
    if (typeof location.state?.business === 'object') {
      history.replaceState({}, document.title);
      return location.state?.business;
    }
    return null;
  }, [location]);

  const hasBorrowerType = useMemo<string | null>(() => {
    if (typeof location.state?.borrowerType === 'string') {
      history.replaceState({}, document.title);
      return location.state?.borrowerType;
    }
    return null;
  }, [location]);

  const hasProduct = useMemo<TProducts | null>(() => {
    if (typeof location.state?.product === 'object') {
      history.replaceState({}, document.title);
      return location.state?.product;
    }
    return null;
  }, [location]);

  useEffect(() => {
    setItems(
      [
        { ...backTo },
        { to: '/qualification/new', label: 'Nova linha de crédito' },
      ],

      <Rule />,
    );

    return () => resetBreadcrumbs();
  }, [backTo]);

  const onSaveBorrower = useCallback(
    async (customVariablesArray: TCustomVariableValue[]) => {
      const customVariables = customVariablesArray.reduce(
        (variables, cVariable) => {
          if (
            typeof cVariable.value === 'string' &&
            cVariable.value?.trim() !== '' &&
            cVariable.value !== null &&
            cVariable.value !== undefined
          ) {
            return {
              ...variables,
              [cVariable.name || '']: cVariable.value?.split('T')[0],
            };
          }

          if (typeof cVariable.value === 'boolean') {
            return {
              ...variables,
              [cVariable.name || '']: cVariable.value,
            };
          }
          return variables;
        },
        {},
      );

      if (borrowerData !== null && productData !== null) {
        updateBorrowerData((borrower) => ({
          ...(borrower as TQualificationBorrower),
          custom_variables: {
            ...(borrower?.custom_variables || {}),
            ...customVariables,
          },
        }));

        if (
          productData.borrower_type === 'PERSON' &&
          borrowerType === EFunctions.BORROWER
        ) {
          return updatePerson(borrowerData.id as string, {
            address: borrowerData.address,
            taxpayer_id: borrowerData.taxpayer_id,
            full_name: borrowerData.full_name,
            nationality: borrowerData.nationality,
            phone: borrowerData.phone,
            occupation: borrowerData.occupation,
            birth_date: borrowerData.birth_date,
            pep: borrowerData.pep,
            marital_status: borrowerData.marital_status,
            email_address: borrowerData.email_address,
            mothers_name: borrowerData.mothers_name,
            marital_property_system: borrowerData.marital_property_system,
            sex: borrowerData.sex,
            custom_variables: {
              ...(borrowerData.custom_variables || {}),
              ...customVariables,
            },
            external_bank_account: borrowerData.external_bank_account,
            pix: borrowerData.pix,
            id_document: borrowerData.id_document,
            spouse_full_name: borrowerData.spouse_full_name,
            qualification_request: {
              product: {
                id: productData.id as string,
              },
              funding: {
                id: selectedFunding as string,
              },
              role: borrowerType ? borrowerType : EFunctions.BORROWER,
            },
            role: borrowerData.role,
            employer: borrowerData.employer,
            created_at: borrowerData.created_at,
            updated_at: borrowerData.updated_at,
          });
        }

        return updateBusiness(borrowerData.id as string, {
          taxpayer_id: borrowerData.taxpayer_id,
          legal_name: borrowerData.legal_name,
          id: borrowerData.id,
          phone: borrowerData.phone,
          email_address: borrowerData.email_address,
          foundation_date: borrowerData.foundation_date,
          address: borrowerData.address,
          external_bank_account: borrowerData.external_bank_account,
          pix: borrowerData.pix,
          tax_regime: borrowerData.tax_regime,
          created_at: borrowerData.created_at,
          qualification_request: {
            product: {
              id: productData.id as string,
            },
            funding: {
              id: selectedFunding as string,
            },
            role: borrowerType ? borrowerType : EFunctions.BORROWER,
          },
          custom_variables: {
            ...(borrowerData.custom_variables || {}),
            ...(borrowerType === EFunctions.BORROWER ? customVariables : {}),
          },
          role: borrowerData.role,
        });
      }

      return false;
    },
    [borrowerData, borrowerType, productData, selectedFunding],
  );

  const onCreateQualification = useCallback(
    (skipCondition: boolean) => {
      return new Promise<TQualification | EQualificationError>(
        (resolve, reject) => {
          if (
            productData &&
            borrowerData &&
            selectedFunding &&
            (conditionData || skipCondition)
          ) {
            const qualificationRequest =
              borrowerType === EFunctions.EMPLOYER
                ? {
                    employer: {
                      id: borrowerData.id as string,
                    },
                    funding: {
                      id: selectedFunding as string,
                    },
                    role: EFunctions.EMPLOYER,
                  }
                : {
                    borrower: {
                      id: borrowerData.id as string,
                    },
                    funding: {
                      id: selectedFunding as string,
                    },
                    role: EFunctions.BORROWER,
                  };
            createQualificationRequest(productData.id, qualificationRequest)
              .then((qualificationResponse) => {
                if (skipCondition) {
                  if (qualificationResponse) {
                    resolve(qualificationResponse);
                  } else {
                    reject(EQualificationError.ON_CREATING_QUALIFICATION);
                  }
                } else if (qualificationResponse) {
                  updateQualificationCondition(
                    productData.id,
                    borrowerData.id as string,
                    conditionData as Condition,
                  )
                    .then((conditionResponse) => {
                      if (conditionResponse) {
                        resolve(qualificationResponse);
                        return;
                      } else {
                        reject(EQualificationError.ON_CREATING_CONDITIONS);
                        return;
                      }
                    })
                    .catch(() =>
                      reject(EQualificationError.ON_CREATING_CONDITIONS),
                    );
                } else {
                  reject(EQualificationError.ON_CREATING_QUALIFICATION);
                  return;
                }
              })
              .catch(() =>
                reject(EQualificationError.ON_CREATING_QUALIFICATION),
              );
          } else {
            reject(EQualificationError.ON_CREATING_QUALIFICATION);
            return;
          }
        },
      );
    },
    [borrowerData, borrowerType, productData, conditionData, selectedFunding],
  );

  const onChangeQualificationData = useCallback(
    (_qualificationData: Partial<TQualificationRequest>) => {
      updateQualificationData({
        ...qualificationData,
        ..._qualificationData,
      });
    },
    [qualificationData],
  );

  const onForward = useCallback(() => {
    if (activeStep < steps.length) {
      updateActiveStep(activeStep + 1);
    }
  }, [activeStep]);

  const onBack = useCallback(() => {
    if (activeStep > 0) {
      updateActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  const onSelectBorrowerType = useCallback(() => {
    toggleBorrowerTypeModalOpen(true);
  }, []);

  useEffect(() => {
    if (typeof hasBorrowerPerson === 'object' && hasBorrowerPerson !== null) {
      updateBorrowerData(hasBorrowerPerson);
      toggleOriginatorStepVisible(false);
      updateBackTo({
        to: `/records/natural/${hasBorrowerPerson.id}/details`,
        label: hasBorrowerPerson.full_name,
      });
    }
  }, [hasBorrowerPerson]);

  useEffect(() => {
    if (
      typeof hasBorrowerBusiness === 'object' &&
      hasBorrowerBusiness !== null
    ) {
      toggleOriginatorStepVisible(false);
      updateBorrowerData(hasBorrowerBusiness);
      updateBackTo({
        to: `/records/legal/${hasBorrowerBusiness.id}/details`,
        label: hasBorrowerBusiness.legal_name,
      });
    }
  }, [hasBorrowerBusiness]);

  useEffect(() => {
    if (typeof hasProduct === 'object' && hasProduct !== null) {
      updateProductData(hasProduct);
      toggleOriginatorStepVisible(true);
      updateBackTo({
        to: `/products/detail/${hasProduct.id}/applications`,
        label: hasProduct.name,
      });
    }
  }, [hasProduct]);

  useEffect(() => {
    if (typeof hasBorrowerType === 'string' && hasBorrowerType.length > 0) {
      toggleIsForEmployer(hasBorrowerType === EFunctions.EMPLOYER);
      updateBorrowerType(hasBorrowerType as EFunctions);
    }
  }, [hasBorrowerType]);

  const renderSteps = () => {
    const currentStep = stepsInfo[activeStep];
    return currentStep?.element || null;
  };

  useEffect(() => {
    if (!hasMultiplesFundings && userInfo) {
      updateSelectedFunding(userInfo.fundings[0]);
    }
  }, [hasMultiplesFundings]);

  const isCreatingQualification =
    createQualificationRequestLoading ||
    updateQualificationConditionLoading ||
    !!updateQualificationStatusLoading;

  return (
    <QualificationRequestStepperContext.Provider
      value={{
        activeStep,
        onForward,
        onBack,

        qualificationData,
        onChangeQualificationData,

        productData,
        onChangeProductData: updateProductData,

        borrowerData,
        onChangeBorrowerData: updateBorrowerData,

        conditionData,
        onChangeConditionData: updateConditionData,

        isSavingBorrower: creatBusinessLoading || editPersonLoading,
        onSaveBorrower,

        isCreatingQualification,
        onCreateQualification,

        onStartCreateQualififcation: (_isSkipCondition) => {
          if (isSkipCondition !== _isSkipCondition)
            toggleSkipCondition(_isSkipCondition);
          toggleConfirmModalOpen(true);
        },

        funding: selectedFunding,
        hasMultiplesFundings: !!hasMultiplesFundings,
        updateFunding: updateSelectedFunding,

        borrowerType,
        onChangeBorrowerType: updateBorrowerType,
        onSelectBorrowerType,

        isBusiness,
        isPerson,
        isForEmployer,

        isOriginatorStepVisible: isOriginatorStepVisible && !!is_issuer_app,
        originator: selectedOriginator,
        updateOriginator: setSelectedOriginator,
      }}
    >
      <QualificationRequestContainer>
        <QualificationRequestStepperContainer>
          <Stepper
            activeStep={activeStep}
            stepsItems={steps.filter(
              (step) =>
                (isProfileAuthorized(
                  EAccountPermissions.UPDATE_APPLICATION_QUALIFICATION_CONDITIONS,
                ) &&
                  !isForEmployer &&
                  borrowerType !== EFunctions.EMPLOYER) ||
                step.step !== (hasMultiplesFundings ? 5 : 4),
            )}
          />
        </QualificationRequestStepperContainer>
        <QualificationRequestStepContainer>
          {renderSteps()}
        </QualificationRequestStepContainer>
        <ConfirmModal
          isOpen={isConfirmModalOpen !== false}
          icon={
            <Rule
              style={{
                color: theme.palette.brand.primary.base as string,
                fontSize: '52px',
              }}
            />
          }
          title="Concluir a linha de crédito?"
          color="primary"
          btnConfirmText="Concluir"
          isLoading={isCreatingQualification}
          handleConfirm={() =>
            onCreateQualification(isSkipCondition)
              .then(() => {
                navigate(
                  productData?.borrower_type === 'PERSON' &&
                    borrowerType === EFunctions.BORROWER
                    ? `/records/natural/${borrowerData?.id}/qualifications`
                    : `/records/legal/${borrowerData?.id}/qualifications`,
                );
              })
              .catch((reason: EQualificationError) => {
                if (reason === EQualificationError.ON_CREATING_QUALIFICATION) {
                  showSnackbar(
                    'Ocorreu um problema ao efetuar a linha de crédito',
                    'error',
                  );
                  return;
                }

                if (reason === EQualificationError.ON_CREATING_CONDITIONS) {
                  showSnackbar(
                    'Ocorreu um problema ao cadastrar condição',
                    'alert',
                  );
                } else if (reason === EQualificationError.ON_QUALIFYING) {
                  showSnackbar(
                    'Ocorreu um problema ao aprovar linha de crédito',
                    'alert',
                  );
                }
                navigate(
                  productData?.borrower_type === 'PERSON' &&
                    borrowerType === EFunctions.BORROWER
                    ? `/records/natural/${borrowerData?.id}/qualifications`
                    : `/records/legal/${borrowerData?.id}/qualifications`,
                );
              })
              .finally(() => toggleConfirmModalOpen(false))
          }
          handleClose={() => toggleConfirmModalOpen(false)}
          handleCancel={() => toggleConfirmModalOpen(false)}
        >
          Você está prestes a qualificar{' '}
          <strong>{`${
            productData?.borrower_type === 'PERSON' &&
            borrowerType === EFunctions.BORROWER
              ? borrowerData?.full_name
              : borrowerData?.legal_name
          }`}</strong>{' '}
          ao produto <strong>{`${productData?.name}`}</strong>, deseja
          continuar?
        </ConfirmModal>
        <BorrowerTypeModal
          isOpen={isBorrowerTypeModalOpen}
          handleClose={() => toggleBorrowerTypeModalOpen(false)}
          handleConfirm={(selectedBorrowerType) => {
            updateBorrowerType(selectedBorrowerType.value);
            toggleBorrowerTypeModalOpen(false);
            onForward();
          }}
        />
      </QualificationRequestContainer>
    </QualificationRequestStepperContext.Provider>
  );
};

export default QualificationRequest;
