const TrashIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.50049 8.16732C4.50049 7.68867 4.89017 7.30065 5.37086 7.30065H19.2968C19.7775 7.30065 20.1672 7.68867 20.1672 8.16732C20.1672 8.64596 19.7775 9.03398 19.2968 9.03398H5.37086C4.89017 9.03398 4.50049 8.64596 4.50049 8.16732Z"
      fill="#FF0000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5931 10.7673C11.0738 10.7673 11.4635 11.1553 11.4635 11.634V16.834C11.4635 17.3126 11.0738 17.7006 10.5931 17.7006C10.1124 17.7006 9.72271 17.3126 9.72271 16.834V11.634C9.72271 11.1553 10.1124 10.7673 10.5931 10.7673Z"
      fill="#FF0000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0746 10.7673C14.5553 10.7673 14.9449 11.1553 14.9449 11.634V16.834C14.9449 17.3126 14.5553 17.7006 14.0746 17.7006C13.5939 17.7006 13.2042 17.3126 13.2042 16.834V11.634C13.2042 11.1553 13.5939 10.7673 14.0746 10.7673Z"
      fill="#FF0000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.20622 9.03398C6.59674 9.03398 7.20622 9.03398 7.20622 9.03398L7.97896 18.4953C7.98097 18.5193 7.98197 18.5433 7.98197 18.5673C7.98197 18.7972 8.07367 19.0176 8.2369 19.1801C8.40012 19.3427 8.6215 19.434 8.85234 19.434H15.8153C16.0461 19.434 16.2675 19.3427 16.4307 19.1801C16.594 19.0176 16.6857 18.7972 16.6857 18.5673C16.6857 18.5433 16.6867 18.5193 16.6887 18.4953L17.5816 9.03398H18.5035H19.2968L18.4261 18.607C18.4158 19.2822 18.1419 19.9276 17.6616 20.4058C17.172 20.8934 16.5078 21.1673 15.8153 21.1673H8.85234C8.15983 21.1673 7.49568 20.8934 7.00601 20.4058C6.52573 19.9276 6.25187 19.2822 6.24153 18.607L5.41293 9.03398C5.41293 9.03398 5.89642 9.03398 6.20622 9.03398Z"
      fill="#FF0000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.36219 4.34167C9.68864 4.0166 10.1314 3.83398 10.5931 3.83398H14.0746C14.5362 3.83398 14.979 4.0166 15.3055 4.34167C15.6319 4.66673 15.8153 5.10761 15.8153 5.56732V7.30065H14.9449H14.0746V5.56732H10.5931V7.30065H9.72271H8.85234V5.56732C8.85234 5.10761 9.03574 4.66673 9.36219 4.34167Z"
      fill="#FF0000"
    />
  </svg>
);

export default TrashIcon;
