import { useTheme } from '@mui/material';
import {
  BoxSection,
  ButtonDropdown,
  Heading3,
  Spinner,
  TextM,
  TextXs,
} from 'app/components';
import {
  ButtonArea,
  FilterArea,
  HeaderArea,
  MenuDropdownContent,
  NaturalPersonListPageContainer,
  TableArea,
} from './styles';
import { useState, useEffect, useRef, useCallback } from 'react';
import { UploadCsvHandler } from '../';
import { usePersonService } from 'modules/customer/services';
import { useNavigate } from 'react-router-dom';
import { routingPath } from 'app/routes';
import { formatCNPJ, formatCPF } from 'app/helpers';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { EAccountPermissions } from 'modules/auth/context/permify/permify.interfaces';
import { usePermify } from 'modules/auth/context';
import SearchFilter, {
  TFilterItems,
} from 'app/components/SearchFilter/SearchFilter';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { Table, TextL, TextS } from 'app/components';
import {
  useQueryParam,
  NumberParam,
  StringParam,
  JsonParam,
  withDefault,
} from 'use-query-params';
import { IPersonList } from 'modules/customer/context/PersonProvider/person.interfaces';
import { useAppContext } from 'app/context/AppContextProvider';
import { useOriginatorContext } from 'modules/common/OriginatorProvider';

const tableHeadContent = [
  {
    id: '1',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Originador</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Pessoa</TextL>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextL weight="bold">Data de cadastro</TextL>
      </TableHeadCellContainer>
    ),
  },
];

const filterItems: TFilterItems[] = [
  {
    name: 'Nome',
    parameterName: 'full_name',
  },
  {
    name: 'CPF',
    parameterName: 'taxpayer_id',
  },
  {
    name: 'Email',
    parameterName: 'email_address',
  },
];

const NaturalPersonListPageContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getPersonList, personListLoading } = usePersonService();
  const [isLoading, toggleIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
  );
  const [addDropdownActive, setAddDropdownActive] = useState(false);
  const [modalCSVOpen, setModalCSVOpen] = useState(false);
  const [linesPerPage, setLinesPerPage] = useQueryParam(
    'size',
    withDefault(NumberParam, 10),
  );
  const [totalItemsQty, setTotalItemsQty] = useState(0);
  const [currentPage, setCurrentPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 0),
  );
  const { originators } = useOriginatorContext();
  const prevSelectedFilter = useRef<string>('');
  const prevFilterValue = useRef<string>('');
  const [selectedFilter, setSelectedFilter] = useQueryParam<TFilterItems>(
    'filter',
    withDefault(JsonParam, filterItems[0]),
  );
  const { isProfileAuthorized } = usePermify();
  const { is_issuer_app } = useAppContext();
  const { setSelectedOriginator } = useOriginatorContext();
  const [tableDataContent, updateTableDataContent] = useState<
    IPersonList['content']
  >([]);

  const handleAdd = () => {
    setAddDropdownActive(true);
  };

  useEffect(() => {
    setAddDropdownActive(() => false);
  }, [modalCSVOpen]);

  const getPersonListWithPage = useCallback(
    async (
      page: number,
      parameterName?: string,
      parameterValue?: string,
      itemsPerPage?: number,
    ) => {
      try {
        const personList = await getPersonList(
          page,
          parameterName,
          parameterValue,
          itemsPerPage,
        );
        if (personList) {
          updateTableDataContent([]);
          setTotalItemsQty(personList.total_elements || 0);
          const personMap = personList.content;

          if (personMap.length > 0) {
            if (
              prevSelectedFilter.current === parameterName &&
              prevFilterValue.current === parameterValue
            ) {
              updateTableDataContent((state) =>
                state.concat(
                  personMap.filter((content) => {
                    const inx = state.findIndex(
                      (stateContent) => stateContent.id === content.id,
                    );
                    return inx < 0;
                  }),
                ),
              );
            } else {
              updateTableDataContent(() => personMap);
              prevSelectedFilter.current = parameterName || '';
              prevFilterValue.current = parameterValue || '';
            }
          } else {
            updateTableDataContent(() => []);
            prevSelectedFilter.current = parameterName || '';
            prevFilterValue.current = parameterValue || '';
          }
          toggleIsLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [tableDataContent],
  );

  useEffect(() => {
    getPersonListWithPage(
      currentPage,
      selectedFilter.parameterName,
      searchInputValue,
      linesPerPage,
    );
  }, [linesPerPage, currentPage]);

  const handleSearch = () => {
    setCurrentPage(0);
    setLinesPerPage(10);
    setTimeout(() =>
      getPersonListWithPage(
        0,
        selectedFilter.parameterName,
        searchInputValue,
        10,
      ),
    );
  };

  const handleNavigate = (person: IPersonList['content'][0]) => {
    if (is_issuer_app && person.originator?.id) {
      setSelectedOriginator(person.originator.id);
    }
    setTimeout(() => navigate(`/records/natural/${person.id}/details`));
  };

  return (
    <>
      <NaturalPersonListPageContainer>
        <BoxSection>
          <HeaderArea>
            <div style={{ width: '100%' }}>
              <Heading3 style={{ color: theme.palette?.brand.gray[800] }}>
                Cadastros
              </Heading3>
              <FilterArea>
                <SearchFilter
                  inputValue={searchInputValue}
                  handleClearInput={() => {
                    setCurrentPage(0);
                    setLinesPerPage(10);
                    setSearchInputValue(() => '');
                    setTimeout(() =>
                      getPersonListWithPage(0, undefined, undefined, 10),
                    );
                  }}
                  handleInputChange={(evt) =>
                    setSearchInputValue(() => evt.target.value)
                  }
                  filterItems={filterItems}
                  handleSelectFilterItem={(selectedFilterValue) => {
                    setSelectedFilter(() => selectedFilterValue);
                  }}
                  selectedFilter={selectedFilter?.name}
                  handleSearch={handleSearch}
                />
                <ButtonArea>
                  {isProfileAuthorized(
                    EAccountPermissions.CREATE_PERSON_BUSINESS,
                  ) && (
                    <ButtonDropdown
                      isOpen={addDropdownActive}
                      handleClose={() => setAddDropdownActive(false)}
                      handleClick={handleAdd}
                      title="Cadastrar Pessoas"
                    >
                      <MenuDropdownContent>
                        <TextM
                          weight="Bold"
                          style={{ color: theme.palette.brand.secondary.base }}
                          onClick={() =>
                            navigate(routingPath.records.naturalNew)
                          }
                        >
                          Nova Pessoa
                        </TextM>
                      </MenuDropdownContent>
                      <MenuDropdownContent>
                        <TextM
                          weight="Bold"
                          style={{ color: theme.palette.brand.secondary.base }}
                          onClick={() => {
                            setModalCSVOpen(true);
                          }}
                        >
                          Importar CSV
                        </TextM>
                      </MenuDropdownContent>
                    </ButtonDropdown>
                  )}
                </ButtonArea>
              </FilterArea>
            </div>
          </HeaderArea>
          <TableArea>
            {isLoading || personListLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: '100%',
                }}
              >
                <Spinner />
              </div>
            ) : (
              <Table
                showPagination
                handlePageChange={(selectedPage) => {
                  setCurrentPage(selectedPage - 1);
                }}
                handleLinesPerPage={(selectedLinesPerPage) => {
                  setCurrentPage(0);
                  setLinesPerPage(
                    selectedLinesPerPage === 0 ? 10 : selectedLinesPerPage,
                  );
                }}
                totalElementsQty={totalItemsQty}
                currentPage={currentPage + 1}
                linesPerPage={linesPerPage}
                tableHeadCell={tableHeadContent.filter(
                  (i) => is_issuer_app || i.id !== '1',
                )}
                tableBodyContent={tableDataContent?.map((item) => {
                  const originator = originators?.find(
                    (o) => o.id === item?.originator?.id,
                  );
                  return {
                    id: '1',
                    cells: [
                      {
                        id: '1',
                        content: (
                          <TableBodyCellContainer
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleNavigate(item)}
                          >
                            <TextS weight="Bold">
                              {originator?.legal_name ??
                                (item?.originator?.legal_name || '')}
                            </TextS>
                            {originator?.taxpayer_id && (
                              <TextXs>
                                {formatCNPJ(originator?.taxpayer_id)}
                              </TextXs>
                            )}
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleNavigate(item)}
                          >
                            <TextS weight="Bold">{item.full_name}</TextS>
                            <TextS>{formatCPF(item.taxpayer_id)}</TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleNavigate(item)}
                          >
                            <TextS weight="Bold">
                              {formatDateFromBackend(
                                item.created_at || '',
                              ).toLocaleDateString('pt-br')}
                            </TextS>
                          </TableBodyCellContainer>
                        ),
                      },
                    ].filter((i) => is_issuer_app || i.id !== '1'),
                  };
                })}
              />
            )}
          </TableArea>
        </BoxSection>
      </NaturalPersonListPageContainer>
      <UploadCsvHandler
        handleClose={() => setModalCSVOpen(false)}
        isOpen={modalCSVOpen}
      />
    </>
  );
};

export default NaturalPersonListPageContent;
