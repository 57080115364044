import { useState } from 'react';
import { CessionHttpService } from './api/cessionHttpService';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  TCession,
  TCessionIssuerInfo,
  TCessionResponse,
} from '../interfaces/cession';
import { useAuthContext } from 'modules/auth/context';

export const useCessionService = () => {
  const { showSnackbar } = useSnackbar();
  const [getCessionLoading, setGetCessionLoading] = useState(false);
  const [hasCessionConfigurationLoading, setHasCessionConfigurationLoading] =
    useState(false);
  const { userInfo } = useAuthContext();
  const [getCessionFileLoading, setGetCessionFileLoading] = useState(false);
  const [getCessionIssuerInfoLoading, setGetCessionIssuerInfoLoading] =
    useState(false);
  const [getCessionRequestsListLoading, setGetCessionRequestsListLoading] =
    useState(false);

  const getCessionFile = async (
    fundingId: string,
    cessionId: string,
  ): Promise<string | undefined> => {
    setGetCessionFileLoading(true);
    try {
      const cessionService = new CessionHttpService();
      cessionService.setRequestConfig({
        responseType: 'arraybuffer',
      });
      const res = await cessionService.getCessionFile(fundingId, cessionId);
      return res;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar o arquivo', 'error');
    } finally {
      setGetCessionFileLoading(false);
    }
  };

  const hasCessionConfiguration = async (): Promise<boolean> => {
    if (!userInfo?.fundings) {
      return false;
    }

    setHasCessionConfigurationLoading(true);
    try {
      const cessionService = new CessionHttpService();
      cessionService.setHeader({
        handled: true,
      });
      const res = await cessionService.getCessionConfiguration(
        userInfo.fundings,
      );
      return res;
    } catch {
      console.log('No cession configuration found!');
      return false;
    } finally {
      setHasCessionConfigurationLoading(false);
    }
  };

  const getCessionIssuerInfo = async (
    issuerId: string,
  ): Promise<TCessionIssuerInfo | undefined> => {
    setGetCessionIssuerInfoLoading(true);
    try {
      const cessionService = new CessionHttpService();
      const res = await cessionService.getCessionIssuerInfo(issuerId);
      return res;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar informações do cedente', 'error');
    } finally {
      setGetCessionIssuerInfoLoading(false);
    }
  };

  const getCessionRequestsList = async (
    fundingId: string,
  ): Promise<TCession[] | undefined> => {
    setGetCessionRequestsListLoading(true);
    try {
      const cessionService = new CessionHttpService();
      const res = await cessionService.getCessionRequestsList(fundingId);
      const cessionsWithApplications: TCession[] = await Promise.all(
        res.content.map(async (cession) => {
          const applicationIds = cession.applications.map(
            (application) => application.applicationId,
          );

          const applicationSummary =
            await cessionService.getCessionApplicationSummary(applicationIds);

          return {
            cession,
            requestedAmount: applicationSummary.total_requested_amount,
            acquisitionAmount: applicationSummary.total_acquisition_value,
          };
        }),
      );
      return cessionsWithApplications;
    } catch {
      showSnackbar('Ocorreu um erro ao buscar a lista de cessões', 'error');
    } finally {
      setGetCessionRequestsListLoading(false);
    }
  };

  const getCessionRequest = async (
    fundingId: string,
    cessionId: string,
  ): Promise<TCessionResponse | undefined> => {
    setGetCessionLoading(true);
    try {
      const cessionService = new CessionHttpService();
      const res = await cessionService.getCession(cessionId, fundingId);
      return res.content[0];
    } catch {
      showSnackbar('Ocorreu um erro ao buscar os detalhes da cessão', 'error');
    } finally {
      setGetCessionLoading(false);
    }
  };

  return {
    getCessionRequest,
    getCessionLoading,
    getCessionFile,
    getCessionFileLoading,
    getCessionRequestsList,
    getCessionRequestsListLoading,
    getCessionIssuerInfo,
    getCessionIssuerInfoLoading,
    hasCessionConfiguration,
    hasCessionConfigurationLoading,
  };
};
