import { BaseHttpService } from 'app/services';
import {
  ValidateBillets,
  ValidateBilletsResponse,
} from 'modules/products/interfaces/billets';

export class BilletsHttpService extends BaseHttpService {
  baseUrl = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;

  validateBillets = async (
    _validateBillets: ValidateBillets,
  ): Promise<ValidateBilletsResponse> => {
    const res = await this.post(
      `${this.baseUrl}/originator/billets/validate-billets`,
      _validateBillets,
    );

    return res.data as ValidateBilletsResponse;
  };
}
