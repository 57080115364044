import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 40px;
  position: relative;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 0px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 71px;

  .title {
    display: flex;

    h3 {
      margin-right: 16px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;
`;

export const TableArea = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 48px;
`;

export const ArrowArrowContainer = styled.div`
  cursor: pointer;
`;

export const TableWrapper = styled.div`

  th:nth-of-type(9) {
    border-left: 1px solid #E6E5EA;
    max-width: 60px;
  }

  td:nth-of-type(9) {
    border-left: 1px solid #E6E5EA;
    max-width: 60px;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  width: 100%;
  margin-right: 11px;
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints?.values?.laptop + 'px'}) {
      flex-direction: column;

  }
`;
