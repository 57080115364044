import { useState, useEffect } from 'react';
import { Cnab444GenerateModal } from './components/Cnab444GenerateModal';
import {
  Cnab444GenerateMessageModal,
  Cnab444GenerateLoadingModal,
} from './components';
import { ErrorCircularIcon } from 'app/components/Icons';
import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { useCnabService } from 'modules/cession/services/useCnabService';
import {
  TGenerateCnab444RequestFilters,
  TGenerateCnab444RequestParameters,
  TGenerateCnabRequestFilters,
} from 'modules/cession/interfaces/cnab444';
import { useAuthContext } from 'modules/auth/context';

enum ECnab444GenerateError {
  CONNECTION,
  UNKNOWN,
}

export interface ICnab444Generate {
  isOpen: boolean;
  fundingId: string;
  filters?: TGenerateCnab444RequestFilters;
  cnab?: TGenerateCnabRequestFilters;
  originator?: string;
  handleClose: (isOpen: boolean) => void;
}

const Cnab444Generate = ({
  isOpen,
  filters,
  fundingId,
  cnab,
  originator,
  handleClose,
}: ICnab444Generate) => {
  const { userInfo } = useAuthContext();
  const { getCnab444File, generateCnab444File, getCnab444 } = useCnabService();
  const [isGenerateOpen, toggleIsGenerateOpen] = useState(false);
  const [modalCnab444GenerateLoadingOpen, setModalCnab444GenerateLoadingOpen] =
    useState(false);
  const [cnab444Id, setCnab444Id] = useState<string | false>(false);
  const [cnab444IntervalId, setCnab444IntervalId] =
    useState<NodeJS.Timeout | null>(null);
  const [modalErrorOpen, setModalErrorOpen] = useState<
    ECnab444GenerateError | false
  >(false);

  const getResultFile = (): void => {
    if (cnab444Id) {
      getCnab444File(fundingId, cnab444Id, originator).then((result) => {
        if (result) {
          saveAs(new Blob([result]), `cnab_444_${cnab444Id}.txt`);
          setTimeout(() => handleClose(false));
        }
      });
    }
  };

  const handleGenerate = (params: TGenerateCnab444RequestParameters) => {
    if (!filters && !cnab) return null;
    handleClose(false);
    setModalCnab444GenerateLoadingOpen(true);
    generateCnab444File(
      fundingId,
      {
        filters: filters
          ? filters
          : {
              application_ids: cnab?.application_ids,
            },
        parameters: params,
      },
      originator,
    )
      .then((res) => {
        if (res) {
          setCnab444Id(res.id);
          return;
        }
        setModalCnab444GenerateLoadingOpen(false);
        setModalErrorOpen(ECnab444GenerateError.UNKNOWN);
      })
      .catch((e) => {
        if ((e as AxiosError).code === 'ERR_NETWORK') {
          setModalErrorOpen(ECnab444GenerateError.CONNECTION);
        } else {
          setModalErrorOpen(ECnab444GenerateError.UNKNOWN);
        }
        setModalCnab444GenerateLoadingOpen(false);
      });
  };

  useEffect(() => {
    if (!modalCnab444GenerateLoadingOpen && cnab444IntervalId) {
      clearTimeout(cnab444IntervalId);
      setCnab444IntervalId(null);
    }
  }, [modalCnab444GenerateLoadingOpen]);

  useEffect(() => {
    if (cnab444Id) {
      const fetchCnab444Status = async () => {
        try {
          const cnab444 = await getCnab444(fundingId, cnab444Id, originator);
          if (cnab444?.status === 'PENDING') {
            const timeout = setTimeout(() => fetchCnab444Status(), 2000);
            setCnab444IntervalId(timeout);
            return;
          } else {
            setCnab444IntervalId(null);
            setModalCnab444GenerateLoadingOpen(false);
            if (cnab444?.status === 'ERROR') {
              setModalErrorOpen(ECnab444GenerateError.UNKNOWN);
            } else {
              getResultFile();
            }
          }
        } catch (e: unknown) {
          if ((e as AxiosError).code === 'ERR_NETWORK') {
            setModalErrorOpen(ECnab444GenerateError.CONNECTION);
          } else {
            setModalErrorOpen(ECnab444GenerateError.UNKNOWN);
          }
          setCnab444IntervalId(null);
          console.log(e);
        }
      };

      fetchCnab444Status();
    } else {
      if (cnab444IntervalId) {
        clearTimeout(cnab444IntervalId);
        setCnab444IntervalId(null);
      }
    }
    return () => {
      if (cnab444IntervalId) {
        clearTimeout(cnab444IntervalId);
        setCnab444IntervalId(null);
      }
    };
  }, [cnab444Id, originator]);

  useEffect(() => {
    if (isOpen) {
      if (typeof cnab !== 'undefined') {
        toggleIsGenerateOpen(false);
        handleGenerate({
          cession_date: cnab.cession_date,
          originator: {
            id: originator ?? (userInfo!.originator_id as string),
          },
        });
      } else {
        toggleIsGenerateOpen(true);
      }
    } else {
      toggleIsGenerateOpen(false);
    }
  }, [isOpen, cnab, originator]);

  return (
    <>
      <Cnab444GenerateModal
        handleClose={() => handleClose(false)}
        isOpen={isGenerateOpen}
        originator={originator}
        handleGenerate={handleGenerate}
      />
      <Cnab444GenerateLoadingModal
        isOpen={modalCnab444GenerateLoadingOpen}
        handleClose={() => setModalCnab444GenerateLoadingOpen(false)}
      />
      <Cnab444GenerateMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === ECnab444GenerateError.CONNECTION}
        title="Não foi possível conectar à internet."
        icon={<ErrorCircularIcon />}
      >
        Verifique sua conexão de rede e tente novamente.
      </Cnab444GenerateMessageModal>
      <Cnab444GenerateMessageModal
        handleClose={() => setModalErrorOpen(false)}
        isOpen={modalErrorOpen === ECnab444GenerateError.UNKNOWN}
        title="Ocorreu um problema no nosso sistema."
        icon={<ErrorCircularIcon />}
      >
        Por gentileza, tente novamente ou contate o suporte.
      </Cnab444GenerateMessageModal>
    </>
  );
};

export default Cnab444Generate;
