import { Condition } from 'modules/products/services/hooks/intefaces';
import { qualificationsActionType } from './qualificationsActionType';
import {
  TProductDocumentConfiguration,
  TProductVariableConfiguration,
} from '../ProductsProvider/products.interfaces';

export type TQualificationEmployer = {
  id: string;
  legal_name: string;
  taxpayer_id: string;
};

export type TQualificationBorrower = {
  id: string;
  name: string;
  taxpayer_id?: string;
};

export type TQualificationFunding = {
  id: string;
  legal_name: string;
  taxpayer_id: string;
};

export enum EQualificationStatus {
  APPROVED = 'QUALIFIED',
  DENIED = 'DENIED',
  CANCELED = 'CANCELED',
  PENDING_RANDOM = 'PENDING_RANDOM',
}

export enum EQualificationRole {
  EMPLOYER = 'EMPLOYER',
}

export type TQualification = {
  id: string;
  created_at: string;
  employer: TQualificationEmployer;
  funding: TQualificationFunding;
  role: EQualificationRole;
  originator?: string;
  status: string | EQualificationStatus;
  missingDocuments: TProductDocumentConfiguration[];
  missingVariables: TProductVariableConfiguration[];
};

export type TBorrowerQualification = {
  id: string;
  created_at: string;
  updated_at?: string;
  funding: TQualificationFunding;
  condition: Condition | null;
  role: EQualificationRole;
  status: string | EQualificationStatus;
  originator?: string;
  borrower: TQualificationBorrower;
  employer: TQualificationEmployer | null;
};

export type TQualificationStatusOption = {
  identifier: string;
  description: string;
};

export interface IQualificationsState {
  page: number;
  hasMorePage: boolean;
  content: TQualification[];
  totalElements: number;
  statusOptions: TQualificationStatusOption[];
  selected?: TQualification;
  qualificationBorrower: TBorrowerQualification[];
  borrowerStatusOptions: TQualificationStatusOption[];
}

export type IDispatch = (action: IQualificationsAction) => void;

export interface IQualificationsContext {
  state: IQualificationsState;
  dispatch: IDispatch;
}

export type IQualificationsAction =
  | {
      type: qualificationsActionType.SET_QUALIFICATIONS;
      payload: {
        content: TQualification[];
        page: number;
        hasMorePage: boolean;
        totalElements: number;
      };
    }
  | {
      type: qualificationsActionType.SELECT_QUALIFICATION;
      payload: TQualification | undefined;
    }
  | {
      type: qualificationsActionType.SET_STATUS_OPTIONS;
      payload: TQualificationStatusOption[];
    }
  | {
      type: qualificationsActionType.SET_STATUS_OPTIONS_PERSON;
      payload: TQualificationStatusOption[];
    }
  | {
      type: qualificationsActionType.UPDATE_QUALIFICATION;
      payload: { id: string; qualification: Partial<TQualification> };
    }
  | {
      type: qualificationsActionType.UPDATE_PERSON_QUALIFICATION;
      payload: { id: string; qualification: Partial<TBorrowerQualification> };
    }
  | {
      type: qualificationsActionType.SET_QUALIFICATIONS_BORROWER;
      payload: TBorrowerQualification[];
    };
