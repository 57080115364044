const QRCodeIcon = ({ color = '#4B4662' }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 14.1667V13.3333H10.8333V10.8333H13.3333V12.5H15V14.1667H14.1667V15.8333H12.5V17.5H10.8333V15H12.5V14.1667H13.3333ZM17.5 17.5H14.1667V15.8333H15.8333V14.1667H17.5V17.5ZM2.5 2.5H9.16667V9.16667H2.5V2.5ZM4.16667 4.16667V7.5H7.5V4.16667H4.16667ZM10.8333 2.5H17.5V9.16667H10.8333V2.5ZM12.5 4.16667V7.5H15.8333V4.16667H12.5ZM2.5 10.8333H9.16667V17.5H2.5V10.8333ZM4.16667 12.5V15.8333H7.5V12.5H4.16667ZM15 10.8333H17.5V12.5H15V10.8333ZM5 5H6.66667V6.66667H5V5ZM5 13.3333H6.66667V15H5V13.3333ZM13.3333 5H15V6.66667H13.3333V5Z"
        fill={color}
      />
    </svg>
  );
};

export default QRCodeIcon;
